import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import frenchdetail from "../../Assets/img/frenchdetail.png";
import center from "../../Assets/img/center.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../Assets/css/detailfranchise.css";
import manimg from "../../Assets/img/manimg.png";
import video1 from "../../Assets/img/video1.png";
import video2 from "../../Assets/img/video2.png";
import clock from "../../Assets/img/clock.png";
import { GetHomeFranchiseDetailsApi } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";

const Franchisede = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [view, setView] = useState(false);
	const userData = useSelector((state) => state.AuthReducer.users);
	let currentUrl = window.location.href.split("/");
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [franchiesDetails, setFranchiesDetails] = useState();

	useEffect(() => {
		GetHomeFranchiseDetailsApi(currentUrl[4])
			.then((res) => {
				setFranchiesDetails(res?.data?.response?.data);
				// dispatch(AllStates(res?.data?.response?.data))
			})
			.catch((err) => {});
	}, [currentUrl[4]]);

	const AddToCartHandler = (item) => {
		let checkItemAlreadyExist = CartDataRedux.filter(
			(val) => val?.id === item?.id,
		);
		if (checkItemAlreadyExist?.length > 0) {
			alert("Item Already Exist in Cart!!!");
		} else {
			let data = {
				id: item?.id,
				price: item?.price,
				productitem: item,
			};
			dispatch(AddToCart(data));
			toast.success("Product Successfully Add ");
			navigate("/");
		}
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Franchise Detail Page</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Cetnter pro section  */}
			<section className="center-pro-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="cenetr-img">
								<img
									className="img-fluid"
									src={franchiesDetails?.cover_image_path}
								></img>
							</div>
							{/* {franchiesDetails?.cashback ? (
                <div>
                  <p className="cover-cashback-tag">
                    Cashback: {franchiesDetails?.cashback}
                  </p>
                </div>
              ) : null} */}
						</div>

						<div className="col-md-4">
							<div className="center-pro-img">
								<img
									className="img-fluid"
									src={franchiesDetails?.logo_path}
								></img>
							</div>

							<div className="center-img-btn">
								<a onClick={(e) => AddToCartHandler(franchiesDetails)}>
									Request Free Info
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Center pro section  */}

			{/* tabs-section  */}
			<section className="tab-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="tab-sec-all">
								<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
									<li class="nav-item">
										<a
											class="nav-link active"
											id="pills-home-tab"
											data-toggle="pill"
											href="#pills-home"
											role="tab"
											aria-controls="pills-home"
											aria-selected="true"
										>
											Overview
										</a>
									</li>
									<li class="nav-item">
										<a
											class="nav-link"
											id="pills-profile-tab"
											data-toggle="pill"
											href="#pills-profile"
											role="tab"
											aria-controls="pills-profile"
											aria-selected="false"
										>
											Videos
										</a>
									</li>
								</ul>
								<div class="tab-content" id="pills-tabContent">
									<div
										class="tab-pane fade show active"
										id="pills-home"
										role="tabpanel"
										aria-labelledby="pills-home-tab"
									>
										<div className="home-things-para">
											<p
												dangerouslySetInnerHTML={{
													__html: franchiesDetails?.description,
												}}
											/>
											{/* <p>{franchiesDetails?.description}</p> */}
											{/* <h5>Ownership Types Allowed</h5>
                      <ul>
                        {franchiesDetails?.passive_ownership_allowed?.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      </ul> */}

											{/* <h5>{franchiesDetails?.states? "Available States:" : null}</h5>
                      <ul>
                        {franchiesDetails?.states?.map((item, index) => {
                          return <li key={index}>{item?.name}</li>;
                        })}
                      </ul> */}

											{/* <h5>
                        {franchiesDetails?.states
                          ? "Not Available States:"
                          : null}
                      </h5>
                      <ul>
                        {franchiesDetails?.not_available_states?.map(
                          (item, index) => {
                            return <li key={index}>{item?.name}</li>;
                          }
                        )}
                      </ul> */}

											<div className="center-img-btn">
												<a onClick={(e) => AddToCartHandler(franchiesDetails)}>
													Request Free Info
												</a>
											</div>
										</div>
									</div>
									<div
										class="tab-pane fade"
										id="pills-profile"
										role="tabpanel"
										aria-labelledby="pills-profile-tab"
									>
										<div className="video-all">
											<div className="row">
												<div className="col-md-5">
													<>
														{franchiesDetails?.videos?.length > 0 ? (
															<>
																{franchiesDetails?.videos?.map(
																	(item, index) => {
																		return (
																			<div className="video-img">
																				<video
																					width="320"
																					height="240"
																					controls
																				>
																					<source
																						src={item?.file_path}
																						type="video/mp4"
																					/>
																				</video>
																			</div>
																		);
																	},
																)}
															</>
														) : (
															<p>No Record Found</p>
														)}
													</>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-4">
							{/* <div className="man-img">
                {franchiesDetails?.images?.length > 0 ? (
                  <img
                    className="img-fluid"
                    src={franchiesDetails?.images[0]?.file_path}
                  ></img>
                ) : null}
              </div> */}

							<div className="year-established">
								{franchiesDetails?.cashback_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.cashback_label != "" || null
												? franchiesDetails?.cashback_label
												: "Cash Back from Franchise Velocity"}
										</h5>
										<p>{franchiesDetails?.cashback_value}</p>
									</div>
								) : null}
								{franchiesDetails?.year_founded_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.year_founded_label != null
												? franchiesDetails?.year_founded_label
												: "Year Established"}
										</h5>
										<p>{franchiesDetails?.year_founded}</p>
									</div>
								) : null}

								{franchiesDetails?.franchising_since_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.franchising_since_label != null
												? franchiesDetails?.franchising_since_label
												: "Began Franchising"}{" "}
										</h5>
										<p>{franchiesDetails?.franchising_since}</p>
									</div>
								) : null}

								{franchiesDetails?.headquarters_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.headquarters_label != null
												? franchiesDetails?.headquarters_label
												: "Headquarters"}{" "}
										</h5>
										<p>{franchiesDetails?.headquarters}</p>
									</div>
								) : null}

								{franchiesDetails?.total_units_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.total_units_label != null
												? franchiesDetails?.total_units_label
												: "Total Units"}
										</h5>
										<p>{Math.ceil(franchiesDetails?.total_units)}</p>
									</div>
								) : null}

								<div className="establish">
									<h5>Categories:</h5>
									{/* <ul>
                    {franchiesDetails
                      ? franchiesDetails.categories.map((item) => {
                          return (
                            <>
                              <li>{item?.name}</li>
                            </>
                          );
                        })
                      : null}
                  </ul> */}
									{franchiesDetails
										? franchiesDetails.categories.map((item) => {
												return (
													<>
														<p>{item?.name}</p>
													</>
												);
										  })
										: null}
								</div>

								{franchiesDetails?.min_cash_required_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.min_cash_required_label != null
												? franchiesDetails?.min_cash_required_label
												: "Minimum Cash Required"}
										</h5>
										<p>{franchiesDetails?.min_cash_required}</p>
									</div>
								) : null}

								{franchiesDetails?.net_worth_required_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.net_worth_required_label != null
												? franchiesDetails?.net_worth_required_label
												: "Min. Net Worth Required"}
										</h5>
										<p>{franchiesDetails?.net_worth_required}</p>
									</div>
								) : null}

								{franchiesDetails?.max_investment_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.max_investment_label != null
												? franchiesDetails?.max_investment_label
												: "Max Investment"}
										</h5>
										<p>{franchiesDetails?.max_investment}</p>
									</div>
								) : null}

								{franchiesDetails?.min_investment_display == 1 ? (
									<div className="establish">
										<h5>
											{" "}
											{franchiesDetails?.min_investment_label != null
												? franchiesDetails?.min_investment_label
												: "Min Investment"}
										</h5>
										<p>{franchiesDetails?.min_investment}</p>
									</div>
								) : null}

								{franchiesDetails?.financial_assistance_available_display ==
								1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.financial_assistance_available_label !=
											null
												? franchiesDetails?.financial_assistance_available_label
												: "Financial Assistance Available"}
										</h5>
										<p>
											{franchiesDetails?.financial_assistance_available == 1
												? "Yes"
												: "No"}
										</p>
									</div>
								) : null}

								{franchiesDetails?.passive_ownership_allowed_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.passive_ownership_allowed_label != null
												? franchiesDetails?.passive_ownership_allowed_label
												: "Ownership Types Allowed"}
										</h5>

										{franchiesDetails?.passive_ownership_allowed?.map(
											(item, index) => {
												return <p key={index}>{item}</p>;
											},
										)}
										{/* {franchiesDetails?.passive_ownership_allowed == 1
                        ? "Yes"
                        : "No"} */}
									</div>
								) : null}

								{/* {franchiesDetails?.passive_ownership_allowed ? (
                  <div className="establish">
                    <h5>Passive Ownership Type</h5>
                    {franchiesDetails?.passive_ownership_allowed?.map(
                      (item, index) => {
                        return <p key={index}>{item}</p>;
                      }
                    )}
                  </div>
                ) : null} */}

								{franchiesDetails?.franchise_fee_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.franchise_fee_label != null
												? franchiesDetails?.franchise_fee_label
												: "Franchise Fee"}
										</h5>
										<p>{franchiesDetails?.franchise_fee}</p>
									</div>
								) : null}

								{franchiesDetails?.advertising_fee_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.advertising_fee_label != null
												? franchiesDetails?.advertising_fee_label
												: "Advertising Fee"}
											:
										</h5>
										{/* <p>{Math.ceil(franchiesDetails?.advertising_fee)}%</p> */}
										<p>{franchiesDetails?.advertising_fee}</p>
									</div>
								) : null}

								{franchiesDetails?.royalty_fee_display == 1 ? (
									<div className="establish">
										<h5>
											{franchiesDetails?.royalty_fee_label != null
												? franchiesDetails?.royalty_fee_label
												: "Royalty Fee"}
										</h5>
										{/* <p>{Math.ceil(franchiesDetails?.royalty_fee)}%</p> */}
										<p>{franchiesDetails?.royalty_fee}</p>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* End of Tabs section  */}

			<Footer />
		</>
	);
};

export default Franchisede;
