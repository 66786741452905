import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/about.css";
import aboutus from "../../Assets/img/aboutus.png";
import aboutus2 from "../../Assets/img/aboutus2.jpg";
import about1 from "../../Assets/img/about1.png";
import about2 from "../../Assets/img/about2.png";
import { PagesApi } from "../../network/Network";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const About = () => {
	const [aboutUs, setAboutUs] = useState();
	const [spinLoad, setSpinLoad] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/about-us";
		PagesApi(slug)
			.then((res) => {
				setAboutUs(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link> {">"} <span>About US</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<section className="about-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							{spinLoad ? (
								<>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
								</>
							) : (
								<div className="about-head">
									{/* <h5>{aboutUs?.tag_line}</h5>
                  <h2>{aboutUs?.page_title}</h2> */}

									<p dangerouslySetInnerHTML={{ __html: aboutUs?.content }} />
								</div>
							)}

							{/* <div className="about-two-pics">
                <img className="img-fluid" src={about1}></img>
                <img className="img-fluid" src={about2}></img>
              </div> */}
						</div>

						<div className="col-md-4">
							<div className="about-img">
								<img className="img-fluid" src={aboutUs?.image_url}></img>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default About;
