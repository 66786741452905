import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import "../../Assets/css/termsconditions.css";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { PagesApi } from "../../network/Network";
import { useState } from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
	const [pageData, setPageData] = useState();
	const [spinLoad, setSpinLoad] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/terms-condition";
		PagesApi(slug)
			.then((res) => {
				setPageData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);
	return (
		<>
			{/* Terms and conditions secton  */}
			<section className="terms-modal">
				{spinLoad ? (
					<></>
				) : (
					<>
						<h5>Cash Back Program</h5>
						<h2>{pageData?.page_title}</h2>

						<p dangerouslySetInnerHTML={{ __html: pageData?.content }} />
					</>
				)}
			</section>
			{/* End of Terms condition section  */}
		</>
	);
};

export default TermsConditions;
