import { Lead } from "bootstrap-4-react/lib/components";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { GetMyLeadDetails, GetMyLeads } from "../../network/Network";
import Sidebar from "./Sidebar";

const LeadDetails = () => {
	const Token = useSelector((state) => state.AuthReducer.token);
	const UserData = useSelector((state) => state.AuthReducer.users);
	const [leadDetails, setLeadDetails] = useState([]);
	const [view, setView] = useState(false);
	const [spinLoad, setSpinLoad] = useState(false);
	let currentUrl = window.location.href.split("/");

	useEffect(() => {
		setSpinLoad(true);
		GetMyLeadDetails(currentUrl[4], Token)
			.then((res) => {
				setLeadDetails(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>
										Join As{" "}
										{UserData?.user_type == "broker"
											? "Franchisor Consultant"
											: "Franchisor"}
									</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Leads section  */}
			<section className="leads-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="view-info-detail">
								<h3>Franchise Information</h3>
								<div className="form-details">
									{spinLoad ? (
										<>
											<div className="col-md-12">
												<Skeleton count={5} />
											</div>
											<div className="col-md-12">
												<Skeleton count={5} />
											</div>
										</>
									) : (
										<form>
											<div className="row view-detail">
												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">First Name</label>
														<p className="sub-info">
															{leadDetails?.first_name}
														</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Last Name</label>
														<p className="sub-info">{leadDetails?.last_name}</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Email Address</label>
														<p className="sub-info">{leadDetails?.email}</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">State</label>
														<p className="sub-info">{leadDetails?.state}</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Zip Code</label>
														<p className="sub-info">{leadDetails?.zip}</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Phone Number</label>
														<p className="sub-info">{leadDetails?.phone}</p>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">
															Cash Available to Invest
														</label>
														<p className="sub-info">
															${leadDetails?.cash_available_to_invest}
														</p>
													</div>
												</div>

												<div className="col-md-12">
													<label className="info-title">Comments</label>
													<p className="sub-info">{leadDetails?.comments}</p>
												</div>
											</div>
										</form>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Leads section  */}

			<Footer />
		</>
	);
};
export default LeadDetails;
