import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import profilelogo from "../../Assets/img/profilelogo.png";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import {
	GetAllFranchisesApi,
	UpdateFranchiseStatus,
} from "../../network/Network";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { notFound } from "../../constant";
import Sidebar from "../Profilepages/Sidebar";
import ReactPaginate from "react-paginate";
import DOMPurify from "dompurify";

const Franch = () => {
	const [view, setView] = useState(false);
	const label = { inputProps: { "aria-label": "Switch demo" } };
	const Token = useSelector((state) => state.AuthReducer.token);
	const [myAllFranchises, setMyAllFranchises] = useState([]);
	const [checkSwitch, setCheckSwitch] = useState(false);
	const [spinLoad, setSpinLoad] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		setSpinLoad(true);
		GetAllFranchisesApi(Token, currentPage)
			.then((res) => {
				setMyAllFranchises(res?.data?.response?.data?.data);
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				// dispatch(AllStates(res?.data?.response?.data))
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [checkSwitch, currentPage]);

	const FranchiseStatusHandler = (e, id) => {
		e.preventDefault();
		let data = {
			franchise_id: id,
			status: e.target.value == 1 ? 0 : 1,
		};
		UpdateFranchiseStatus(data, Token)
			.then((res) => {
				toast?.success(res?.data?.message);
				setCheckSwitch(!checkSwitch);
			})
			.catch((err) => {});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As Franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Leads section  */}
			<section className="leads-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							{view ? null : (
								<>
									<div className="all-table">
										<div className="fra">
											<h3>My Franchise</h3>
											<div className="fra-btn">
												<Link to="/add-franchise">Add Franchise </Link>
											</div>
										</div>

										{spinLoad ? (
											<>
												<div className="col-md-12">
													<Skeleton count={5} />
												</div>
												<div className="col-md-12">
													<Skeleton count={5} />
												</div>
												<div className="col-md-12">
													<Skeleton count={5} />
												</div>
											</>
										) : (
											<>
												{myAllFranchises?.length > 0 ? (
													<table>
														<tr>
															<th>ID</th>
															<th>Franchise Logo</th>
															<th>Franchise Name</th>
															<th>Franchise Status</th>
														</tr>
														{myAllFranchises?.map((item, index) => {
															return (
																<tr className="for-background">
																	<td>{item?.id}</td>
																	<td className="table-logo">
																		<img
																			className="img-fluid"
																			src={item?.logo_path}
																		></img>
																	</td>
																	{/* <td>{item?.name}</td> */}
																	<td>
																		{
																			DOMPurify.sanitize(
																				item?.name
																					.replace(/\n/g, " ") // replace newline characters with spaces
																					.replace(/\s+/g, " ") // replace multiple spaces with a single space
																					.replace(
																						/<script[^>]*>([\S\s]*?)<\/script>/gim,
																						" ",
																					) // replace html entities with a single space
																					.replace(
																						/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
																						" ",
																					) // replace html entities with a single space
																					.replace(/&[^;]+;/g, "") // replace html entities with a single space
																					.trim(), // remove any leading or trailing spaces
																				{ ALLOWED_TAGS: [] },
																			)
																			// .replace(/&[^;]+;/g, "") // remove HTML entities
																			// .slice(0, 290)
																		}
																	</td>
																	<td className="total-price">
																		<Switch
																			checked={item?.status == 1 ? true : false}
																			value={item?.status}
																			onChange={(e) =>
																				FranchiseStatusHandler(e, item?.id)
																			}
																			inputProps={{
																				"aria-label": "controlled",
																			}}
																		/>
																	</td>
																	<td className="view-details">
																		<Link to={`/franchise-details/${item?.id}`}>
																			View Details
																		</Link>
																	</td>

																	<td className="view-details">
																		<Link to={`/edit-franchise/${item?.id}`}>
																			Edit Details
																		</Link>
																	</td>
																</tr>
															);
														})}
													</table>
												) : (
													<div className="notFound-container">
														<img
															src={notFound}
															alt="not found"
															className="img-fluid"
														/>
														<p className="mt-4">No Record Found</p>
													</div>
												)}
											</>
										)}

										<div className="pagination-container mt-5">
											<ReactPaginate
												previousLabel="Previous"
												nextLabel="Next"
												breakLabel={"..."}
												pageCount={pageCount}
												marginPagesDisplayed={2}
												pageRangeDisplayed={3}
												onPageChange={handlePageClick}
												containerClassName={"pagination justify-content-center"}
												pageClassName={"page-item"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
												previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
											/>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* End of Leads section  */}

			<Footer />
		</>
	);
};

export default Franch;
