import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { GetFaqs } from "../network/Network";

const Faq = () => {
	const [faqsData, setFaqsData] = useState([]);
	const [loading, setLoading] = useState();
	useEffect(() => {
		setLoading(true);
		GetFaqs()
			.then((res) => {
				setLoading(false);
				setFaqsData(res?.data?.response?.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<div className="accordion" id="accordionExample">
				{loading ? (
					<>
						{[0, 1, 2, 3, 4, 5]?.map((item, index) => {
							return (
								<div className="col-md-12 mt-3" key={index}>
									<Skeleton count={5} />
								</div>
							);
						})}
					</>
				) : (
					<>
						<div id="accordion">
							{faqsData?.map((item, index) => {
								return (
									<div className="card mt-2" key={index}>
										<div className="card-header" id={item?.id}>
											<h5 className="mb-0">
												<button
													className="btn btn-link accordion-button"
													data-toggle="collapse"
													// data-target="#collapseOne"
													// aria-expanded="true"
													// aria-controls="collapseOne"

													data-target={`#${item?.question}${item?.id}`}
													aria-expanded="true"
													aria-controls={`${item?.question}${item?.id}`}
												>
													{item?.question}
												</button>
											</h5>
										</div>

										<div
											// id="collapseOne"
											className="collapse"
											// aria-labelledby="headingOne"
											data-parent="#accordion"
											id={`${item?.question}${item?.id}`}
											aria-labelledby={item?.id}
										>
											<div className="card-body">
												<p
													dangerouslySetInnerHTML={{
														__html: item?.answer,
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Faq;
