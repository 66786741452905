import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/category.css";
import cat1 from "../../Assets/img/cat1.png";
import Bucket from "../../Components/Bucket";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCategoriesApi } from "../../network/Network";
import { AllCategoriesData } from "../../redux/actions/AuthActions";

const Category = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const CategoriesReduxData = useSelector(
		(state) => state.AuthReducer.categories,
	);
	const InvestmentLevelRedux = useSelector(
		(state) => state.AuthReducer.investmentLevels,
	);

	const AllStatesDataRedux = useSelector(
		(state) => state.AuthReducer.allStates,
	);

	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedState, setSelectedState] = useState("");
	const [selectedMinMax, setSelectedMinMax] = useState("");

	const FindHandler = (e) => {
		e.preventDefault();
		let data = {
			category: selectedCategory,
			state: selectedState,
			minMax: selectedMinMax,
		};
		navigate(`/fastest-growing`, { state: { data: data } });
	};

	// Get cate Data
	useEffect(() => {
		GetCategoriesApi()
			.then((res) => {
				dispatch(AllCategoriesData(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Category</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<Bucket />

			{/* Category section  */}
			<section className="category-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<div className="category-head">
								<h5>Search Franchises</h5>
								<h2>By Category</h2>
							</div>
							<div className="category-all">
								<div className="row">
									{CategoriesReduxData?.map((item, index) => {
										return (
											<div className="col-md-4 col-6" key={index}>
												<Link to={`/category-filter/${item?.id}`}>
													<div className="category-img">
														<img
															className="img-fluid"
															src={item?.image_path}
														></img>
														<div className="category-text">
															<h5>{item?.name}</h5>
														</div>
													</div>
												</Link>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="col-md-4">
							<div className="all-things-newd">
								<div className="row">
									<div className="col-md-12">
										<div className="find-frnchise">
											<h3>Search Franchises</h3>
											{/* <p>Search Franchises</p> */}
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<select
												className="form-control"
												id="exampleFormControlSelect1"
												onChange={(e) => {
													setSelectedCategory(e.target.value);
												}}
											>
												<option
													value={
														CategoriesReduxData
															? CategoriesReduxData[0]?.id
															: ""
													}
												>
													{CategoriesReduxData
														? CategoriesReduxData[0]?.name
														: "By Category"}
												</option>
												{CategoriesReduxData?.map((item, index) => {
													return (
														<>
															{item?.name == "All Categories" ? null : (
																<option value={item?.id} key={index}>
																	{item?.name}
																</option>
															)}
														</>
													);
												})}
											</select>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group ">
											<select
												className="form-control"
												id="exampleFormControlSelect1"
												onChange={(e) => {
													setSelectedState(e.target.value);
												}}
											>
												<option
													value={
														AllStatesDataRedux ? AllStatesDataRedux[0]?.id : ""
													}
												>
													{AllStatesDataRedux
														? AllStatesDataRedux[0]?.name
														: "By State"}
												</option>
												{AllStatesDataRedux?.map((item, index) => {
													return (
														<>
															{item?.name == "All States" ? null : (
																<option value={item?.id} key={index}>
																	{item?.name}
																</option>
															)}
														</>
													);
												})}
											</select>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group ">
											<select
												className="form-control"
												id="exampleFormControlSelect1"
												onChange={(e) => {
													setSelectedMinMax(e.target.value);
												}}
											>
												<option
													value={
														InvestmentLevelRedux
															? InvestmentLevelRedux[0]?.id
															: ""
													}
												>
													{InvestmentLevelRedux
														? InvestmentLevelRedux[0]?.name
														: "By Investment Level"}
												</option>
												{InvestmentLevelRedux?.map((item, index) => {
													return (
														<>
															{item?.name == "All Investments" ? null : (
																<option value={item?.id} key={index}>
																	{item?.convert_price}
																</option>
															)}
														</>
													);
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="allthings-table">
									<button onClick={(e) => FindHandler(e)}>Find</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* End of Category section  */}

			<Footer />
		</>
	);
};

export default Category;
