import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Bucket from "../../Components/Bucket";
import { Link, useNavigate } from "react-router-dom";
import { PostQuestionnaire } from "../../network/Network";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { useRef } from "react";

const Disclosure = () => {
	const navigate = useNavigate();
	const [interests, setInterests] = useState([]);

	const sigCanvas = useRef();
	const [imageURL, setImageURL] = useState(null);

	const Capture = () => {
		const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		setImageURL(URL);
	};

	const new_date = new Date().toISOString().slice(0, 10);
	const [date, setDate] = useState();
	const [name, setName] = useState();

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span> Disclosure Form</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<Bucket />
			{/* contact section  */}
			<section className="contact-sec conatct-sec-for-padding disclosure">
				<div className="container">
					<div className="contact-head">
						<h3>Disclosure Form</h3>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div>
								<h2>
									The Value of the Franchise Consultant to their Candidates:
								</h2>
								<p>
									There is significant value when it comes to working with a
									franchise consultant to find a franchise? Make sure the time
									spent with a franchise consultant brings direct value to you.
									And that value should be measurable!{" "}
								</p>

								<ul>
									<li>
										We only have so much time in a day! A Franchise Velocity
										consultant listens, qualifies, understands you, and focuses
										on alignment of your goals to the franchise company. A
										win-win for all!
									</li>
									<li>
										Which is better; one concept or a few hundred? Franchise
										Velocity consultants represent a few hundred franchise
										companies (their franchise clients). They are not focused on
										one brand or one industry. They are focused on you and come
										from an unbiased perspective!
									</li>
									<li>
										Relationships matter! Now, this may be subjective but it is
										measurable! The franchise consultant will develop a
										relationship with you to understand YOU. They will show you
										franchise companies or industries you may not have thought
										of or concepts that are out of the box! The results lead to
										finding the RIGHT franchise that will meet YOUR goals! Now
										that is measurable!
									</li>
									<li>
										The candidate does NOT pay anything additional to work with
										a franchise consultant! The franchise company pays the
										consultant if it results in an executed franchise agreement.
										The same price if they worked directly with the franchise
										company!
									</li>
									<li>
										The franchise consultant has professional resources that are
										experts in the franchise industry. This includes franchise
										attorneys, funding providers, and business advisors.
									</li>
								</ul>

								<h2>
									The Franchise Consultants Value to the Franchise Company
									(franchisor):
								</h2>
								<ul>
									<li>
										The franchise consultant qualifies the candidate to insure
										they meet the qualifications required by the franchisor.
									</li>
									<li>
										Qualifying the candidate will include the territory being
										available, insuring they are financially qualified which may
										include pre-qualification with a funding provider, an
										understanding of their background and goals, and a
										high-level understanding of the specific franchise company.{" "}
									</li>
									<li>
										The franchise consultant helps to educate the candidate
										about franchising and encourages the candidate to do
										extensive research during their discovery.
									</li>
								</ul>

								<h2>
									The Candidates Responsibility in the Franchise Ownership
									Discovery Process:
								</h2>
								<p>
									The choice is YOURS and yours alone! The franchise consultant
									will help guide you through a methodical process but the
									investigation of each franchise you consider is yours alone.
									Once you enter into a franchise agreement, the relationship is
									governed by the contract between you and the franchise
									company. We strongly suggest you consult legal and financial
									advisors before you enter into a franchise agreement. The
									franchise consultant will provide professional resources that
									you can choose to employ.
								</p>

								<h2>DISCLOSURE Provided to the Prospective Franchise Buyer</h2>

								<ul>
									<li>
										The licensed Franchise Velocity broker is not an employee of
										the seller of the respective business opportunities and
										franchises introduced to you.
									</li>
									<li>
										The licensed Franchise Velocity broker is an Independent
										Contractor, engaged in the activity of introducing
										prospective business buyers to sellers of franchises and
										business opportunities.
									</li>
									<li>
										The licensed Franchise Velocity broker is paid a finder’s
										fee or commission by the seller if you elect to purchase a
										business from a company that was introduced by the licensed
										Franchise Velocity broker.
									</li>
									<li>
										The licensed Franchise Velocity broker has not checked the
										accuracy of the information provided by the seller, and
										assumes no responsibility for the acts, errors, or omissions
										of the seller, or the outcome of any transaction.
									</li>
									<li>
										The licensed Franchise Velocity broker is not qualified to
										advise in any part of the purchase of a business
										opportunity. You are urged to seek professional advice from
										a lawyer, accountant, or other qualified resource.
									</li>
									<li>
										Buying a business opportunity is a complicated investment.
										Take your time to decide, and personally visit the
										headquarters of the company you elect to buy from. Make
										good, common sense decisions in all investment matters.
									</li>
									<li>
										The Federal Trade Commission regulates the sale of
										franchises and business opportunities. Contact the nearest
										office to you for information to assure yourself that any
										company you deal with is in full compliance with the law.
									</li>
									<li>
										Your state may also have laws regarding franchises and
										business opportunities. Ask your state agencies about them.
									</li>
									<li>
										The Better Business Bureau is an excellent information
										source for potential business buyers. Contact your local
										bureau as well as the bureau in the city of origin of the
										seller. You alone are responsible for researching the
										seller.
									</li>
								</ul>
								<p>
									I acknowledge receipt of this disclosure information. I
									clearly understand that the licensed Franchise Velocity broker
									has no standing or involvement in my transaction with any
									business opportunity seller. I further agree to save and hold
									harmless that broker in any event regarding my ultimate
									business transaction and outcome with any seller that he
									introduces to me. The licensed Franchise Velocity broker has
									made no verbal representations that contradict any portion of
									this disclosure.
								</p>

								<div className="row mt-5">
									<div className="col-md-4">
										<input
											type="text"
											placeholder="Name"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div className="col-md-4">
										<input
											type="date"
											min={new_date}
											onChange={(e) => {
												setDate(e.target.value);
											}}
										/>
									</div>
									<div className="col-md-4">
										{imageURL ? (
											<>
												<div className="sig-img">
													<img
														src={imageURL}
														alt="signature"
														className="signature"
													/>
												</div>
											</>
										) : null}
									</div>

									<div className="col-md-12">
										<SignatureCanvas
											canvasProps={{ className: "sigCanvas" }}
											penColor="black"
											ref={sigCanvas}
										/>
										<button
											className="btn clear"
											onClick={() => sigCanvas.current.clear()}
										>
											Clear
										</button>
										<button className="btn Capture ml-3" onClick={Capture}>
											Capture
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of contact section  */}

			<Footer />
		</>
	);
};

export default Disclosure;
