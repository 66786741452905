import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";

const Sidebar = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const userData = useSelector((state) => state.AuthReducer.users);
	const LogoutHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		setTimeout(async () => {
			setLoading(false);
			let x = await dispatch(logout());
			window.location.href = "/login";
		}, 600);
	};

	return (
		<>
			<div className="dash-tab">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
					<div className="dashboard-head">
						<h3>
							{userData?.first_name} {userData?.last_name}
						</h3>
					</div>
					<li className="nav-item">
						<Link to="/profile-account">Account</Link>
					</li>
					{userData?.user_type == "advertisor" ? null : (
						<>
							<li className="nav-item">
								<Link to="/leads">Leads</Link>
							</li>

							<li class="nav-item">
								<Link className="color-view" to="/leads">
									View Leads
								</Link>
							</li>
							<li class="nav-item">
								<Link className="color-view" to="/purchase-leads">
									Purchase Leads
								</Link>
							</li>
						</>
					)}

					<li class="nav-item">
						<Link
							to={`${
								userData?.user_type == "broker"
									? "/franch"
									: "/manage-franchise"
							}`}
						>
							Manage Franchise
						</Link>
					</li>

					{userData?.user_type == "advertisor" ? null : (
						<li class="nav-item">
							<Link to="/card-info">Card Information</Link>
						</li>
					)}

					<div className="sign-out">
						<button onClick={(e) => LogoutHandler(e)}>
							{loading ? "Loading.." : "Sign out"}
						</button>
					</div>
				</ul>
			</div>
		</>
	);
};

export default Sidebar;
