import { Lead } from "bootstrap-4-react/lib/components";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { notFound } from "../../constant";
import { GetMyLeads } from "../../network/Network";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";

const Leads = () => {
	const navigate = useNavigate();
	const Token = useSelector((state) => state.AuthReducer.token);
	const UserData = useSelector((state) => state.AuthReducer.users);
	const [myAllLeads, setMyAllLeads] = useState([]);
	const [view, setView] = useState(false);
	const [spinLoad, setSpinLoad] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		setSpinLoad(true);
		GetMyLeads(Token, currentPage)
			.then((res) => {
				setMyAllLeads(res?.data?.response?.data?.data);
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [currentPage]);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>
										Join As{" "}
										{UserData?.user_type == "broker"
											? "Franchisor Consultant"
											: "Franchisor"}
									</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Leads section  */}
			<section className="leads-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="all-table">
								<h3>My Leads</h3>

								{spinLoad ? (
									<>
										<div className="col-md-12">
											<Skeleton count={5} />
										</div>
										<div className="col-md-12">
											<Skeleton count={5} />
										</div>
									</>
								) : (
									<>
										<table>
											<tr>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Email Address</th>
												<th>Contact Number</th>
											</tr>
											{myAllLeads?.length > 0 ? (
												<>
													{myAllLeads?.map((item, index) => {
														return (
															<tr className="for-background">
																<td>{item?.first_name}</td>
																<td>{item?.last_name}</td>
																<td>{item?.email}</td>
																<td className="contact-number">
																	{item?.phone}
																</td>
																<td className="view-details">
																	{" "}
																	<button
																		className="view-details"
																		onClick={() =>
																			navigate(`/lead-details/${item?.id}`)
																		}
																	>
																		view details
																	</button>
																</td>
															</tr>
														);
													})}
												</>
											) : (
												<div className="notFound-container">
													<img
														src={notFound}
														alt="not found"
														className="img-fluid"
													/>
													<p className="mt-4">No Record Found</p>
												</div>
											)}
										</table>
									</>
								)}

								{/* <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#">
                        Previous
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav> */}
								<div className="pagination-container mt-5">
									<ReactPaginate
										previousLabel="Previous"
										nextLabel="Next"
										breakLabel={"..."}
										pageCount={pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={"pagination justify-content-center"}
										pageClassName={"page-item"}
										pageLinkClassName={"page-link"}
										previousClassName={"page-item"}
										previousLinkClassName={"page-link"}
										nextClassName={"page-item"}
										nextLinkClassName={"page-link"}
										breakClassName={"page-item"}
										breakLinkClassName={"page-link"}
										activeClassName={"active"}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Leads section  */}

			<Footer />
		</>
	);
};
export default Leads;
