import logo from "./logo.svg";
import "./App.css";
import "./Assets/css/style.css";
import Publicroutes from "./Routes/Publicroutes";
import "bootstrap-4-react/dist/bootstrap-4-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Assets/css/Custom.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect } from "react";
import AutoLogout from "./Components/AutoLogout";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
	return (
		<>
			<AutoLogout />
			<Publicroutes />
			{/* <TawkMessengerReact
                propertyId="property_id"
                widgetId="default"/> */}
			<ToastContainer />
		</>
	);
}

export default App;
