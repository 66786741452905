import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../Assets/img/logo.png";
import { GetAllAvailableStates } from "../network/Network";
import { AllStates } from "../redux/actions/AuthActions";

const Footer = () => {
	const dispatch = useDispatch();
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);

	useEffect(() => {
		GetAllAvailableStates()
			.then((res) => {
				// setGetAllStates(res?.data?.response?.data);
				dispatch(AllStates(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	return (
		<>
			<footer>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="footer-logo">
								<Link to="/">
									<img className="img-fluid" src={SiteSettingsRedux?.logo_path} />
								</Link>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-3">
							<div className="widget1">
								<div className="footer-head">
									<h3>Quick Links</h3>
								</div>
								<div className="footer-ul">
									<ul>
										<li>
											<Link to="/about">About Us</Link>
										</li>
										<li>
											<Link to="/contact-us">Contact</Link>
										</li>
										<li>
											<Link to="/terms">Terms & Conditions</Link>
										</li>
										<li>
											<Link to="/privacy">Privacy Policy</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-md-3">
							<div className="widget2">
								<div className="footer-head">
									<h3>Advertise with Us</h3>
								</div>
								<div className="footer-ul">
									<ul>
										<li>
											<Link to="/signup-franchisor">Join as a Franchisor</Link>
										</li>
										<li>
											<Link to="/signup-broker ">
												Join as a Franchise Consultant
											</Link>
										</li>
										<li>
											<Link to="/login">Partner Login</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-md-2">
							<div className="widget3">
								<div className="footer-head">
									<h3>Follow Us</h3>
								</div>
								<div className="footer-ul">
									<ul>
										<li>
											<a href={SiteSettingsRedux?.facebook} target="_blank">
												Facebook
											</a>
										</li>
										<li>
											<a href={SiteSettingsRedux?.instagram} target="_blank">
												Instagram
											</a>
										</li>
										<li>
											<a href={SiteSettingsRedux?.linkedin} target="_blank">
												LinkedIn
											</a>
										</li>
										<li>
											<a href={SiteSettingsRedux?.tiktok_url} target="_blank">
												TikTok
											</a>
										</li>
										<li>
											<a href={SiteSettingsRedux?.youtube} target="_blank">
												YouTube
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-md-2">
							<div className="widget4">
								<div className="footer-head">
									<h3>Resources</h3>
								</div>
								<div className="footer-ul">
									<ul>
										<li>
											<Link to="/franchise101">Franchising 101</Link>
										</li>
										<li>
											<Link to="/news-letter">Newsletter Sign-up</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="col-md-2">
							<div className="widget4">
								<div className="footer-head">
									<h3>Services</h3>
								</div>
								<div className="footer-ul">
									<ul>
										<li>
											<Link to="/free-consultation">Free Consultation</Link>
										</li>
										<li>
											<Link to="/cash-program">Cash Back Program</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="copy-right">
						<p>
							{/* © 2022 <span>Franchise Velocity </span> | All Rights Reserved */}
							{SiteSettingsRedux?.copyright}
						</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
