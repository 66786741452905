import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Contactus from "../../Components/Contactus";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import cashprogram from "../../Assets/img/cashprogram.png";
import what from "../../Assets/img/what.png";
import constitution from "../../Assets/img/constitution.png";
import Bucket from "../../Components/Bucket";

import {
	ConsultationForm,
	GetAllAvailableStates,
	GetAllInvestmentLevels,
	GetAvailableFranchise,
	GetCategoriesApi,
	GetSiteSettingsApi,
	PagesApi,
} from "../../network/Network";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	AllAvailableFranchise,
	AllCategoriesData,
	AllStates,
	InvestmentLevel,
	WebSiteSettings,
} from "../../redux/actions/AuthActions";
import FranchiseCard from "../../Components/Card/FranchiseCard";
import { toast } from "react-toastify";
import { AddToCart } from "../../redux/actions/CartActions";
import Skeleton from "react-loading-skeleton";
import { notFound } from "../../constant";
import ConsultationModal from "../../Components/Modal/ConsultationModal";
import AutoLogout from "../../Components/AutoLogout";

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const Token = useSelector((state) => state.AuthReducer.token);
	const [bucket, setBucket] = useState(false);
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedState, setSelectedState] = useState("");
	const [selectedMinMax, setSelectedMinMax] = useState("");
	const [cashBackData, setCashBackData] = useState([]);
	const [whatData, setWhatData] = useState([]);
	const [consultData, setConsultData] = useState([]);
	const [spinLoad, setSpinLoad] = useState(false);
	const [searchName, setSearchName] = useState("");
	const [viewLoader, setViewLoader] = useState(false);
	const [selectedFranchise, setSelectedFranchise] = useState(null);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [stateUser, setStateUser] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [cashAvailable, setCashAvailable] = useState("");
	const [loading, setLoading] = useState(false);
	const [isOpenConsultationModal, setIsOpenConsultationModal] = useState(false);
	const [checkState, setCheckState] = useState(false);
	const [loadMoreFeatured, setLoadMoreFeatured] = useState(4);
	const [hasMore, setHasMore] = useState(false);

	const CategoriesReduxData = useSelector(
		(state) => state.AuthReducer.categories,
	);
	const AllStatesDataRedux = useSelector(
		(state) => state.AuthReducer.allStates,
	);
	const availableFranchiseRedux = useSelector(
		(state) => state.AuthReducer.availableFranchise,
	);

	const availableFeaturedFranchises = availableFranchiseRedux
		?.map((franchise) => {
			if (franchise?.is_featured === 1) {
				return franchise;
			}
			return null;
		})
		.filter(Boolean);

	const InvestmentLevelRedux = useSelector(
		(state) => state.AuthReducer.investmentLevels,
	);

	// Get cate Data
	// useEffect(() => {
	//   console.log(availableFranchiseRedux, "check redux featured");
	//   console.log(availableFeaturedFranchises, "check featured");
	// }, []);

	// Get cate Data
	useEffect(() => {
		GetCategoriesApi()
			.then((res) => {
				dispatch(AllCategoriesData(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	// Get Pages Data
	useEffect(() => {
		GetSiteSettingsApi()
			.then((res) => {
				dispatch(WebSiteSettings(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	// All States
	useEffect(() => {
		GetAllAvailableStates()
			.then((res) => {
				dispatch(AllStates(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	const FranchiseFilterHandler = (e = null) => {
		if (e) {
			e.preventDefault();
		}
		// setViewLoader(true);
		e.preventDefault();
		let data = {
			category: selectedCategory == 0 ? " " : selectedCategory,
			state: selectedState == 0 ? " " : selectedState,
			minMax: selectedMinMax == 0 ? " " : selectedMinMax,
		};
		navigate(`/fastest-growing`, { state: { data: data } });
	};

	useEffect(() => {
		setViewLoader(true);
		GetAvailableFranchise()
			.then((res) => {
				dispatch(AllAvailableFranchise(res?.data?.response?.data?.data));
				setViewLoader(false);
			})
			.catch((err) => {
				setViewLoader(false);
			});
	}, []);

	const AddToCartHandler = (e, item) => {
		e.preventDefault();
		let checkItemAlreadyExist = CartDataRedux.filter(
			(val) => val?.id === item?.id,
		);
		if (checkItemAlreadyExist?.length > 0) {
			alert("Item Already Exist in Cart!!!");
		} else {
			let data = {
				id: item?.id,
				price: item?.price,
				productitem: item,
			};
			dispatch(AddToCart(data));
			toast.success("Product Successfully Add ");
		}

		// reset the background color of the previously selected button
		if (selectedFranchise && document.getElementById(selectedFranchise)) {
			document.getElementById(selectedFranchise).style.background = "#d4d5d5";
		}

		// set background color to #000
		e.target.style.background = "#03a84e";

		// set the id of the newly selected button
		setSelectedFranchise(e.target.id);
	};

	useEffect(() => {
		let name = AllStatesDataRedux?.filter((val) => val?.id == selectedState);
		setSearchName(name ? name[0]?.name : " ");
	}, [selectedState]);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/cash-back-programs";
		PagesApi(slug)
			.then((res) => {
				setCashBackData(res?.data?.response?.data);

				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/what-we-do";
		PagesApi(slug)
			.then((res) => {
				setWhatData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/free-consultation";
		PagesApi(slug)
			.then((res) => {
				setConsultData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	const SubmitConsultation = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!firstName ||
			!lastName ||
			!email ||
			!stateUser ||
			!zip ||
			!phone ||
			!cashAvailable
		) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}
		if (!checkState) {
			toast.error("Please agree with our term and conditions");
			setLoading(false);
			return;
		}
		var data = new FormData();
		data.append("type", "cash_back");
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("email", email);
		data.append("state", stateUser);
		data.append("zip", zip);
		data.append("phone", phone);
		data.append("cash_available_to_invest", cashAvailable);
		ConsultationForm(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setIsOpenConsultationModal(false);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setStateUser("");
				setZip("");
				setPhone("");
				setCashAvailable("");
				setCheckState(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const handlLoadMore = () => {
		setLoadMoreFeatured(loadMoreFeatured + 5);
	};

	useEffect(() => {
		loadMoreFeatured <= availableFeaturedFranchises?.length
			? setHasMore(true)
			: setHasMore(false);
	}, [loadMoreFeatured]);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner banner-second">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="banner-text1">
								{/* <AutoLogout /> */}
								<h2>Get Up to $10k Cash Back After Closing</h2>

								<p>
									<span>
										<Link
											to=""
											onClick={() => setIsOpenConsultationModal(true)}
										>
											Sign up{" "}
										</Link>
										for our revolutionary, 100% free program today!
									</span>
								</p>
								{/* <p>
                  {" "}
                  {Token ?
                    <span>
                      <Link to="/profile-account">Sign up </Link>for our
                      revolutionary, 100% free program today!
                    </span>
                    :
                    <span>
                      <Link to="/signup-franchisor">Sign up </Link>for our
                      revolutionary, 100% free program today!
                    </span>
                  }
                </p> */}
							</div>
						</div>
					</div>
					<div className="explore">
						<a href="#explore">
							<h3>EXPLORE NOW</h3>
							<i class="fa fa-angle-double-down" aria-hidden="true"></i>
						</a>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Find Franchise for sale  */}
			<section className="find-franchise-sec">
				<div className="container">
					<div className="main-head">
						<h2>Find Franchises For Sale</h2>
					</div>
					<div className="row">
						<div className="col-md-3 pos-rel">
							{/* <i class="fa fa-chevron-down pos_absl_select_arw" aria-hidden="true"></i> */}
							<select
								className="form-control"
								id="state"
								name="state"
								onChange={(e) => {
									setSelectedCategory(e.target.value);
								}}
							>
								<option
									value={CategoriesReduxData ? CategoriesReduxData[0]?.id : ""}
								>
									{CategoriesReduxData
										? CategoriesReduxData[0]?.name
										: "By Category"}
								</option>
								{CategoriesReduxData?.map((item, index) => {
									return (
										<>
											{item?.name == "All Categories" ? null : (
												<option value={item?.id} key={index}>
													{item?.name}
												</option>
											)}
										</>
									);
								})}
							</select>
						</div>

						<div className="col-md-3 pos-rel">
							{/* <i class="fa fa-chevron-down pos_absl_select_arw" aria-hidden="true"></i> */}
							<select
								className="form-control"
								id="state"
								name="state"
								onChange={(e) => {
									setSelectedMinMax(e.target.value);
								}}
							>
								<option
									value={
										InvestmentLevelRedux ? InvestmentLevelRedux[0]?.id : ""
									}
								>
									{InvestmentLevelRedux
										? InvestmentLevelRedux[0]?.name
										: "By Investment"}
								</option>
								{InvestmentLevelRedux?.map((item, index) => {
									return (
										<>
											{item?.name ? null : (
												<option value={item?.id} key={index}>
													{item?.convert_price}
												</option>
											)}
										</>
									);
								})}
							</select>
						</div>

						<div className="col-md-3 pos-rel">
							{/* <i class="fa fa-chevron-down pos_absl_select_arw" aria-hidden="true"></i> */}
							<select
								className="form-control"
								id="state"
								name="state"
								onChange={(e) => {
									setSelectedState(e.target.value);
								}}
							>
								<option
									value={AllStatesDataRedux ? AllStatesDataRedux[0]?.id : ""}
								>
									{AllStatesDataRedux
										? AllStatesDataRedux[0]?.name
										: "By State"}
								</option>
								{AllStatesDataRedux?.map((item, index) => {
									return (
										<>
											{item?.name == "All States" ? null : (
												<option value={item?.id} key={index}>
													{item?.name}
												</option>
											)}
										</>
									);
								})}
							</select>
						</div>

						<div className="col-md-3">
							<div className="search-btn">
								<button
									className="searchBtn"
									onClick={(e) => FranchiseFilterHandler(e)}
								>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Find Franchise for sale  */}

			<section className="blue-sec">
				<h4>Add Franchises To Basket</h4>
				<h4>For Free Information</h4>
			</section>

			{CartDataRedux?.length > 0 ? <Bucket /> : null}

			{/* Featured section  */}
			<section className="featured-sec">
				<div className="container">
					<div className="featured-sec-head" id="explore">
						<h2>Featured Franchises</h2>
					</div>
					<div className="row">
						{viewLoader ? (
							<>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{availableFeaturedFranchises?.length > 0 ? (
									<>
										{availableFeaturedFranchises
											?.slice(0, loadMoreFeatured)
											?.map((item, index) => {
												return (
													<>
														{/* {item?.is_featured == 1 ? ( */}
														<FranchiseCard
															item={item}
															index={index}
															setBucket={setBucket}
															bucket={bucket}
															AddToCartHandler={AddToCartHandler}
														/>
														{/* ) : null} */}
													</>
												);
											})}
										{hasMore === true ? (
											<>
												<div className="col-lg-12">
													<div className="sign-up-btn">
														{/* <Link to="/fastest-growing">View More</Link> */}
														<a onClick={handlLoadMore}>View More</a>
													</div>
												</div>
											</>
										) : null}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="image-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</section>
			{/* End of Featured section  */}

			{/* cash programs section  */}
			<section className="cash-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							{/* <div className="cash-img">
                <img className="img-fluid" src={cashprogram}></img>
              </div> */}
							<div className="video-div">
								<video
									className="videoTag"
									controls
									// autoPlay
									loop
									muted
									src={cashBackData?.image_url}
								>
									{/* <source  src={cashBackData?.image_url}></source> */}
								</video>
							</div>
						</div>

						{spinLoad ? (
							<>
								<div className="col-md-6">
									<Skeleton count={8} />
								</div>
							</>
						) : (
							<div className="col-md-6">
								<div className="head-sub-head">
									<h5>100% Free</h5>
									<h3>{cashBackData?.page_title}</h3>
								</div>
								<div className="cash-Head-para">
									<p
										dangerouslySetInnerHTML={{ __html: cashBackData?.content }}
									/>
								</div>

								<div className="sign-up-btn">
									{/* {
                    Token ? (null) : (
                      <Link to="/login">Sign Up Now</Link>
                    )
                  } */}
									<Link to="" onClick={() => setIsOpenConsultationModal(true)}>
										Sign Up Now
									</Link>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
			{/* End of cash programs section  */}

			{/* what we do section  */}
			<section className="what-we-do-sec">
				<div className="container">
					<div className="row align-items-center">
						{spinLoad ? (
							<>
								<div className="col-md-6">
									<Skeleton count={8} />
								</div>
							</>
						) : (
							<div className="col-md-6">
								<div className="head-sub-head">
									<h5>About Us</h5>
									<h3>{whatData?.page_title}</h3>
								</div>

								<div className="cash-Head-para">
									<p dangerouslySetInnerHTML={{ __html: whatData?.content }} />
								</div>
							</div>
						)}
						<div className="col-md-6">
							<div className="cash-img">
								<img className="img-fluid" src={what}></img>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* End of What we do section  */}

			{/* cash programs section  */}

			<section className="cash-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="cash-img">
								<img className="img-fluid" src={constitution}></img>
							</div>
						</div>
						{spinLoad ? (
							<>
								<div className="col-md-6">
									<Skeleton count={8} />
								</div>
							</>
						) : (
							<div className="col-md-6">
								<div className="head-sub-head">
									<h5>Get Started Now</h5>
									<h3>{consultData?.page_title}</h3>
								</div>

								<div className="cash-Head-para">
									<p
										dangerouslySetInnerHTML={{ __html: consultData?.content }}
									/>
								</div>

								<div className="sign-up-btn register-now-btn">
									<Link to="/free-consultation">Register Now</Link>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
			{/* End of cash programs section  */}

			<Contactus />

			<Footer />

			<ConsultationModal
				heading="Cash Back Program"
				content="Sign up below"
				setIsOpenConsultationModal={setIsOpenConsultationModal}
				isOpenConsultationModal={isOpenConsultationModal}
				SubmitConsultation={SubmitConsultation}
				firstName={firstName}
				setFirstName={setFirstName}
				lastName={lastName}
				setLastName={setLastName}
				email={email}
				setEmail={setEmail}
				stateUser={stateUser}
				setStateUser={setStateUser}
				zip={zip}
				setZip={setZip}
				phone={phone}
				setPhone={setPhone}
				cashAvailable={cashAvailable}
				setCashAvailable={setCashAvailable}
				loading={loading}
				setLoading={setLoading}
				checkState={checkState}
				setCheckState={setCheckState}
			/>
		</>
	);
};

export default Home;
