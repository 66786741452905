import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/request.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClearCart, deleteCartItem } from "../../redux/actions/CartActions";
import { useState } from "react";
import { UserLeadCreate } from "../../network/Network";
import { toast } from "react-toastify";

import TermsModal from "../../Components/Modal/TermsModal";

const Request = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [cartData, setCartData] = useState([CartDataRedux]);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [country, setCountry] = useState("");
	const [stateUser, setStateUser] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");

	const [cashAvailable, setCashAvailable] = useState("");
	const [comment, setComment] = useState("");
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);

	const RemoveCartItem = (id) => {
		const items = cartData;
		let currentItem = id;
		if (items.length > 0) {
			setCartData(
				items.filter((item, index) => item?.productitem?.id !== currentItem),
			);
			dispatch(deleteCartItem(currentItem));
		}
	};

	const TermCheck = (event) => {
		setChecked(!checked);
	};

	const SubmitUserLead = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!firstName) {
			toast.error("Please Enter your first name");
			setLoading(false);
			return;
		}
		if (!lastName) {
			toast.error("Please Enter your last name");
			setLoading(false);
			return;
		}
		if (!email) {
			toast.error("Please Enter your email address");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		// if (comment?.length < 20) {
		//   toast.error("The message must be at least 20 characters");
		//   setLoading(false);
		//   return;
		// }
		if (!checked) {
			toast.error("Please agree with our term and conditions");
			setLoading(false);
			return;
		}
		var data = new FormData();
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("email", email);
		data.append("country", country);
		data.append("state", stateUser);
		data.append("zip", zip);
		data.append("phone", phone);
		data.append("comments", comment);
		data.append("cash_available_to_invest", cashAvailable);
		CartDataRedux?.map((item) => {
			data.append("franchise_id[]", item?.productitem?.id);
		});
		UserLeadCreate(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				dispatch(ClearCart());
				setFirstName("");
				setLastName("");
				setEmail("");
				setCountry("");
				setStateUser("");
				setComment("");
				setZip("");
				setPhone("");
				setCashAvailable("");
				setChecked(!checked);
				navigate("/");
			})
			.catch((err) => {
				if (err?.response?.data?.errors?.email[0]) {
					toast.error(err?.response?.data?.errors?.email[0]);
				}
				setLoading(false);
			});
	};

	return (
		<>
			<Header />

			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Request Info</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Request section  */}

			<section className="request-sec">
				<div className="container">
					<div className="all-cover-things">
						<div className="req-head">
							<h3>Checkout</h3>
							<p>Free Franchise Information Request</p>
						</div>
						<div className="row">
							<div className="col-md-8">
								<div className="forms">
									<form>
										<div className="row">
											<div className="col-md-6">
												<div class="form-group">
													<input
														type="text"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="First Name"
														value={firstName}
														onChange={(e) => setFirstName(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="text"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Last Name"
														value={lastName}
														onChange={(e) => setLastName(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="email"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Email Address"
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="text"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Country"
														value={country}
														onChange={(e) => setCountry(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="text"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="State"
														value={stateUser}
														onChange={(e) => setStateUser(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-md-6">
												<div class="form-group">
													<input
														type="number"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Zip Code"
														value={zip}
														onChange={(e) => setZip(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="number"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Phone Number"
														value={phone}
														onChange={(e) => setPhone(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-6">
												<div class="form-group">
													<input
														type="number"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Cash Available to Invest"
														value={cashAvailable}
														onChange={(e) => setCashAvailable(e.target.value)}
													/>
												</div>
											</div>

											<div className="col-md-12">
												<div class="form-group text-area">
													<textarea
														class="form-control"
														id="exampleFormControlTextarea1"
														rows="3"
														placeholder="Comments"
														value={comment}
														onChange={(e) => setComment(e.target.value)}
													></textarea>
												</div>
											</div>

											<div className="col-md-12">
												<div className="form-group custom_check">
													<input
														type="checkbox"
														id="checkbox"
														value={checked}
														onChange={(e) => TermCheck(e)}
													/>
													<label for="checkbox">
														{/* Agree to <Link to="/terms">Terms & Conditions</Link> */}
														{/* <Link>Terms & Conditions</Link> */} Agree to{" "}
														<span onClick={() => setIsOpenTermsModal(true)}>
															Terms & Conditions
														</span>
													</label>
												</div>
											</div>

											<div className="col-md-12">
												<div className="form-buctket-done-btn">
													<button
														onClick={(e) => SubmitUserLead(e)}
														disabled={loading}
													>
														{loading
															? "Loading..."
															: "Request Free Information"}
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>

							<div className="col-md-4">
								<div className="all-for-scroll">
									{CartDataRedux?.map((item, index) => {
										return (
											<div className="atax-dv" key={index}>
												<h3>{item?.productitem?.name}</h3>
												<div className="image-cont">
													<img
														className="img-fluid"
														src={item?.productitem?.logo_path}
													></img>
												</div>
												{/* <p
                          dangerouslySetInnerHTML={{
                            __html: item?.productitem?.description,
                          }}
                        /> */}
												{/* <h4>
                          Cash Required{" "}
                          <span> {item?.productitem?.min_cash_required}</span>{" "}
                        </h4> */}
												<div
													className="dv-close"
													onClick={() => RemoveCartItem(item?.productitem?.id)}
												>
													<i class="fa fa-times" aria-hidden="true"></i>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Request section  */}

			<Footer />
			<TermsModal
				setIsOpenTermsModal={setIsOpenTermsModal}
				isOpenTermsModal={isOpenTermsModal}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
};

export default Request;
