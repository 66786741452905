import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/franchise101.css";
import Bucket from "../../Components/Bucket";
import {
	franchise101ApiCall,
	getDocuments,
	GetFaqs,
} from "../../network/Network";
import { Link } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import file from "../../Assets/img/Net Worth Calculator.xlsx";
import Skeleton from "react-loading-skeleton";
import Faq from "../../Components/Faq";

const Franchise101 = () => {
	const [state, setState] = useState();
	const [faqsData, setFaqsData] = useState([]);
	const [docData, setDocData] = useState([]);
	const [loading, setLoading] = useState();

	useEffect(() => {
		setLoading(true);
		GetFaqs()
			.then((res) => {
				setLoading(false);
				setFaqsData(res?.data?.response?.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		getDocuments()
			.then((res) => {
				setLoading(false);
				setDocData(res?.data?.response?.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		franchise101ApiCall()
			.then((res) => {
				setLoading(false);
				setState(res?.data?.response?.data);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span> Franchising 101</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}
			<Bucket />
			{loading ? (
				<div className="container my-5">
					<Skeleton count={8} />
				</div>
			) : (
				<section className="franchise101_page">
					<div className="container">
						{/* <div className="head-sub-head ">
              <h2>{state?.page_title}</h2>
              <div dangerouslySetInnerHTML={{ __html: state?.content }}></div>
            </div> */}
						<div className="row">
							<div className="col-md-12">
								<div className="head-sub-head ">
									<h2>FAQs</h2>
								</div>
								<Faq />
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="head-sub-head ">
									<h2>{state?.page_title}</h2>
									<div
										dangerouslySetInnerHTML={{ __html: state?.content }}
									></div>
								</div>
								<ul>
									{docData?.map((item, index) => {
										try {
											const extension = item.download_url.match(
												/\.(mp4|mov|avi|wmv|flv|mpg)$/i,
											)[1];
											if (extension === "mp4") {
												return null;
											}
										} catch (error) {}
										return (
											<li key={index}>
												<a
													// className="btn download-btn"
													target="_blank"
													href={item?.download_url}
													download={item?.filename}
												>
													{item?.title}{" "}
													<FaFileDownload className="download-icon" />
												</a>
											</li>
										);
									})}

									{/* {docData?.map((item, index) => {
                    return (
                      <li key={index}>
                        <a
                          // className="btn download-btn"
                          target="_blank"
                          href={item?.download_url}
                          download={item?.filename}
                        >
                          {item?.title}{" "}
                          <FaFileDownload className="download-icon" />
                        </a>
                      </li>
                    );
                  })} */}
								</ul>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="head-sub-head ">
									{/* <h3 className="video-head">Video Warehouse</h3> */}
									<h2>Video Warehouse</h2>
								</div>
								<div className="row">
									{/* {docData?.map((item, index) => {
                    try {
                      const extension = item.download_url.match(
                        /\.(mp4|mov|avi|wmv|flv|mpg)$/i
                      )[1];
                      if (extension === "mp4") {
                        return (
                          <div className="col-md-4" style={{margin:"20px"}}>
                            <video
                              width="400"
                              height="280"
                              controls
                              key={index}
                            >
                              <source
                                src={item.download_url}
                                type={`video/${extension}`}
                              />
                            </video>
                          </div>
                        );
                      }
                    } catch (error) {

                    }
                    return null;
                  })} */}

									{docData?.map((item, index) => {
										if (item?.youtube_url != null) {
											return (
												<div
													className="col-lg-4 col-md-6 mt-4"
													// style={{ margin: "20px" }}
													key={index}
												>
													{/* <video width="400" height="280" controls>
                            <source src={item?.youtube_url} type="video/mp4" />
                          </video> */}
													<iframe
														width="100%"
														height="280"
														src={item?.youtube_url}
														title={item?.title}
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														allowfullscreen
													></iframe>
												</div>
											);
										} else {
											return null;
										}
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{/* section franchise 101 end here */}
			<section></section>
			<Footer />
		</>
	);
};
export default Franchise101;
