import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Bucket from "../../Components/Bucket";
import { Link, useNavigate } from "react-router-dom";
import { PostQuestionnaire } from "../../network/Network";
import { toast } from "react-toastify";

const ConfidentialQuestionnaire = () => {
	const navigate = useNavigate();
	const [interests, setInterests] = useState([]);
	const [businessAppeals, setBusinessAppeals] = useState([]);
	const [loading, setLoading] = useState(false);
	const [funding, setFunding] = useState();
	const [partners, setPartners] = useState();
	const [questData, setQuestData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		spousePartner: "",
		address: "",
		city: "",
		state: "",
		zipcode: "",
		desiredLocation: "",
		timeFrame: "",
		franchiseOffering: "",
		cashAvailable: "",
		investmentRange: "",
		netWorth: "",
	});

	function HandleChange(e) {
		const value = e.target.value;
		setQuestData({
			...questData,
			[e.target.name]: value,
		});
	}

	const SelectedInterestHandler = (e) => {
		if (e.target.checked === true) {
			setInterests((preVal) => {
				return [...preVal, e.target.value];
			});
		} else {
			setInterests((preVal) => {
				return [...preVal.filter((val) => val !== e.target.value)];
			});
		}
	};

	const SelectedBusinessHandler = (e) => {
		if (e.target.checked === true) {
			setBusinessAppeals((preVal) => {
				return [...preVal, e.target.value];
			});
		} else {
			setBusinessAppeals((preVal) => {
				return [...preVal.filter((val) => val !== e.target.value)];
			});
		}
	};

	const SubmitHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!questData.firstName ||
			!questData?.lastName ||
			!questData?.email ||
			!questData?.phone ||
			!questData?.spousePartner ||
			!questData?.address ||
			!questData?.city ||
			!questData?.state ||
			!questData?.zipcode ||
			!questData?.desiredLocation ||
			!questData?.timeFrame ||
			!questData?.franchiseOffering ||
			!questData?.cashAvailable ||
			!questData?.investmentRange ||
			!questData?.netWorth
		) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (questData?.firstName.length < 3 || questData?.firstName.length > 30) {
			toast.error("First name limit is min 3 and max 30 characters ");
			setLoading(false);
			return;
		}
		if (questData?.lastName.length < 3 || questData?.lastName.length > 30) {
			toast.error("Last name limit is min 3 and max 20 characters ");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				questData?.email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (questData?.phone?.length < 10 || questData?.phone?.length > 17) {
			toast.error("The phone no limit is min 10 and max 17 digits");
			setLoading(false);
			return;
		}
		if (questData?.address?.length < 3 || questData?.address?.length > 50) {
			toast.error("Address limit is min 3 and max 50 characters");
			setLoading(false);
			return;
		}
		if (questData?.city?.length < 2 || questData?.city?.length > 40) {
			toast.error("City limit is min 2 and max 40 characters");
			setLoading(false);
			return;
		}
		if (questData?.state?.length < 2 || questData?.state?.length > 40) {
			toast.error("State limit is min 2 and max 40 characters");
			setLoading(false);
			return;
		}
		if (questData?.zipcode?.length < 4 || questData?.zipcode?.length > 20) {
			toast.error("zip code limit is min 4 and max 20 characters");
			setLoading(false);
			return;
		}
		if (
			questData?.desiredLocation?.length < 4 ||
			questData?.desiredLocation?.length > 20
		) {
			toast.error("zip code limit is min 4 and max 20 characters");
			setLoading(false);
			return;
		}

		if (
			questData?.franchiseOffering?.length < 5 ||
			questData?.franchiseOffering?.length > 30
		) {
			toast.error(
				"Researched any other franchise text limit is min 5 and max 30 characters",
			);
			setLoading(false);
			return;
		}
		if (businessAppeals?.length < 1) {
			toast.error("Please select Business Appeals");
			setLoading(false);
			return;
		}
		if (!funding) {
			toast.error("Please Check you need funding");
			setLoading(false);
			return;
		}
		if (!partners) {
			toast.error("Please Check you anticipate having partners");
			setLoading(false);
			return;
		}
		if (interests.length < 1) {
			toast.error("Please select interests");
			setLoading(false);
			return;
		}
		var data = new FormData();
		data.append("first_name", questData?.firstName);
		data.append("last_name", questData?.lastName);
		data.append("email", questData?.email);
		data.append("phone", questData?.phone);
		data.append("spouse_partner", questData?.spousePartner);
		data.append("address", questData?.address);
		data.append("city", questData?.city);
		data.append("state", questData?.state);
		data.append("zip", questData?.zipcode);
		data.append("desired_location", questData?.desiredLocation);
		data.append("begin_time", questData?.timeFrame);
		data.append("any_other_franchise", questData?.franchiseOffering);
		businessAppeals?.map((item) => {
			data.append("business_appeals[]", item);
		});
		data.append("need_funding", funding == "Yes" ? 1 : 0);
		data.append("aniticipate_partner", partners == "Yes" ? 1 : 0);
		data.append("liquid_cash_available", questData?.cashAvailable);
		data.append("investment_range", questData?.investmentRange);
		data.append("estimated_networth", questData?.netWorth);
		interests?.map((item) => {
			data.append("interest[]", item);
		});

		PostQuestionnaire(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				navigate("/");
			})
			.catch((err) => {
				if (err?.response?.data?.errors?.address) {
					toast.error(err?.response?.data?.errors?.address[0]);
				}
				if (err?.response?.data?.errors?.city) {
					toast.error(err?.response?.data?.errors?.city[0]);
				}
				if (err?.response?.data?.errors?.state) {
					toast.error(err?.response?.data?.errors?.state[0]);
				}
				if (err?.response?.data?.errors?.zip) {
					toast.error(err?.response?.data?.errors?.zip[0]);
				}
				setLoading(false);
			});
	};

	const lastQ = [
		{
			id: 1,
			name: "Mechanical/ Automotive",
		},
		{
			id: 2,
			name: "Pets & Animals",
		},
		{
			id: 3,
			name: "Design & Décor",
		},
		{
			id: 4,
			name: "Landscaping",
		},
		{
			id: 5,
			name: "Fitness & Sports",
		},
		{
			id: 6,
			name: "Being around Cars",
		},
		{
			id: 7,
			name: "Beauty/ Fashion",
		},
		{
			id: 8,
			name: "Coaching or Teaching",
		},
		{
			id: 9,
			name: "Entertainment, Hosting Parties",
		},
		{
			id: 10,
			name: "Remodeling/Home Improvement",
		},
		{
			id: 11,
			name: "Having Healthy Lifestyle",
		},
		{
			id: 12,
			name: "Travel & Leisure",
		},
		{
			id: 13,
			name: "Working Outdoors",
		},
		{
			id: 14,
			name: "Computer/ High Tech",
		},
		{
			id: 15,
			name: "Talking with just about Anyone",
		},
		{
			id: 16,
			name: "Real Estate Buying or Selling",
		},
		{
			id: 17,
			name: "Working with Hands/ Tools",
		},
		{
			id: 18,
			name: "Volunteering/Community Involvement ",
		},
		{
			id: 19,
			name: "Working with Children",
		},
		{
			id: 20,
			name: "Working with Numbers",
		},
		{
			id: 21,
			name: "Working with Seniors",
		},
		{
			id: 22,
			name: "Organizing Things",
		},
		{
			id: 23,
			name: "Organizing People",
		},
		{
			id: 24,
			name: "Networking with people",
		},
	];

	const business = [
		{
			id: 1,
			name: "Retail",
		},
		{
			id: 2,
			name: "Consumer Products",
		},
		{
			id: 3,
			name: "Owner Operator",
		},
		{
			id: 4,
			name: "Home Based",
		},
		{
			id: 5,
			name: "Fast Food",
		},
		{
			id: 6,
			name: "Semi-Passive Ownership",
		},
		{
			id: 7,
			name: "Office Based",
		},
		{
			id: 8,
			name: "Service Industry",
		},
		{
			id: 9,
			name: "Executive Model",
		},
		{
			id: 10,
			name: "Mobile",
		},
	];

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span> Confidential Questionnaire</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<Bucket />
			{/* contact section  */}
			<section className="contact-sec conatct-sec-for-padding">
				<div className="container">
					<div className="contact-head">
						<h3>Confidential Questionnaire</h3>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div>
								<form>
									<div className="row">
										<div className="col-md-6">
											<div class="form-group">
												<label>First Name</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="First Name"
													name="firstName"
													value={questData?.firstName}
													onChange={HandleChange}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div class="form-group">
												<label>Last Name</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Last Name"
													name="lastName"
													value={questData?.lastName}
													onChange={HandleChange}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div class="form-group">
												<label>Email</label>
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="email@gmail.com"
													name="email"
													value={questData?.email}
													onChange={HandleChange}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div class="form-group">
												<label>Phone</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Phone"
													name="phone"
													min="1"
													value={questData?.phone}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Spouse/Partner</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Spouse/Partner"
													name="spousePartner"
													value={questData?.spousePartner}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Address</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Address"
													name="address"
													value={questData?.address}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>City</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="City"
													name="city"
													value={questData?.city}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>State</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="State"
													name="state"
													value={questData?.state}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Zip Code</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Zip Code"
													name="zipcode"
													min="1"
													value={questData?.zipcode}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Desired Location: (specify zip code)</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="specify zip code"
													name="desiredLocation"
													min="1"
													value={questData?.desiredLocation}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Time frame to begin:</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="timeFrame"
													value={questData?.timeFrame}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<label>
													Have you researched any other franchise offering? If
													so, which ones
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="franchiseOffering"
													value={questData?.franchiseOffering}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">
												What type of business appeals to you:(Check all that
												apply)
											</h5>
											<div className="row">
												{business?.map((item, index) => {
													return (
														<div className="col-md-4" key={index}>
															<div className="qusetion-check-cont">
																<input
																	type="checkbox"
																	id={item?.id}
																	value={item?.name}
																	onChange={(e) => SelectedBusinessHandler(e)}
																/>
																<label className="ml-2">{item?.name}</label>
															</div>
														</div>
													);
												})}
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">Will you need funding?</h5>
											<div className="qusetion-check-cont">
												<input
													type="radio"
													id="Yes"
													name="fund"
													value="Yes"
													onChange={(e) => setFunding(e.target.value)}
												/>
												  <label for="Yes">Yes</label>
												<input
													type="radio"
													id="No"
													name="fund"
													value="No"
													className="ml-3"
													onChange={(e) => setFunding(e.target.value)}
												/>
												  <label for="css">No</label>
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">
												Do you anticipate having partners?
											</h5>
											<div className="qusetion-check-cont">
												<input
													type="radio"
													id="Yes"
													name="partner"
													value="Yes"
													onChange={(e) => setPartners(e.target.value)}
												/>
												  <label for="Yes">Yes</label>
												<input
													type="radio"
													id="No"
													name="partner"
													value="No"
													className="ml-3"
													onChange={(e) => setPartners(e.target.value)}
												/>
												  <label for="css">No</label>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Total Liquid Cash Available to Invest</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="cashAvailable"
													min="1"
													value={questData?.cashAvailable}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Total Investment Range:</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="investmentRange"
													min="1"
													value={questData?.investmentRange}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-6">
											<div class="form-group">
												<label>Estimated Net Worth:</label>
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													name="netWorth"
													min="1"
													value={questData?.netWorth}
													onChange={HandleChange}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">
												Please select any of the industries below that are of
												interest to you:
											</h5>
											<div className="row">
												{lastQ?.map((item, index) => {
													return (
														<div className="col-md-4" key={index}>
															<div className="qusetion-check-cont">
																<input
																	type="checkbox"
																	id={item?.id}
																	value={item?.name}
																	onChange={(e) => SelectedInterestHandler(e)}
																/>
																<label className="ml-2">{item?.name}</label>
															</div>
														</div>
													);
												})}
											</div>
										</div>

										<div className=" offset-2 col-8 offset-2 mt-5">
											<button
												className="btn confidential-btn"
												disabled={loading}
												onClick={(e) => SubmitHandler(e)}
											>
												{loading ? "Loading..." : "Submit"}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of contact section  */}

			<Footer />
		</>
	);
};

export default ConfidentialQuestionnaire;
