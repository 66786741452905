import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headlogo from "../Assets/img/head-logo.PNG";
import phone from "../Assets/img/phone.png";
import message from "../Assets/img/message.png";
import messageicon from "../Assets/img/messageicon.png";
import { useDispatch, useSelector } from "react-redux";
import { ramdomImage } from "../constant/ConstantFunction";
import "../Assets/css/Header.css";
import { InvestmentLevel, logout } from "../redux/actions/AuthActions";
import { GetAllInvestmentLevels } from "../network/Network";

const Header = () => {
	const dispatch = useDispatch();
	const Token = useSelector((state) => state.AuthReducer.token);
	const userData = useSelector((state) => state.AuthReducer.users);
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const [mobView, setMobView] = useState(false);
	let currentUrl = window.location.href.split("/");

	const [view, setView] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const NavBarHandler = () => {
		setMobView(!mobView);
	};

	const LogoutHandler = (e) => {
		e.preventDefault();
		setTimeout(async () => {
			let x = await dispatch(logout());
			window.location.href = "/login";
		}, 600);
	};

	// Investment Level
	useEffect(() => {
		GetAllInvestmentLevels()
			.then((res) => {
				dispatch(InvestmentLevel(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	return (
		<>
			{/* nav-top section  */}
			<section
				className="nav-top"
				// onClick={()=>setView(!view)}
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-10 col-md-10 col-10">
							<div className="two-links">
								<span>
									{" "}
									<img src={phone}></img>{" "}
									<Link to="/">{SiteSettingsRedux?.contact_phone}</Link>
								</span>
								<span>
									<img src={message}></img>{" "}
									<Link to="/">{SiteSettingsRedux?.contact_email}</Link>
								</span>
							</div>
						</div>

						<div className="col-lg-2 col-md-2 col-2">
							<div className="login">
								{Token ? (
									<>
										<Link to="/profile-account">
											<div className="profile-img-cont">
												<div className="header-profile mr-2">
													<img
														src={`${ramdomImage(
															`${userData?.first_name}{""}${userData?.last_name}`,
														)}`}
														className="image-fluid image-width"
														style={{ borderRadius: "50%", width: "100%" }}
														alt=""
													/>
												</div>
												{userData?.first_name} {userData?.last_name}
											</div>
										</Link>
									</>
								) : (
									<Link to="/login">Login</Link>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <end of Navtop section  */}

			{/* // <!-- Header Start Here --> */}
			<header id="headerTop">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-4 col-8">
							<div className="logoDv">
								<Link to="/">
									<figure>
										<img
											src={SiteSettingsRedux?.logo_path}
											className="img-fluid"
										/>
									</figure>
								</Link>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-4">
							<div className="canvas-icon text-black heade-smoll">
								<div onClick={() => NavBarHandler()}>
									<i className="fa fa-bars" aria-hidden="true"></i>
								</div>
							</div>
							<div className="navbar_menus navbar_menus">
								<ul className="menus">
									<li className="menu-items">
										<div className="dropdown">
											<button
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton1"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												About Us
											</button>
											<ul
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton1"
											>
												<li>
													<Link to="/about"> Our Background</Link>
												</li>
												<li>
													<Link to="/free-process">Our Process</Link>
												</li>
												<li>
													<Link to="/cash-program">Cash Back Program</Link>
												</li>
											</ul>
										</div>
									</li>
									<li className="menu-items">
										<div className="dropdown">
											<button
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton1"
												data-bs-toggle="dropdown"
												aria-expanded="false"
												// onClick={() => (window.location.href = "")}
											>
												Find Franchises
											</button>
											<ul
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton1"
											>
												<li>
													<Link to="/fastest-growing">Fastest Growing</Link>
												</li>
												<li>
													<Link to="/category ">By Category</Link>
												</li>
												<li>
													<Link to="/states">By State</Link>
												</li>
												<li>
													<Link to="/invesment">By Investment level</Link>
												</li>
											</ul>
										</div>
									</li>
									<li className="menu-items">
										<Link to="/free-consultation">Free Consultation</Link>
									</li>
									<li className="menu-items">
										<div className="dropdown">
											<button
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton1"
												data-bs-toggle="dropdown"
												aria-expanded="false"
												// onClick={() => (window.location.href = "")}
											>
												Resources
											</button>
											<ul
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton1"
											>
												<li>
													<Link to="/franchise101"> Franchising 101</Link>
												</li>
												<li>
													<Link to="/financing">Financing Options</Link>
												</li>
												<li>
													<Link to="/news-letter">Newsletter Sign Up</Link>
												</li>
												<li>
													<Link to="/confidential-questionnaire">
														Confidential Questionnaire
													</Link>
												</li>
												{/* <li>
                          <Link to="/disclosure-form">
                            Disclosure Form
                          </Link>
                        </li> */}
												<li>
													<Link to="/contact-us">Contact Us</Link>
												</li>
											</ul>
										</div>
									</li>

									<li className="nav-btn">
										<Link to="/quiz">Franchise Matching Quiz</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					{/* <!-- mobile header start --> */}
					<div
						className={`${mobView ? "mobile-header show" : "mobile-header"}`}
					>
						<div className="cancel" onClick={() => NavBarHandler()}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								fill="currentColor"
								className="bi bi-x"
								viewBox="0 0 16 16"
							>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
							</svg>
						</div>

						<ul className="mobile-nav">
							<li className="nav-item">
								<Link
									to="/"
									className={`${currentUrl?.[3] === "" ? "active" : null}`}
								>
									Home
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/about"
									className={`${currentUrl?.[3] === "about" ? "active" : null}`}
								>
									Our Background
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/free-process"
									className={`${
										currentUrl?.[3] === "free-process" ? "active" : null
									}`}
								>
									Our Process
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/cash-program"
									className={`${
										currentUrl?.[3] === "cash-program" ? "active" : null
									}`}
								>
									Cash Back Program
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/fastest-growing"
									className={`${
										currentUrl?.[3] === "Fasting Growing" ? "active" : null
									}`}
								>
									Fasting Growing
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/category"
									className={`${
										currentUrl?.[3] === "category" ? "active" : null
									}`}
								>
									By Category
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/states"
									className={`${
										currentUrl?.[3] === "states" ? "active" : null
									}`}
								>
									By State
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/invesment"
									className={`${
										currentUrl?.[3] === "invesment" ? "active" : null
									}`}
								>
									By Investment level
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/free-consultation"
									className={`${
										currentUrl?.[3] === "free-consultation" ? "active" : null
									}`}
								>
									Free Consultation
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/franchise101"
									className={`${
										currentUrl?.[3] === "franchise101" ? "active" : null
									}`}
								>
									Franchising 101
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/financing"
									className={`${
										currentUrl?.[3] === "financing" ? "active" : null
									}`}
								>
									Financing Options
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/news-letter"
									className={`${
										currentUrl?.[3] === "news-letter" ? "active" : null
									}`}
								>
									Newsletter Sign Up
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/confidential-questionnaire"
									className={`${
										currentUrl?.[3] === "confidential-questionnaire"
											? "active"
											: null
									}`}
								>
									Confidential Forms
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="/contact-us"
									className={`${
										currentUrl?.[3] === "contact-us" ? "active" : null
									}`}
								>
									Contact Us
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/quiz"
									className={`${currentUrl?.[3] === "quiz" ? "active" : null}`}
								>
									Franchise Matching Quiz
								</Link>
							</li>

							{/*            
                <li className="menu-items nav-item dropdown">
                  <Link
                    to="/booking-membership"
                    className={`${currentUrl?.[3] === "all-cars"
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                      }`}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    BOOKING & MEMBERSHIP
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                   
                    <Link
                      to={`/garden-tour`}
                      // state={{ carStyle: item }}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      GARDEN TOUR
                    </Link>
                    <Link
                      to={`/camping`}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      CAMPING
                    </Link>
                    <Link
                      to={`/framers-booth`}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      FARMERS BOOTH SPACE
                    </Link>
                    <Link
                      to={`/csa-membership`}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      CSA MEMBERSHIP
                    </Link>
                 
                  </div>
                </li> */}
						</ul>
						<ul className="secondary-items">
							<li className="flexBox-style navP-mob"></li>

							{Token ? (
								<>
									<li className="menu-items">
										<Link
											to="/profile-account"
											className={`${
												currentUrl?.[3] === "profile-account" ? "active" : null
											}`}
										>
											{/* <PermIdentityIcon className="set_icon" /> */}
											profile
										</Link>
									</li>
								</>
							) : null}

							{Token ? (
								<li className="menu-items">
									<a className=" btn-login" onClick={(e) => LogoutHandler(e)}>
										Sign Out
									</a>
								</li>
							) : (
								<li className="menu-items">
									<Link to="/login" className=" btn-login ">
										Sign In
									</Link>
								</li>
							)}

							<li className="comments"></li>
						</ul>
					</div>
				</div>
			</header>
			{/* <!-- Header End Here --> */}
		</>
	);
};

export default Header;
