import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/freeprocess.css";
import { GetOurProcess } from "../../network/Network";
import { useState } from "react";
import { useEffect } from "react";
import { notFound } from "../../constant";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const Freeprocess = () => {
	const [ourProcess, setOurProcess] = useState([]);
	const [spinLoad, setSpinLoad] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		GetOurProcess()
			.then((res) => {
				setOurProcess(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Our Process</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Our processs section  */}
			<section className="our-process-sec">
				<div className="container">
					<div className="our-process-head">
						<h5>{ourProcess?.heading}</h5>
						<h2>{ourProcess?.sub_heading}</h2>
					</div>
					<div className="row">
						{spinLoad ? (
							<>
								<div className="col-md-12">
									<Skeleton count={5} />
								</div>
								<div className="col-md-12">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{ourProcess?.data?.length > 0 ? (
									<>
										{ourProcess?.data?.map((item, index) => {
											return (
												<div className="col-md-2">
													<div className="our-process">
														<h5>{item?.title}</h5>
														<p>{item?.content}</p>
													</div>
												</div>
											);
										})}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="img-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</section>

			{/* End of our process section  */}

			<Footer />
		</>
	);
};

export default Freeprocess;
