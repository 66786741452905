import React from 'react'

const QuizForm = (props) => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    zip,
    setZip,
    QuizSubmitHandler,
    loading,
  } = props
  return (
   <>
    <div className="quiz_ques">
              <h2>Finish your franchisee profile</h2>
            </div>
            <div className="answer" style={{ maxWidth: "80%" }}>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Your First Name"
                    value={firstName}
                    onChange={(e)=>setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Last Name"
                    value={lastName}
                    onChange={(e)=>setLastName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control "
                    placeholder="(555) 555-555"
                    value={phone}
                    onChange={(e)=>setPhone(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control "
                    placeholder="Zip Code"
                    value={zip}
                    onChange={(e)=>setZip(e.target.value)}
                  />
                </div>
                <div className="button-group">
                  <button
                    onClick={(e) => QuizSubmitHandler(e)}
                    className="newsletter"
                    disabled={loading}
                  >
                   {loading ? "Loading.." : "Submit"}
                  </button>
                </div>
              </form>
             
            </div>
   </>
  )
}

export default QuizForm