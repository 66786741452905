import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import freeconsultation1 from "../../Assets/img/freeconsultation1.png";
import "../../Assets/css/freeconsultation.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ConsultationForm, PagesApi } from "../../network/Network";
import ConsultationModal from "../../Components/Modal/ConsultationModal";
import Skeleton from "react-loading-skeleton";
// import { Link } from "react-router-dom";

const FreeConsultation = () => {
	const navigate = useNavigate();
	// const handleGo = () => {
	//   navigate('/terms')
	// };

	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [stateUser, setStateUser] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [cashAvailable, setCashAvailable] = useState("");
	const [loading, setLoading] = useState(false);

	const [isOpenConsultationModal, setIsOpenConsultationModal] = useState(false);
	const [consultData, sConsultData] = useState([]);
	const [spinLoad, setSpinLoad] = useState(false);
	const [checkState, setCheckState] = useState(false);

	const SubmitConsultation = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!firstName ||
			!lastName ||
			!email ||
			!stateUser ||
			!zip ||
			!phone ||
			!cashAvailable
		) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}
		// if(!methodContact) {
		//   toast.error("Please Check Method Of Contact");
		//   setLoading(false);
		//   return;
		// }
		var data = new FormData();
		data.append("type", "consulations");
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("email", email);
		data.append("state", stateUser);
		data.append("zip", zip);
		data.append("phone", phone);
		data.append("cash_available_to_invest", cashAvailable);
		ConsultationForm(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setIsOpenConsultationModal(false);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setStateUser("");
				setZip("");
				setPhone("");
				setCashAvailable("");
				setCheckState(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/free-consultation";
		PagesApi(slug)
			.then((res) => {
				sConsultData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span> Free Consultation</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}
			{/* free consultion start here */}
			<section className="freeconsultation_page">
				<div className="head-sub-head text-center">
					<h5>Free Consultation</h5>
					<h2>All our services are 100% free to you!</h2>
				</div>
				<div>
					<div className="container">
						<div className="row">
							{spinLoad ? (
								<>
									<div className="col-md-8">
										<Skeleton count={8} />
									</div>
								</>
							) : (
								<div className="col-lg-8 col-md-12">
									<div className="free-constulation-img">
										<img src={freeconsultation1} className="img-fluid" alt="" />
									</div>

									<div className="free-consltation-sec">
										<p
											dangerouslySetInnerHTML={{ __html: consultData?.content }}
										/>
									</div>
								</div>
							)}

							<div className="col-lg-4 co-md-12 ">
								<div className="consultation_form">
									<h5>Free Franchise Consultation</h5>
									<h4>Sign-Up</h4>
									<p>
										Speak with one of our highly credentialed franchise
										consultants. All have MBAs from a top 15 university and have
										at least a decade of experience in the franchise industry.
										We will at no point attempt to sell you anything.
									</p>
									<div className="form_start">
										<form action="">
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="fname"
													placeholder="First Name"
													value={firstName}
													onChange={(e) => setFirstName(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="lname"
													placeholder="Last Name"
													value={lastName}
													onChange={(e) => setLastName(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="email"
													className="form-control"
													id="exampleInputEmail1"
													placeholder="Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="number"
													className="form-control"
													id="pnumber"
													placeholder="Phone Number"
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="text"
													className="form-control"
													id="exampleInputEmail1"
													placeholder="State"
													value={stateUser}
													onChange={(e) => setStateUser(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="number"
													className="form-control"
													id="inputzip"
													placeholder="Zip Code"
													value={zip}
													onChange={(e) => setZip(e.target.value)}
												/>
											</div>
											<div className="form-group">
												<input
													type="number"
													className="form-control"
													id="inputzip"
													placeholder="Available Cash To Invest"
													value={cashAvailable}
													onChange={(e) => setCashAvailable(e.target.value)}
												/>
											</div>

											<div className="sign-up-btn">
												<button
													className=""
													onClick={(e) => SubmitConsultation(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Submit"}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <section className="black_freeconsult">
          <div className="container">
            <div className="text-center">
              <div className="row">
                <div className="col"></div>
                <div className="col-md-8">
                  <h6>We Offer TBD% Cash back</h6>
                  <h1>Get in Touch For Free Consultation</h1>
                  <p>
                    uspendisse mollis ante imperdiet, dictum sapien eu, suscipit
                    turpis. Curabitur enim purus, dignissim non tempor quis,
                    tempus id urna. Quisque egestas quam nulla, quis rutrum
                    metus lacinia non. Fusce eu cursus ante. Etiam
                  </p>
                </div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <div className="sign-up-btn">
                    <button
                      type="button"
                      onClick={()=>setIsOpenConsultationModal(true)}
                    >
                      Free Consultation
                    </button>
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </section> */}
			</section>
			{/* free consultion end here */}
			<section></section>

			{/* <!-- Button trigger modal --> */}

			<Footer />

			<ConsultationModal
				setIsOpenConsultationModal={setIsOpenConsultationModal}
				isOpenConsultationModal={isOpenConsultationModal}
				SubmitConsultation={SubmitConsultation}
				firstName={firstName}
				setFirstName={setFirstName}
				lastName={lastName}
				setLastName={setLastName}
				email={email}
				setEmail={setEmail}
				stateUser={stateUser}
				setStateUser={setStateUser}
				zip={zip}
				setZip={setZip}
				phone={phone}
				setPhone={setPhone}
				cashAvailable={cashAvailable}
				setCashAvailable={setCashAvailable}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
};
export default FreeConsultation;
