import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../redux/actions/AuthActions";

const Enteremail = () => {
  const Navigate = useNavigate()
  const dispatch = useDispatch()
  const [ email, setEmail ] = useState("")
  const [ loading, setLoading ] = useState("")
  const [checked, setChecked] = useState("");

  const ForgetPasswordHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
      if(!email){
        toast.error("please enter Your email");
        setLoading(false);
      }
      if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        toast.error("please enter correct email");
        setLoading(false);
        return;
      } else {
        let data = { email: email };
        setTimeout(async () => {
          setLoading(false);
          let send = await dispatch(forgotPassword(data));
          // Navigate("/forgot-password-Otp", { state: email });
          // Navigate("/newpassword");
          // window.location.href = "/newpassword";
        }, 600);
      }
    };
  return (
    <>
      {/* Newsletter section  */}

      <section className="news-letter-sec">
        <div className="container">
          <div className="news-letter-head">
            <h3 className="enter-email">Enter Email</h3>
            <h4>Please Enter Your Email Below</h4>
            <h5>We will send you a recovery Link</h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="news-letter-form">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-news-letter">
                        <button 
                          disabled={loading}
                          onClick={(e)=>ForgetPasswordHandler(e)}
                          // onClick={() => navigate("/newpassword")}>
                        >
                          {loading? "Loading.." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Newsletter section  */}

      <div className="enter-email-para">
        <p>By Creating Account  you are agree to our <Link to="/terms">Terms & conditions</Link></p>

      </div>
    </>
  );
};

export default Enteremail;
