import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "../Profilepages/Sidebar";
import { Link } from "react-router-dom";
import { GetFranchiseDetailsApi } from "../../network/Network";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DOMPurify from "dompurify";

const Manageprofile2 = () => {
	const [view, setView] = useState(false);
	const Token = useSelector((state) => state.AuthReducer.token);
	const userData = useSelector((state) => state.AuthReducer.users);
	let currentUrl = window.location.href.split("/");
	const [franchiesDetails, setFranchiesDetails] = useState();
	const [spinLoad, setSpinLoad] = useState(false);

	const [youtubeUrl, setYoutubeUrl] = useState("");

	useEffect(() => {
		setSpinLoad(true);
		GetFranchiseDetailsApi(currentUrl[4], Token)
			.then((res) => {
				setFranchiesDetails(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [currentUrl[4]]);

	return (
		<>
			<Header />

			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As Franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="dashboard-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							{/* All frnachise information  */}
							{view ? null : (
								<div className="all-franchise-info">
									<div className="account-head">
										<h3>Franchise Information</h3>
									</div>
									{spinLoad ? (
										<>
											<div className="col-md-12">
												<Skeleton count={5} />
											</div>
											<div className="col-md-12">
												<Skeleton count={5} />
											</div>
										</>
									) : (
										<>
											<form>
												<div className="row frnachise-information">
													<div className="col-md-5">
														<div className="form-group">
															<label className="info-title">
																Franchise Name
															</label>
															<p className="sub-info">
																{franchiesDetails?.name}
															</p>
														</div>
													</div>

													<div className="col-md-7">
														<div className="form-group">
															<label className="info-title">
																Ideal Franchisee
															</label>
															<p className="sub-info">
																{franchiesDetails?.ideal_franchise_candidate}
															</p>
														</div>
													</div>

													<div className="col-md-3">
														<div className="logo-img">
															<label className="info-title">
																Franchise Logo
															</label>
															<img
																className="img-fluid"
																src={franchiesDetails?.logo_path}
															></img>
														</div>
													</div>

													<div className="col-md-3">
														<div className="logo-img">
															<label className="info-title">
																Franchise Cover
															</label>
															<img
																className="img-fluid"
																src={franchiesDetails?.cover_image_path}
															></img>
														</div>
													</div>

													<div className="col-md-12">
														<label className="info-title">
															Overview & Description
														</label>
														<p className="sub-info">
															{/* {franchiesDetails?.description} */}
															{DOMPurify.sanitize(
																franchiesDetails?.description
																	.replace(/\n/g, " ") // replace newline characters with spaces
																	.replace(/\s+/g, " ") // replace multiple spaces with a single space
																	.replace(
																		/<script[^>]*>([\S\s]*?)<\/script>/gim,
																		" ",
																	) // replace html entities with a single space
																	.replace(
																		/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim,
																		" ",
																	) // replace html entities with a single space
																	.replace(/&[^;]+;/g, "") // replace html entities with a single space
																	.trim(), // remove any leading or trailing spaces
																{ ALLOWED_TAGS: [] },
															)}
														</p>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">
																Industry Categories
															</label>

															{franchiesDetails
																? franchiesDetails.categories.map((item) => {
																		return (
																			<p className="sub-info">{item?.name}</p>
																		);
																  })
																: null}
														</div>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">
																Minimum Cash Required
															</label>
															<p className="sub-info">
																USD $ {franchiesDetails?.min_cash_required}
															</p>
														</div>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">
																Net Worth Required
															</label>
															<p className="sub-info">
																USD $ {franchiesDetails?.net_worth_required}
															</p>
														</div>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">
																Advertising Fee
															</label>
															<p className="sub-info">
																{/* USD $ */}
																{franchiesDetails?.advertising_fee}%
															</p>
														</div>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">
																Franchise Fee
															</label>
															<p className="sub-info">
																{/* USD $  */}
																{franchiesDetails?.franchise_fee}%
															</p>
														</div>
													</div>

													<div className="col-md-4">
														<div className="form-group">
															<label className="info-title">Royalty Fee</label>
															<p className="sub-info">
																{/* USD $ */}
																{franchiesDetails?.royalty_fee}%
															</p>
														</div>
													</div>

													{userData?.user_type == "franchisor" ? (
														<>
															<div className="col-md-4">
																<div className="form-group">
																	<label className="info-title">
																		Year Founded
																	</label>
																	<p className="sub-info">
																		{franchiesDetails?.year_founded}
																	</p>
																</div>
															</div>

															<div className="col-md-4">
																<div className="form-group">
																	<label className="info-title">
																		Franchising Since
																	</label>
																	<p className="sub-info">
																		{franchiesDetails?.franchising_since}
																	</p>
																</div>
															</div>

															<div className="col-md-4">
																<div className="form-group">
																	<label className="info-title">
																		Total Units
																	</label>
																	<p className="sub-info">
																		{franchiesDetails?.total_units}
																	</p>
																</div>
															</div>

															<div className="col-md-4">
																<div className="form-group">
																	<label className="info-title">
																		Headquarters
																	</label>
																	<p className="sub-info">
																		{franchiesDetails?.headquarters}
																	</p>
																</div>
															</div>
														</>
													) : null}

													<div className="col-md-4"></div>

													<div className="col-md-12">
														<div className="broker-head">
															<p className="info-title">
																Passive Ownership Types
															</p>
														</div>
													</div>
													{franchiesDetails?.passive_ownership_allowed?.map(
														(item, index) => {
															return (
																<div className="col-lg-4 " key={index}>
																	<div className="form-group-ul">
																		<ul>
																			<li className="sub-info" key={index}>
																				{item ? item : null}
																			</li>
																		</ul>
																	</div>
																</div>
															);
														},
													)}

													<div className="col-md-4"></div>
													<div className="col-md-4"></div>
													<div className="col-md-12">
														<div className="broker-head">
															<p className="info-title">
																Available In These States
															</p>
														</div>
													</div>

													{franchiesDetails?.all_states === 1 ? (
														<div className="col-md-4">
															<div className="form-group-ul">
																<ul>
																	<li className="sub-info">All States</li>
																</ul>
															</div>
														</div>
													) : (
														franchiesDetails?.states.map((item, index) => {
															return (
																<div className="col-md-4">
																	<div className="form-group-ul">
																		<ul>
																			<li className="sub-info" key={index}>
																				{item?.name}
																			</li>
																		</ul>
																	</div>
																</div>
															);
														})
													)}

													{/* <div className="col-md-12">
                            <div className="broker-head">
                              <p className="info-title">
                                Not Available In These States
                              </p>
                            </div>
                          </div>
                          {franchiesDetails?.not_available_states?.map(
                            (item, index) => {
                              return (
                                <div className="col-md-4">
                                  <div className="form-group-ul">
                                    <ul>
                                      <li className="sub-info" key={index}>
                                        {item?.name}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              );
                            }
                          )} */}

													<div className="col-md-12">
														<label className="info-title">Videos</label>
														<div className="videosss-img">
															{franchiesDetails?.videos?.map((item, index) => {
																return (
																	<video width="320" height="240" controls>
																		<source
																			src={item?.file_path}
																			type="video/mp4"
																		/>
																	</video>
																);
															})}
														</div>
													</div>

													<div className="col-md-12">
														<label className="info-title">Images</label>
														<div className="videosss-img">
															{franchiesDetails?.images?.map((item, index) => {
																return (
																	<div className="images-cont mr-2">
																		<img
																			className="img-fluid"
																			src={item?.file_path}
																		/>
																	</div>
																);
															})}
														</div>
													</div>

													<div className="col-lg-12">
														<div className="form-group">
															<label className="info-title">
																YouTube Videos
															</label>
															{franchiesDetails?.youtube_urls?.map(
																(item, index) => {
																	return (
																		<>
																			<a
																				target="_blank"
																				href={item?.youtube_url}
																			>
																				<p className="sub-info" key={index}>
																					{item?.youtube_url}
																				</p>
																			</a>
																		</>
																	);
																},
															)}
														</div>
													</div>

													<div className="col-md-6"></div>
												</div>
											</form>
										</>
									)}
								</div>
							)}

							<div></div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Manageprofile2;
