import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AutoLogout = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	// const navigate = useNavigate();

	const Token = useSelector((state) => state.AuthReducer.token);

	useEffect(() => {
		if (Token) {
			setIsLoggedIn(true);
		} else setIsLoggedIn(false);
	}, [Token]);

	// console.log(Token, "userDataRedux");

	// // Function to simulate user login
	// const handleLogin = () => {
	// 	setIsLoggedIn(true);
	// };

	// Function to simulate user logout
	const handleLogout = () => {
		setIsLoggedIn(false);
		logOutUser();
	};

	const logOutUser = () => {
		localStorage.clear();
		window.location.reload();
		// navigate("/login");
		// console.log("logout");
	};

	// Set a timer for automatic logout after 10 minutes of inactivity
	useEffect(() => {
		let logoutTimer;

		const resetTimer = () => {
			clearTimeout(logoutTimer);
			// logoutTimer = setTimeout(handleLogout, 10 * 60 * 1000); // 10 minutes in milliseconds
			// logoutTimer = setTimeout(handleLogout, 60000); // 1 minutes in milliseconds

			logoutTimer = setTimeout(handleLogout, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
		};

		if (isLoggedIn) {
			resetTimer();

			// Add event listeners to detect user activity and reset the timer
			const onActivity = () => {
				resetTimer();
			};

			document.addEventListener("mousemove", onActivity);
			document.addEventListener("keydown", onActivity);

			// Clean up event listeners when component unmounts or user logs out
			return () => {
				clearTimeout(logoutTimer);
				document.removeEventListener("mousemove", onActivity);
				document.removeEventListener("keydown", onActivity);
			};
		}
	}, [isLoggedIn]);

	return (
		<div>
			{/* {isLoggedIn ? (
				<div>
					<h2>Welcome, User!</h2>
					<button onClick={handleLogout}>Logout</button>
				</div>
			) : (
				<button onClick={handleLogin}>Login</button>
			)} */}
		</div>
	);
};

export default AutoLogout;
