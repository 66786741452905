import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import cashprogrampic from "../../Assets/img/cashprogrampic.png";
import dollar from "../../Assets/img/dollar.png";
import "../../Assets/css/cashprogram.css";
import arrow from "../../Assets/img/arrow.png";
import mandollar from "../../Assets/img/mandollar.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { ConsultationForm, PagesApi } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import Bucket from "../../Components/Bucket";
import { Link } from "react-router-dom";
import {
	Picture1,
	Picture2,
	Picture3,
	Picture4,
	Picture5,
	Picture6,
} from "../../constant";

import TermsModal from "../../Components/Modal/TermsModal";

const Cashprogram = () => {
	const dispatch = useDispatch();
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [spinLoad, setSpinLoad] = useState(false);
	const [state, setState] = useState(null);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [stateUser, setStateUser] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [cashAvailable, setCashAvailable] = useState("");
	const [loading, setLoading] = useState(false);
	const [checkState, setCheckState] = useState(false);
	const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/cash-back-programs";
		PagesApi(slug)
			.then((res) => {
				setState(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	const SubmitConsultation = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!firstName ||
			!lastName ||
			!email ||
			!stateUser ||
			!zip ||
			!phone ||
			!cashAvailable
		) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}
		// if(!methodContact) {
		//   toast.error("Please Check Method Of Contact");
		//   setLoading(false);
		//   return;
		// }
		var data = new FormData();
		data.append("type", "cash_back");
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("email", email);
		data.append("state", stateUser);
		data.append("zip", zip);
		data.append("phone", phone);
		data.append("cash_available_to_invest", cashAvailable);
		ConsultationForm(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setStateUser("");
				setZip("");
				setPhone("");
				setCashAvailable("");
				setCheckState(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Cash Back Program</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<section className="blue-sec">
				<h4>Add Franchises To Basket</h4>
				<h4>For Free Information</h4>
			</section>

			{CartDataRedux?.length > 0 ? <Bucket /> : null}

			{/* Cash program section  */}
			<section className="cash-program-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-md-12">
							{state?.heading ? (
								<div className="process-head">
									<h3>{state?.heading}</h3>
								</div>
							) : null}
							{state?.sub_heading ? <h5>{state?.sub_heading}</h5> : null}
							<div className="video-div">
								<video
									className="videoTag"
									controls
									// autoPlay
									loop
									muted
									src={state?.image_url}
								></video>
								{/* <img
                  src={`${state?.image_url}`}
                  className="cashprogram_img_onlyy mb-4"
                /> */}
							</div>
							<div dangerouslySetInnerHTML={{ __html: state?.content }}></div>
							{/* <div className="cash-program-dv">
                <div className="cash-pro-img">
                  <img className="img-fluid" src={cashprogrampic}></img>
                </div>
                <div className="play-btn">
                  <i class="fa fa-play" aria-hidden="true"></i>
                </div>
              </div>
              <div className="process-head">
                <h3>Historical Franchise Purchase Model Example</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p className="last-img-text m-0">Franchise Buyer</p>
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture1}></img>
                    </div>
                    <div className="process-img">
                      <p className="arrow-text">$$$</p>
                      <img className="img-fluid" src={Picture2}></img>
                      <p className="arrow-text">Franchise Fee</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <p className="last-img-text m-0">Franchise</p>
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture3}></img>
                    </div>
                    <div className="process-img ml-2">
                      <p className="arrow-text">$$$</p>
                      <img className="img-fluid" src={Picture2}></img>
                      <p className="arrow-text">Referral Fee</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="process-box-2">
                    <p className="last-img-text">Traditional Broker</p>
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture4}></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="process-head franchise-velocity">
                <h3>Franchise Velocity’s Revolutionary Purchase Experience</h3>
              </div> */}
							{/* <div className="row">
                <div className="col-md-4">
                  <p className="last-img-text m-0">Franchise Buyer</p>
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture1}></img>
                    </div>
                    <div className="process-img">
                      <p className="arrow-text">$$$</p>
                      <img className="img-fluid" src={Picture2}></img>
                      <p className="arrow-text">Franchise Fee</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <p className="last-img-text m-0">Franchise</p>
                  <div className="process-box">
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture3}></img>
                    </div>
                    <div className="process-img ml-2">
                      <p className="arrow-text">$$$</p>
                      <img className="img-fluid" src={Picture2}></img>
                      <p className="arrow-text">Referral Fee</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="process-box-2">
                    <p className="last-img-text">Franchise Velocity</p>
                    <div className="dollar-img">
                      <img className="img-fluid" src={Picture5}></img>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <div className="bar-img-cont">
                    <img className="img-fluid" src={Picture6}></img>
                  </div>
                  <p className="last-text">$ Cash Back (Up to $10k)</p>
                </div>
              </div> */}
						</div>
						<div className="col-md-4 col-md-12">
							<div className="all-forms-sec">
								<h4>Cash Back Program</h4>
								<h3>Sign Up Today!</h3>
								<p>
									Program members will receive up to $10k cash back after
									closing on any franchise that Franchise Velocity introduced
									you to. Terms and conditions apply.
								</p>
								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="First Name"
													value={firstName}
													onChange={(e) => setFirstName(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Last Name"
													value={lastName}
													onChange={(e) => setLastName(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Phone Number"
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="State"
													value={stateUser}
													onChange={(e) => setStateUser(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Zip Code"
													value={zip}
													onChange={(e) => setZip(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Available Cash to Invest"
													value={cashAvailable}
													onChange={(e) => setCashAvailable(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<form>
												<input
													type="checkbox"
													id="vehicle1"
													name="vehicle1"
													value={checkState}
													onChange={(e) => setCheckState(e.target.value)}
												/>
												{/* <Link to="/terms"> */}
												<label for="vehicle1">
													{" "}
													Agree to{" "}
													<span onClick={() => setIsOpenTermsModal(true)}>
														Terms & Conditions
													</span>
												</label>
												{/* </Link> */}
												<br />
											</form>
										</div>
										<div className="col-md-12">
											<div className="form-submit-btn">
												<button
													onClick={(e) => SubmitConsultation(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Submit"}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Cash program section  */}
			<Footer />
			<TermsModal
				setIsOpenTermsModal={setIsOpenTermsModal}
				isOpenTermsModal={isOpenTermsModal}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
};

export default Cashprogram;
