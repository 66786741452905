import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SetResetPassword } from "../../redux/actions/AuthActions";
import queryString from "query-string";

const Newpassword = () => {
	const location = useLocation();
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const value = queryString.parse(location.search);
	const token = value.token;

	const NewPasswordHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!password || !confirmPassword || !email) {
			toast.error("Please Fill all fields");
			setLoading(false);
			return;
		}
		if (password !== confirmPassword) {
			toast.error("Please Enter Same password and Confirm Password");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		let data = {
			email: email,
			token: token,
			password: password,
		};
		setTimeout(async () => {
			let send = await dispatch(SetResetPassword(data, Navigate));
			setLoading(false);
			//   Navigate("/Signin");
		}, 600);
	};

	return (
		<>
			{/* Newsletter section  */}

			<section className="news-letter-sec">
				<div className="container">
					<div className="news-letter-head">
						<h3 className="enter-email">New Password</h3>
						{/* <h4>Please Enter Your Email Below</h4> */}
						<h5>Create a new Password</h5>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="news-letter-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>

												<input
													type="password"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="New Password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>

												<input
													type="password"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Re-Enter New Password"
													value={confirmPassword}
													onChange={(e) => setConfirmPassword(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div className="form-news-letter">
												<button
													onClick={(e) => NewPasswordHandler(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Submit"}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Newsletter section  */}

			<div className="enter-email-para">
				<p>
					By Creating Account you are agree to our{" "}
					<Link to="/terms">Terms & conditions</Link>
				</p>
			</div>
		</>
	);
};

export default Newpassword;
