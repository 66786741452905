import React from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { useEffect } from "react";

const FranchiseCard = (props) => {
	const { item, index, setBucket, bucket, AddToCartHandler } = props;

	// const getDescription = () => {
	//   const sanitizedDescription = DOMPurify.sanitize(item?.description, {
	//     ALLOWED_TAGS: [],
	//   });
	//   const cleanedDescription = sanitizedDescription.replace(/&[^;]+;/g, "");
	//   const truncatedDescription = cleanedDescription.slice(0, 50);
	// };

	return (
		<div className="col-lg-3 col-md-6 mb-3" key={index}>
			<div className="featured-box  card h-100">
				<div className="f-img">
					<Link to={`/franchise/${item?.id}`}>
						<img className="img-fluid" src={item?.cover_image_path}></img>
					</Link>
				</div>
				{/* {item?.cashback ? (
          <div>
              <p className='cashback-tag'>Cashback: {item?.cashback}</p>
          </div>
        ) : null} */}

				<div className="all-text-things">
					<div className="featured-text">
						<Link
							to={`/franchise/${item?.id}`}
							style={{ textDecoration: "none" }}
						>
							<h5>{item?.name}</h5>
						</Link>
						{/* <p
              dangerouslySetInnerHTML={{
                __html: item?.description.slice(0,50),
              }}
            /> */}
						<p>
							{
								DOMPurify.sanitize(
									item?.description
										.replace(/\n/g, " ") // replace newline characters with spaces
										.replace(/\s+/g, " ") // replace multiple spaces with a single space
										.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, " ") // replace html entities with a single space
										.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, " ") // replace html entities with a single space
										.replace(/&[^;]+;/g, "") // replace html entities with a single space
										.trim(), // remove any leading or trailing spaces
									{ ALLOWED_TAGS: [] },
								)
								// .replace(/&[^;]+;/g, "") // remove HTML entities
								// .slice(0, 290)
							}
						</p>

						<Link to={`/franchise/${item?.id}`}>Learn more</Link>
					</div>

					<div className="featured-bold">
						<p>
							Min. Cash Required <span>{item?.min_cash_required}</span>{" "}
						</p>
					</div>
				</div>
				<div
					className="featured-input"
					onClick={(e) => AddToCartHandler(e, item)}
				>
					<label>Request Free Info</label>
				</div>

				<div className="inner-img firstImages-feture">
					<img className="img-fluid" src={item?.logo_path}></img>
				</div>
			</div>
		</div>
	);
};

export default FranchiseCard;
