import React from "react";
import DragDrop from "../../Components/Drag";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import franch from "../../Assets/img/franch.png";
import Path1 from "../../Assets/img/Path1.png";
import close from "../../Assets/img/close.png";
import Dragimg from "../../Components/Dragimg";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
	DeleteFranchiseImage,
	DeleteFranchiseVideo,
	GetFranchiseDetailsApi,
	UpdateFranchise,
} from "../../network/Network";
import { useEffect } from "react";
import Sidebar from "../Profilepages/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import { AiFillCamera } from "react-icons/ai";
import swal from "sweetalert";

const Editdetail2 = () => {
	const navigate = useNavigate();
	const Token = useSelector((state) => state.AuthReducer.token);
	const userData = useSelector((state) => state.AuthReducer.users);
	let currentUrl = window.location.href.split("/");
	const AllStatesDataRedux = useSelector(
		(state) => state.AuthReducer.allStates,
	);

	const [franchiesDetails, setFranchiesDetails] = useState();
	const CategoriesReduxData = useSelector((state) =>
		state.AuthReducer.categories?.slice(1),
	);
	const [loading, setLoading] = useState(false);

	// const [industryCategory, setIndustryCategory] = useState("");
	const [industryCategories, setIndustryCategories] = useState([]);

	const [selectedAvailableStates, setSelectedAvailableStates] = useState([]);
	const [selectedNotAvailableStates, setSelectedNotAvailableStates] = useState(
		[],
	);
	const [franchiseName, setFranchiseName] = useState("");
	const [franchiseCandidate, setFranchiseCandidate] = useState("");
	const [logo, setLogo] = useState();
	const [coverImage, SetCoverImage] = useState();
	const [fileupload, setFileupload] = useState("");
	const [logoFileupload, setLogoFileupload] = useState("");
	const [coverFileupload, setCoverFileupload] = useState("");
	const [description, setDescription] = useState("");
	const [minCashReq, setMinCashReq] = useState("");
	const [netWorthReq, setNetWorthReq] = useState("");
	const [minInvestment, setMinInvestment] = useState();
	const [maxInvestment, setMaxInvestment] = useState();
	const [yearFound, setYearFound] = useState();
	const [headquarters, setHeadquarters] = useState();
	const [franchisingSince, setFranchisingSince] = useState();
	const [total_units, setTotal_units] = useState();
	const [financialAssistanceAvailable, setFinancialAssistanceAvailable] =
		useState();
	const [passiveOwnershipAllowed, setPassiveOwnershipAllowed] = useState([]);
	// const [passiveOwnershipAllowedApi, setPassiveOwnershipAllowedApi] = useState();
	const [multiImageFiles, setMultiImageFiles] = useState([]);
	const [multiVideosFiles, setMultiVideosFiles] = useState([]);
	const [uploadImages, setUploadImages] = useState([]);
	const [uploadVideo, setUploadVideo] = useState([]);

	const [oldImages, setOldImages] = useState([]);
	const [oldVideos, setOldVideo] = useState([]);

	const [royaltyFee, setRoyaltyFee] = useState();
	const [advertisingFee, setAdvertisingFee] = useState();
	const [franchiseFee, setFranchiseFee] = useState();

	const [youtubeUrls, setYoutubeUrls] = useState([]);

	const [inputFields, setInputFields] = useState(1);

	// useEffect(() => {
	//   GetFranchiseDetailsApi(currentUrl[4], Token)
	//     .then((res) => {
	//       let response = res?.data?.response?.data;
	//       console.log(response?.min_cash_required,"check values");
	//       setMinCashReq(response?.min_cash_required.slice(1));
	//     })
	//     .catch((err) => {
	//
	//     });
	// }, [currentUrl[4], loading]);

	useEffect(() => {
		GetFranchiseDetailsApi(currentUrl[4], Token)
			.then((res) => {
				let response = res?.data?.response?.data;
				setFranchiesDetails(res?.data?.response?.data);
				setFranchiseName(response?.name);
				setFranchiseCandidate(response?.ideal_franchise_candidate);
				setDescription(response?.description);
				// response?.categories.map((item) => {
				//   setIndustryCategories([item?.id]);
				// });
				const categoryIds = response?.categories.map((item) => item?.id);
				setIndustryCategories(categoryIds);

				setMinCashReq(response?.min_cash_required.slice(1));
				// setMinCashReq(response?.min_cash_required);
				setNetWorthReq(response?.net_worth_required.slice(1));
				setMinInvestment(response?.min_cash_required.slice(1));
				setMaxInvestment(response?.max_investment.slice(1));
				setYearFound(response?.year_founded);
				setHeadquarters(response?.headquarters);
				setFranchisingSince(response?.franchising_since);
				setTotal_units(response?.total_units);
				setFinancialAssistanceAvailable(
					response?.financial_assistance_available,
				);
				// setPassiveOwnershipAllowedApi(response?.passive_ownership_allowed)
				setPassiveOwnershipAllowed(response?.passive_ownership_allowed);
				// console.log(response?.passive_ownership_allowed,"setPassiveOwnershipAllowed")
				let i, j;
				for (i = 0; i < response?.not_available_states; i++) {
					selectedNotAvailableStates.push(
						response?.not_available_states[i]?.id,
					);
				}
				for (j = 0; j < response?.states; j++) {
					selectedAvailableStates.push(response?.not_available_states[j]?.id);
				}
				setSelectedNotAvailableStates(response?.not_available_states);
				setSelectedAvailableStates(response?.states);
				setLogo(response?.logo_path);
				setOldImages(response?.images);
				setOldVideo(response?.videos);
				SetCoverImage(response?.cover_image_path);
				setHeadquarters(response?.headquarters);
				setFranchisingSince(response?.franchising_since);
				setYearFound(response?.year_founded);

				setRoyaltyFee(response?.royalty_fee);
				setAdvertisingFee(response?.advertising_fee);
				setFranchiseFee(response?.franchise_fee.slice(1));
				// setYoutubeUrls(response?.youtube_urls);
				const urls = response?.youtube_urls?.map((item) => item?.youtube_url);
				setYoutubeUrls(urls);
				setInputFields(response?.youtube_urls?.length);
			})
			.catch((err) => {});
	}, [currentUrl[4], loading]);

	// useEffect(() => {
	//   console.log(youtubeUrls, "youtubeUrls");
	// }, [])

	// for testing purpose
	// useEffect(() => {
	//   GetFranchiseDetailsApi(currentUrl[4], Token)
	//     .then((res) => {
	//       // setFranchiesDetails(res?.data?.response?.data);
	//       let response = res?.data?.response?.data;

	//       console.log(
	//         response?.passive_ownership_allowed,
	//         "setPassiveOwnershipAllowed"
	//       );
	//     })
	//     .catch((err) => {
	//
	//     });
	// }, []);

	const VideosUploadHandler = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiVideosFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadVideo((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderVideos = (source) => {
		return source.map((video) => {
			return (
				<>
					<video width="320" height="240" controls>
						<source src={video} type="video/mp4" />
					</video>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiVideos(e, video)}
					/>
					{/* <RiCloseCircleLine style={{ position: "absolute" }} /> */}
				</>
			);
		});
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadImages((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderPhotos = (source) => {
		return source.map((photo) => {
			return (
				<div className="image-cont">
					<img className="img-fluid" src={photo} />
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiImg(e, photo)}
					/>
				</div>
			);
		});
	};

	let passiveOwnershipData = [
		{
			id: 1,
			name: "Owner-Operator",
		},
		{
			id: 2,
			name: "Semi-Absentee",
		},
		{
			id: 3,
			name: "Passive",
		},
	];

	const EditFranchise = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!franchiseName ||
			!franchiseCandidate ||
			!description ||
			!industryCategories
		) {
			setLoading(false);
			toast.error("Please Enter All Field");
			return;
		}
		if (!logo) {
			toast.error("Please Upload Logo");
			setLoading(false);
			return;
		}
		if (description.length < 300) {
			toast.error("Description must be greater than 300 characters");
			setLoading(false);
			return;
		}
		if (!coverImage) {
			toast.error("Please Upload Cover Image");
			setLoading(false);
			return;
		}
		let data = new FormData();
		data.append("franchise_id", currentUrl[4]);
		// data.append("category_id", industryCategories);
		// data.append("category_ids", industryCategories);
		// industryCategories?.map((category) => {
		//   data.append("categories[]", category);
		// });
		industryCategories?.map((categoryId) => {
			data.append("category_ids[]", categoryId);
		});
		selectedAvailableStates?.map((state) => {
			data.append("states[]", state?.id);
		});
		// selectedNotAvailableStates?.map((notState) => {
		//   data.append("not_available_states[]", notState?.id);
		// });
		data.append("name", franchiseName);
		data.append("ideal_franchise_candidate", franchiseCandidate);
		data.append("logo", logo);
		data.append("converImage", coverImage);
		data.append("description", description);
		// data.append("min_cash_required", minCashReq);
		// data.append("net_worth_required", netWorthReq);
		// data.append("min_investment", minInvestment);
		// data.append("max_investment", maxInvestment);
		data.append("min_cash_required", `$${minCashReq}`);
		data.append("net_worth_required", `$${netWorthReq}`);
		data.append("min_investment", `$${minInvestment}`);
		data.append("max_investment", `$${maxInvestment}`);
		data.append("year_founded", yearFound);
		data.append("headquarters", headquarters);
		data.append("franchising_since", franchisingSince);
		data.append("total_units", total_units);
		// data.append("passive_ownership_allowed", passiveOwnershipAllowed);
		passiveOwnershipAllowed?.map((passive) => {
			data.append("passive_ownership_allowed[]", passive);
		});
		data.append("financial_assistance_available", financialAssistanceAvailable);

		uploadImages.map((singlepic) => {
			data.append("images[]", singlepic);
		});
		uploadVideo.map((singleVideo) => {
			data.append("videos[]", singleVideo);
		});
		data.append("royalty_fee", royaltyFee);
		data.append("advertising_fee", advertisingFee);
		data.append("franchise_fee", `$${franchiseFee}`);

		data.append(
			"min_cash_required_display",
			franchiesDetails?.min_cash_required_display,
		);
		data.append(
			"min_cash_required_label",
			franchiesDetails?.min_cash_required_label,
		);
		data.append(
			"net_worth_required_display",
			franchiesDetails?.net_worth_required_display,
		);
		data.append(
			"net_worth_required_label",
			franchiesDetails?.net_worth_required_label,
		);
		data.append(
			"max_investment_display",
			franchiesDetails?.max_investment_display,
		);
		data.append("max_investment_label", franchiesDetails?.max_investment_label);
		data.append(
			"min_investment_display",
			franchiesDetails?.min_investment_display,
		);
		data.append("min_investment_label", franchiesDetails?.min_investment_label);
		data.append(
			"financial_assistance_available_display",
			franchiesDetails?.financial_assistance_available_display,
		);
		data.append(
			"financial_assistance_available_label",
			franchiesDetails?.financial_assistance_available_label,
		);
		data.append(
			"passive_ownership_allowed_display",
			franchiesDetails?.passive_ownership_allowed_display,
		);
		data.append(
			"passive_ownership_allowed_label",
			franchiesDetails?.passive_ownership_allowed_label,
		);
		data.append(
			"franchise_fee_display",
			franchiesDetails?.franchise_fee_display,
		);
		data.append("franchise_fee_label", franchiesDetails?.franchise_fee_label);
		data.append(
			"advertising_fee_display",
			franchiesDetails?.advertising_fee_display,
		);
		data.append(
			"advertising_fee_label",
			franchiesDetails?.advertising_fee_label,
		);
		data.append("royalty_fee_display", franchiesDetails?.royalty_fee_display);
		data.append("royalty_fee_label", franchiesDetails?.royalty_fee_label);
		// data.append("youtube_url", youtubeUrl);

		youtubeUrls?.map((url) => {
			data.append("youtube_urls[]", url ?? url);
		});
		UpdateFranchise(data, Token)
			.then((res) => {
				toast.success(res?.data?.message);
				navigate("/manage-franchise");
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const SelectedStateHandler = (e, type) => {
		let value = AllStatesDataRedux?.filter(
			(val) => val?.name == e.target.value,
		);
		let newObj = { id: value[0]?.id, name: value[0]?.name };

		if (type == "yes") {
			if (selectedAvailableStates?.includes(e.target.value) === false) {
				setSelectedAvailableStates([...selectedAvailableStates, newObj]);
			}
		}
		if (type == "no") {
			if (selectedNotAvailableStates?.includes(e.target.value) === false) {
				setSelectedNotAvailableStates([...selectedNotAvailableStates, newObj]);
			}
		}
	};

	const RemoveSelectedHandler = (id, type) => {
		if (type == "yes") {
			// selectedAvailableStates.splice(index, 1);
			setSelectedAvailableStates((preVal) => {
				return [...preVal.filter((val) => val?.id !== id)];
			});
		}
		if (type == "no") {
			// selectedNotAvailableStates.splice(index, 1);

			setSelectedNotAvailableStates((preVal) => {
				return [...preVal.filter((val) => val?.id !== id)];
			});
		}
	};

	const RemoveFranchiseImageHandler = (e, item) => {
		e.preventDefault();
		setLoading(true);
		let data = {
			franchise_id: currentUrl[4],
			image_id: item?.id,
		};
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this image!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				DeleteFranchiseImage(data, Token)
					.then((res) => {
						toast.success(res?.data?.message);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
					});
			} else {
				swal("Your image is safe!");
				setLoading(false);
			}
		});
	};

	const RemoveFranchiseVideoHandler = (e, item) => {
		e.preventDefault();
		setLoading(true);
		let data = {
			franchise_id: currentUrl[4],
			video_id: item?.id,
		};
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this video!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				DeleteFranchiseVideo(data, Token)
					.then((res) => {
						toast.success(res?.data?.message);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
					});
			} else {
				swal("Your Video is safe!");
				setLoading(false);
			}
		});
	};

	const handleLogoUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setLogo(file);
			setFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		// console.log(reader.result);
	};

	const handleCoverUpload = (e) => {
		e.preventDefault();
		const coverImageReader = new FileReader();
		const coverImageFile = e.target.files[0];
		coverImageReader.onloadend = () => {
			SetCoverImage(coverImageFile);
			setCoverFileupload(coverImageReader.result);
		};
		coverImageReader.readAsDataURL(coverImageFile);
	};

	const PassiveOwnershipHandler = (e) => {
		e.preventDefault();
		if (e.target.checked === true) {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal, e.target.value];
			});
		} else {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal.filter((val) => val !== e.target.value)];
			});
		}
	};

	// console.log("this one", passiveOwnershipAllowed);

	const RemoveMultiImg = (e, item) => {
		e.preventDefault();
		setMultiImageFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	const RemoveMultiVideos = (e, item) => {
		e.preventDefault();
		// console.log(item);
		setMultiVideosFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	// maaz precentage check

	const setMinCashRequired = (e) => {
		// setMinCashReq(e.target.value);
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMinCashReq(showVal);
			} else {
				setMinCashReq("");
			}
		} catch (err) {
			setMinCashReq("");
		}
		// let value_comma1 = parseInt(e.target.value).toLocaleString("en");
		// let checkVal = minCashReq.split(",").join("");
		// // .split(',').join('')
		// // let value_comma = parseInt(e.target.value)
		// setShowMinCashReq(value_comma1);
		// // setMinCashReq(value_comma.toLocaleString("en"));
		// console.log(showMinCashReq, '888888888888888888888888888888888888888');
	};

	const setMinInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMinInvestment(showVal);
			} else {
				setMinInvestment("");
			}
		} catch (err) {
			setMinInvestment("");
		}
	};

	const setMaxInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMaxInvestment(showVal);
			} else {
				setMaxInvestment("");
			}
		} catch (err) {
			setMaxInvestment("");
		}
	};

	const changeFranchiseFees = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setFranchiseFee(showVal);
			} else {
				setFranchiseFee("");
			}
		} catch (err) {
			setFranchiseFee("");
		}
	};

	const changeNetWorth = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setNetWorthReq(showVal);
			} else {
				setNetWorthReq("");
			}
		} catch (err) {
			setNetWorthReq("");
		}
	};
	const handleTagClick = (event, value) => {
		event.preventDefault();
		if (industryCategories.includes(value)) {
			setIndustryCategories(
				industryCategories.filter((category) => category !== value),
			);
		} else {
			setIndustryCategories([...industryCategories, value]);
		}
		// console.log(value, "cat id ")
	};

	const addInputField = (e) => {
		e.preventDefault();
		// setYoutubeUrls([...youtubeUrls, ""]);
		setInputFields(inputFields + 1);
	};

	const removeInputField = (e, indexToRemove, indexOfUrl) => {
		e.preventDefault();
		// console.log(indexOfUrl);
		// const updatedUrls = youtubeUrls.filter((element) => element !== indexOfUrl);
		youtubeUrls.splice(indexOfUrl, 1);
		setYoutubeUrls(youtubeUrls);
		setInputFields(inputFields - 1);
	};

	const handleYoutubeUrlChange = (value, index) => {
		const updatedUrls = [...youtubeUrls];
		updatedUrls[index] = value;
		setYoutubeUrls(updatedUrls);
	};

	return (
		<>
			<Header />

			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As Franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Edit detail section  */}
			<section className="edit-detail-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="franchise-broker">
								<h2>Franchisor</h2>
							</div>

							<div className="all-franchise-infoness">
								<div className="account-head">
									<h3>Franchise Information</h3>
								</div>
								<form>
									<div className="row frnachise-information">
										<div className="col-md-5">
											<div class="form-group">
												<label className="info-title">Franchise Name</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={franchiseName}
													onChange={(e) => setFranchiseName(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-7">
											<div class="form-group">
												<label className="info-title">
													Ideal Franchisee (one sentence max)
												</label>
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={franchiseCandidate}
													onChange={(e) =>
														setFranchiseCandidate(e.target.value)
													}
												/>
											</div>
										</div>

										<div className="col-lg-3 col-md-6">
											<div class="logo-img">
												<label className="info-title">Franchise Logo</label>
												<div className="image-container">
													<img
														src={`${fileupload ? fileupload : `${logo}`}`}
														className="image-fluid image-width"
														style={{ height: "100%" }}
														alt=""
													/>
												</div>

												<AiFillCamera className="camera-icon" />

												<div className="edit-container">
													<input
														type="file"
														accept="image/*"
														onChange={handleLogoUpload}
														multiple={false}
													/>
												</div>

												{/* <img className="img-fluid" src={logo}></img> */}
												{/* <div className="img-close">
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div> */}
											</div>
										</div>

										<div className="col-lg-3 col-md-6">
											<div class="logo-img">
												<label className="info-title">Franchise Cover</label>
												<div className="image-container">
													<img
														src={`${
															coverFileupload
																? coverFileupload
																: `${coverImage}`
														}`}
														className="image-fluid image-width"
														style={{ height: "100%" }}
														alt=""
													/>
												</div>

												<AiFillCamera className="camera-icon" />

												<div className="edit-container">
													<input
														type="file"
														accept="image/*"
														onChange={handleCoverUpload}
														multiple={false}
													/>
												</div>

												{/* <img className="img-fluid" src={logo}></img> */}
												{/* <div className="img-close">
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div> */}
											</div>
										</div>
										<div className="col-md-12">
											<label className="info-title">
												Overview & Description
											</label>
											<textarea
												class="form-control"
												id="exampleFormControlTextarea1"
												rows="3"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
											></textarea>
										</div>

										<div className="col-md-4z">
											<div className="form-group industry-category3">
												<label className="info-title">
													Select All Industries/Categories that are relevant to
													your franchise:
												</label>
												{/* <h3>Select Industry Categories:</h3> */}
												<div>
													{/* {CategoriesReduxData?.map((item, index) => {
                            const selected = industryCategories.includes(
                              item?.id
                            );
                            return (
                              <button
                                key={index}
                                onClick={(event) =>
                                  handleTagClick(event, item?.id)
                                }
                                style={{
                                  backgroundColor: selected ? "#0077c2" : "",
                                  color: selected ? "#fff" : "",
                                  padding: "5px 10px",
                                  borderRadius: "10px",
                                  margin: "5px",
                                  cursor: "pointer",
                                  border: "none",
                                }}
                              >
                                {item?.name}
                              </button>
                            );
                          })} */}
													{CategoriesReduxData?.map((item, index) => {
														const selected = industryCategories.includes(
															item?.id,
														);
														return (
															<button
																key={index}
																onClick={(event) =>
																	handleTagClick(event, item?.id)
																}
																style={{
																	backgroundColor: selected ? "#0077c2" : "",
																	color: selected ? "#fff" : "",
																	padding: "5px 10px",
																	borderRadius: "10px",
																	margin: "5px",
																	cursor: "pointer",
																	border: "none",
																}}
															>
																{item?.name}
															</button>
														);
													})}
												</div>
											</div>
										</div>

										<div className="col-md-4 pos-rel">
											<span className="doollar_Sign_adjust_edit">{"$"}</span>
											<div class="form-group">
												<label className="info-title">
													Minimum Cash Required
												</label>
												<input
													type="text"
													class="form-control field-pd-left"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={minCashReq}
													onChange={setMinCashRequired}
												/>
											</div>
										</div>

										<div className="col-md-4 pos-rel">
											<span className="doollar_Sign_adjust_edit">{"$"}</span>
											<div class="form-group">
												<label className="info-title">Net Worth Required</label>
												<input
													type="text"
													class="form-control field-pd-left"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={netWorthReq}
													onChange={changeNetWorth}
												/>
											</div>
										</div>

										<div className="col-md-4 pos-rel">
											<span className="doollar_Sign_adjust_edit">{"$"}</span>
											<div class="form-group">
												<label className="info-title">Minimum Investment</label>
												<input
													type="text"
													class="form-control field-pd-left"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={minInvestment}
													onChange={setMinInvstRequired}
												/>
											</div>
										</div>

										<div className="col-md-4 pos-rel">
											<span className="doollar_Sign_adjust_edit">{"$"}</span>
											<div class="form-group">
												<label className="info-title">Maximum Investment</label>
												<input
													type="text"
													class="form-control field-pd-left"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={maxInvestment}
													onChange={setMaxInvstRequired}
												/>
											</div>
										</div>

										<div className="col-md-4  pos-rel">
											<span className="doollar_Sign_adjust_edit">{"%"}</span>
											<div class="form-group">
												<label className="info-title">Royalty Fee</label>
												<input
													type="text"
													class="form-control pl--percent--edit--prof"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={royaltyFee}
													onChange={(e) => setRoyaltyFee(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-4 pos-rel">
											<span className="doollar_Sign_adjust_edit">{"%"}</span>
											<div class="form-group">
												<label className="info-title">Advertising Fee</label>
												<input
													type="text"
													class="form-control pl--percent--edit--prof"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={advertisingFee}
													onChange={(e) => setAdvertisingFee(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-4">
											<span className="doollar_Sign_adjust_edit">{"$"}</span>
											<div class="form-group">
												<label className="info-title">Franchise Fee</label>
												<input
													type="text"
													class="form-control field-pd-left"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													value={franchiseFee}
													onChange={changeFranchiseFees}
												/>
											</div>
										</div>

										{userData?.user_type == "franchisor" ? (
											<>
												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Year Founded</label>
														<input
															type="number"
															class="form-control"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
															value={yearFound}
															onChange={(e) => setYearFound(e.target.value)}
														/>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">
															Franchising Since
														</label>
														<input
															type="number"
															class="form-control"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
															value={franchisingSince}
															onChange={(e) =>
																setFranchisingSince(e.target.value)
															}
														/>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Total Units</label>
														<input
															type="number"
															class="form-control"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
															value={total_units}
															onChange={(e) => setTotal_units(e.target.value)}
														/>
													</div>
												</div>

												<div className="col-md-4">
													<div class="form-group">
														<label className="info-title">Headquarters</label>
														<input
															type="text"
															class="form-control"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
															value={headquarters}
															onChange={(e) => setHeadquarters(e.target.value)}
														/>
													</div>
												</div>
											</>
										) : null}

										<div className="col-md-4"></div>

										<div className="col-md-12">
											<div className="heading-content">
												<h5>Ownership Types Allowed</h5>
											</div>
										</div>
										{passiveOwnershipData
											?.filter((val) => {
												if (
													passiveOwnershipAllowed?.find(
														(value) => value == val?.name,
													)
												) {
													return val;
												}
											})
											.map((item, index) => {
												return (
													<div className="col-md-4" key={index}>
														<input
															type="checkbox"
															id={`${item?.name}${item?.id}`}
															value={item?.name}
															checked={true}
															onChange={(e) => PassiveOwnershipHandler(e)}
														/>
														<label className="ml-2">{item?.name}</label>
													</div>
												);
											})}

										{passiveOwnershipData
											?.filter((val) => {
												if (
													!passiveOwnershipAllowed?.find(
														(value) => value == val?.name,
													)
												) {
													return val;
												}
											})
											.map((item, index) => {
												return (
													<div className="col-md-4" key={index}>
														<input
															type="checkbox"
															id={`${item?.name}${item?.id}`}
															value={item?.name}
															onChange={(e) => PassiveOwnershipHandler(e)}
														/>
														<label className="ml-2">{item?.name}</label>
													</div>
												);
											})}

										<div className="col-md-12">
											<div className="broker-head">
												<p className="info-title">Available In These States</p>
											</div>
										</div>

										<div className="col-md-12">
											<div className="all-select form-group industry-category3">
												<label className="info-title">Add New State</label>
												<select
													// className="form-control"
													id="add-state"
													name="state"
													onChange={(e) => {
														SelectedStateHandler(e, "yes");
													}}
												>
													<option selected="">Select State</option>
													{AllStatesDataRedux.filter((val) => {
														if (
															selectedNotAvailableStates.find(
																(value) => value?.id == val?.id,
															)
														) {
															// console.log(
															// 	"Not found in Available",
															// 	selectedNotAvailableStates.find(
															// 		(value) => value?.id == val?.id,
															// 	),
															// );
														} else {
															return val;
														}
													})?.map((item, index) => {
														return (
															<option value={item?.name} key={index}>
																{item?.name}
															</option>
														);
													})}
												</select>
											</div>
										</div>

										{selectedAvailableStates?.map((item, index) => {
											return (
												<div className="col-lg-4 col-6" key={index}>
													<div class="form-group-ul">
														<div className="state-name">
															<h5>{item?.name ? item?.name : item}</h5>
															<div
																className="close-tag"
																onClick={(e) =>
																	RemoveSelectedHandler(item?.id, "yes")
																}
															>
																<img className="img-fluid" src={close}></img>
															</div>
														</div>
													</div>
												</div>
											);
										})}

										{/* <div className="col-md-12">
                      <div className="broker-head">
                        <p className="info-title">
                          Not Available In These States
                        </p>
                      </div>
                    </div> */}

										{/* <div className="col-md-12">
                      <div className="all-select form-group industry-category3">
                        <label className="info-title">Add New State</label>
                        <select
                          // className="form-control"
                          id="add-state"
                          name="state"
                          onChange={(e) => {
                            SelectedStateHandler(e, "no");
                          }}
                        >
                          <option selected="">Select State</option>
                          <option selected="">Select State</option>
                          {AllStatesDataRedux.filter((val) => {
                            if (
                              selectedAvailableStates.find(
                                (value) => value?.id == val?.id
                              )
                            ) {
                              console.log(
                                "find in Available",
                                selectedAvailableStates.find(
                                  (value) => value?.id == val?.id
                                )
                              );
                            } else {
                              return val;
                            }
                          })?.map((item, index) => {
                            return (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div> */}

										{/* {selectedNotAvailableStates?.map((item, index) => {
                      return (
                        <div className="col-md-4" key={index}>
                          <div class="form-group-ul">
                            <div className="state-name">
                              <h5>{item?.name ? item?.name : item}</h5>
                              <div
                                className="close-tag"
                                onClick={(e) =>
                                  RemoveSelectedHandler(item?.id, "no")
                                }
                              >
                                <img className="img-fluid" src={close}></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
										<div className="col-lg-12 mt-4">
											<label className="info-title mt-3">YouTube Video</label>
											<div className="form-group">
												{Array.from({ length: inputFields }, (_, index) => (
													<>
														<div className="dynamicFieldsWraper">
															<input
																key={index}
																type="url"
																className="form-control"
																id={`exampleInputEmail${index}`}
																aria-describedby="emailHelp"
																placeholder="Youtube Video Url"
																value={youtubeUrls[index] || ""}
																onChange={(e) =>
																	handleYoutubeUrlChange(e.target.value, index)
																}
															/>
															<button
																style={{ margin: "0px 10px 10px 10px" }}
																className="removeFieldbtn"
																onClick={(e) => {
																	const urlIndex = youtubeUrls[index];
																	removeInputField(e, urlIndex, index);
																}}
															>
																Remove
															</button>
														</div>
													</>
												))}
											</div>
											<button className="addFieldbtn" onClick={addInputField}>
												Add Url
											</button>
										</div>

										<div className="col-md-12">
											<div class="input-group">
												<label className="multi-image-upload">
													<img className="img-fluid" src={Path1}></img>
													<span className="addfile">Add file</span>
													<span className="ordrop"> or drop files here</span>
												</label>
												<input
													type="file"
													accept="video/*"
													onChange={VideosUploadHandler}
													multiple={true}
													className="form-control file-opc"
													id="fileUpload"
												/>
											</div>
											{/* <DragDrop /> */}
										</div>

										<div className="col-md-12">
											<label className="info-title mt-3">Videos</label>
											<div className="videosss-img">
												{oldVideos?.map((item, index) => {
													return (
														<div>
															<video
																width="320"
																height="240"
																controls
																key={index}
															>
																<source
																	src={item?.file_path}
																	type="video/mp4"
																/>
															</video>
															<RiCloseCircleLine
																style={{ position: "absolute" }}
																onClick={(e) =>
																	RemoveFranchiseVideoHandler(e, item)
																}
															/>
														</div>
													);
												})}
												<ul className="multi-imglist">
													{renderVideos(multiVideosFiles)}
												</ul>
											</div>
										</div>
										<div className="col-md-12">
											<div class="input-group">
												<label className="multi-image-upload">
													<img className="img-fluid" src={Path1}></img>
													<span className="addfile">Add file</span>
													<span className="ordrop"> or drop files here</span>
												</label>
												<input
													type="file"
													accept="image/*"
													onChange={handleImageUpload}
													multiple={true}
													className="form-control file-opc"
													id="fileUpload"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<label className="info-title mt-3">Images</label>
											<div className="videosss-img">
												{oldImages?.map((item, index) => {
													return (
														<>
															<div className="image-cont">
																<img
																	className="img-fluid"
																	src={item?.file_path}
																	key={index}
																/>
																<RiCloseCircleLine
																	style={{ position: "absolute" }}
																	onClick={(e) =>
																		RemoveFranchiseImageHandler(e, item)
																	}
																/>
															</div>
														</>
													);
												})}
												{renderPhotos(multiImageFiles)}
											</div>
										</div>

										<div className="col-md-6"></div>

										<div className="col-md-6">
											<div className="update-detail">
												<button
													onClick={(e) => EditFranchise(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Update Details"}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Edit detail section  */}

			<Footer />
		</>
	);
};

export default Editdetail2;
