import { Email } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { EditProfileData } from "../../redux/actions/AuthActions";
import Sidebar from "./Sidebar";
import { UpdateProfile } from "../../network/Network";
import { Navigate, useNavigate } from "react-router-dom";

const Editprofile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  
  const [firstName, setFirstName] = useState(userData?.first_name);
  const [lastName, setLastName] = useState(userData?.last_name);
  // const [address, setAddress] = useState(userData?.address);
  // const [country, setCountry] = useState(userData?.country);
  const [userState, setUserState] = useState(userData?.state);
  const [phone, setPhone] = useState(userData?.phone);
  const [zipCode, setZipCode] = useState(userData?.zip);
  const [email, setEmail] = useState(userData?.email);

  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const EditProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email||
      !phone
    ) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    let data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("phone", phone);
    // data.append("state", userState);
    // data.append("zip", zipCode);
    // data.append("image", newFile);
    UpdateProfile(data, Token)
      .then((res) => {
        setLoading(false);
        setUploadLoading(false);
        toast.success(res?.data?.message);
        dispatch(EditProfileData(res?.data?.response?.data));
        navigate('/profile-account')
      })
      .catch((err) => {
        setLoading(false);
        setUploadLoading(false);
      });
  };

  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Join As Franchisor</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      {/* Edit profile section  */}
      <section className="edit-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
            </div>
            <div className="col-md-9">
              <div className="all-change-password-changes some-edit-label-changes">
                <div className="account-head">
                  <h3>Account</h3>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        value={email}
                        disabled
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Contact Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    {/* <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchising Headquarter State"
                        value={userState}
                        onChange={(e) => setUserState(e.target.value)}
                      />
                    </div> */}

                     {/* <div className="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchising Headquarter Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>  */}

                    <div className="col-md-6">
                      <div className="submit-btn-forms">
                        <button
                           onClick={(e) => EditProfile(e)}
                           disabled={loading}
                        >{loading ? "Loading.." : "Submit"}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of profile section  */}

      <Footer />
    </>
  );
};

export default Editprofile;
