import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import visa from "../../Assets/img/visa.png";
import mastercard from "../../Assets/img/mastercard.png";
import gplay from "../../Assets/img/gplay.png";
import paypal from "../../Assets/img/paypal.png";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import {
	DeletePaymentMethodsApi,
	GetAllCards,
	PostPaymentDefaultCard,
	PostSaveCard,
} from "../../network/Network";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { cardVisa1, notFound } from "../../constant";
import { AiTwotoneDelete, AiFillCheckCircle } from "react-icons/ai";
import PaymentModal from "../../Components/Modal/PaymentModal";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";

const Cardinfo = () => {
	const Token = useSelector((state) => state.AuthReducer.token);
	const [checkSwitch, setCheckSwitch] = useState(false);
	const [spinLoad, setSpinLoad] = useState(false);
	const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
	const [cardToken, setCardToken] = useState("");
	const [paymentData, setPaymentData] = useState([]);
	const [btnLoading, setBtnLoading] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		GetAllCards(Token)
			.then((res) => {
				setPaymentData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [checkSwitch, isOpenPaymentModal]);

	const PaymentHandler = async (token, buyer) => {
		// e.preventDefault();
		setSpinLoad(true);
		setBtnLoading(true);
		let waitToken = await token?.token;
		if (token?.token) {
			let data = {
				card_nonce: token?.token,
			};
			PostSaveCard(data, Token)
				.then((res) => {
					setPaymentData(res?.data?.response?.data);
					setSpinLoad(false);
					setBtnLoading(false);
					toast.success(res?.data?.message);
					setIsOpenPaymentModal(false);
					setCheckSwitch(!checkSwitch);
				})
				.catch((err) => {
					setSpinLoad(false);
					setBtnLoading(false);
				});
		} else {
			toast.error("Something went Wrong");
		}
		// let waitToken = await visaToken;
		// if (waitToken) {
		//   let data = {
		//     stripe_token: visaToken,
		//   };
	};

	// == Remove card ==
	const DeletePaymentMethod = (id, index) => {
		setSpinLoad(true);
		let data = { card_nonce: id };
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this Card Details!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				DeletePaymentMethodsApi(data, Token)
					.then((res) => {
						// toast.success(res?.data?.message);
						swal(res?.data?.message, {
							icon: "success",
						});
						// paymentData?.splice(index, 1);
						// setPaymentData(res?.data?.response?.data);
						setSpinLoad(false);
						setCheckSwitch(!checkSwitch);
					})
					.catch((err) => {
						setSpinLoad(false);
					});
			} else {
				swal("Your Card is safe!");
				setSpinLoad(false);
			}
		});
	};

	const DefaultCardHandler = (e, id) => {
		e.preventDefault();
		setSpinLoad(true);
		// setPaymentLoading(true);
		let data = { card_nonce: id };
		PostPaymentDefaultCard(data, Token)
			.then((res) => {
				// setPaymentData(res?.data?.response?.data);
				toast.success(res?.data?.message);
				setSpinLoad(false);
				setCheckSwitch(!checkSwitch);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As Franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<section className="dashboard-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="account-head">
								<h3>Card Infomation</h3>
							</div>
							{/* <p onClick={() => setIsOpenPaymentModal(true)}>Add Card</p> */}

							<div className="card-info-all">
								{spinLoad ? (
									<>
										<div className="col-md-12">
											<Skeleton count={5} />
										</div>
										<div className="col-md-12">
											<Skeleton count={5} />
										</div>
										<div className="col-md-12">
											<Skeleton count={5} />
										</div>
									</>
								) : (
									<>
										{paymentData?.length > 0 ? (
											<>
												{paymentData?.map((item, index) => {
													return (
														<div className="row mt-5" key={index}>
															<div className="col-lg-12">
																<div className="row">
																	<div className="col-lg-1 col-md-1 col-2">
																		<AiFillCheckCircle
																			className={`${
																				item?.default_card == "1"
																					? "check-Icon"
																					: "check-Icon2"
																			}`}
																		/>
																	</div>
																	<div className="col-lg-2 col-md-3 col-5">
																		<p>{item?.card_brand}</p>
																	</div>
																	<div className="col-lg-4 col-md-4 col-5 p-md-0">
																		<p className="paymentCard m-0">
																			My Personal Card
																		</p>
																		<p className="paymentCard-num m-0">
																			**********{item?.card_last4}
																		</p>
																	</div>
																	<div className="col-lg-3 col-md-3 col-6">
																		<div className="ml-4 ml-md-0">
																			<button
																				type="button"
																				className="btn btn-primary payment-default-btn"
																				style={{ width: "unset" }}
																				onClick={(e) =>
																					DefaultCardHandler(
																						e,
																						item?.stripe_source_id,
																					)
																				}
																				disabled={item?.default_card != 0}
																			>
																				Set as Default
																			</button>
																		</div>
																	</div>
																	<div className="col-lg-2 col-md-1 col-6">
																		<div>
																			<AiTwotoneDelete
																				className="icon-delete"
																				onClick={() =>
																					DeletePaymentMethod(
																						item?.stripe_source_id,
																					)
																				}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												})}
											</>
										) : (
											<div className="notFound-container">
												<img
													src={notFound}
													alt="not found"
													className="img-fluid card-info-img"
												/>
												<p className="mt-4">No Record Found</p>
											</div>
										)}
									</>
								)}

								<div className="col-lg-6 col-md-8 mt-2 mb-2">
									<div className="visa-img">
										<img className="img-fluid" src={visa}></img>
										<img className="img-fluid" src={gplay}></img>
										<img className="img-fluid" src={mastercard}></img>
										<img className="img-fluid" src={paypal}></img>
									</div>
								</div>

								<div className="col-lg-6 col-md-6">
									<div className="card-btn" style={{ cursor: "pointer" }}>
										<a onClick={() => setIsOpenPaymentModal(true)}>
											Add New Card
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />

			<PaymentModal
				setIsOpenPaymentModal={setIsOpenPaymentModal}
				isOpenPaymentModal={isOpenPaymentModal}
				PaymentHandler={PaymentHandler}
				setCardToken={setCardToken}
				cardToken={cardToken}
				btnLoading={btnLoading}
			/>
		</>
	);
};

export default Cardinfo;
