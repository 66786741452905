import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import myfrnachise from "../../Assets/img/myfranchise.png";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { PostChangePassword } from "../../network/Network";
import Sidebar from "./Sidebar";

const Profileaccount = () => {
	const [view, setView] = useState(false);
	const userData = useSelector((state) => state.AuthReducer.users);
	const Token = useSelector((state) => state.AuthReducer.token);
	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const changePassword = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!currentPassword || !password || !confirmPassword) {
			setLoading(false);
			toast.error("Please Enter All Field");
			return;
		}
		if (
			password?.length < 8 ||
			currentPassword?.length < 8 ||
			confirmPassword?.length < 8
		) {
			toast.error("The password must be at least 8 characters.");
			setLoading(false);
			return;
		}
		if (password !== confirmPassword) {
			toast.error("Please enter same password and confirm password");
			setLoading(false);
			return;
		}
		let data = new FormData();
		data.append("current_password", currentPassword);
		data.append("new_password", password);
		data.append("confirm_password", confirmPassword);
		PostChangePassword(data, Token)
			.then((res) => {
				setLoading(false);
				toast.success(res?.data?.message);
				setCurrentPassword("");
				setPassword("");
				setConfirmPassword("");
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>
										Join As{" "}
										{userData?.user_type == "advertisor"
											? "Advertisor"
											: userData?.user_type == "broker"
											? "Consultation"
											: "Franchisor"}
									</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}
			<section className="dashboard-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="all-tab-things accounts">
								<div class="tab-content" id="pills-tabContent">
									<div
										class="tab-pane fade show active"
										id="pills-home"
										role="tabpanel"
										aria-labelledby="pills-home-tab"
									>
										{view ? (
											<>
												<div className="all-change-password-changes">
													<div className="account-head">
														<h3>Account</h3>
													</div>
													<form>
														<div className="row">
															<div className="col-md-6">
																<input
																	type="text"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="Old Password"
																	value={currentPassword}
																	onChange={(e) =>
																		setCurrentPassword(e.target.value)
																	}
																/>
															</div>

															<div className="col-md-6">
																<input
																	type="text"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="New Password"
																	value={password}
																	onChange={(e) => setPassword(e.target.value)}
																/>
															</div>

															<div className="col-md-6">
																<input
																	type="text"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="Confirm New Password"
																	value={confirmPassword}
																	onChange={(e) =>
																		setConfirmPassword(e.target.value)
																	}
																/>
															</div>

															<div className="col-md-6"></div>

															<div className="col-md-6">
																<div className="submit-btn-forms">
																	<button
																		onClick={(e) => changePassword(e)}
																		disabled={loading}
																	>
																		{loading ? "Loading.." : "Submit"}
																	</button>
																</div>
															</div>
														</div>
													</form>
												</div>
											</>
										) : (
											<div className="account-all">
												<div className="account-head">
													<h3>Account</h3>
												</div>

												<div className="all-forms-accounts">
													<form>
														<div className="row">
															<div className="col-md-6">
																<div class="form-group">
																	<label for="exampleInputEmail1">
																		First Name
																	</label>
																	<p className="form-control">
																		{userData?.first_name}
																	</p>
																	{/* <input
                                    type="email"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="James"
                                  /> */}
																</div>
															</div>

															<div className="col-md-6">
																<div class="form-group">
																	<label for="exampleInputEmail1">
																		Last Name
																	</label>
																	<p className="form-control">
																		{userData?.last_name}
																	</p>
																</div>
															</div>

															<div className="col-md-6">
																<div class="form-group">
																	<label for="exampleInputEmail1">
																		Contact Number
																	</label>
																	<p className="form-control">
																		{userData?.phone}
																	</p>
																</div>
															</div>

															<div className="col-md-6">
																<div class="form-group">
																	<label for="exampleInputEmail1">Email</label>
																	<p className="form-control">
																		{userData?.email}
																	</p>
																</div>
															</div>

															{userData?.user_type == "franchisor" ? (
																<>
																	{/* <div className="col-md-6">
                                    <div class="form-group">
                                      <label>Franchise Headquarter State</label>
                                      <p className="form-control">
                                        {userData?.state}
                                      </p>
                                    </div>
                                  </div> */}
																	{/* <div className="col-md-6">
                                    <div class="form-group">
                                      <label for="exampleInputEmail1">
                                        Franchise Headquarter State
                                      </label>
                                      <input
                                        type="email"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Arizona"
                                      />
                                    </div>
                                  </div> */}

																	{/* <div className="col-md-12">
                                    <div class="form-group">
                                      <label>
                                        Franchise Headquarter Zip Code
                                      </label>
                                      <p className="form-control">
                                        {userData?.zip}
                                      </p>
                                    </div>
                                  </div> */}
																</>
															) : null}

															<div className="col-md-2">
																<div class="form-group change-password">
																	<button onClick={() => setView(true)}>
																		Change Password
																	</button>
																</div>
															</div>
															<div className="col-md-6"></div>

															<div className="col-md-12">
																<div className="account-btn mt-4">
																	<Link to="/edit-profile">Edit Profile</Link>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
										)}
									</div>
									<div
										class="tab-pane fade"
										id="pills-profile"
										role="tabpanel"
										aria-labelledby="pills-profile-tab"
									>
										<div className="my-franchise-head">
											<h3>My Franchise</h3>
										</div>
										<div className="my-franchise">
											<div className="row">
												<div className="col-md-3">
													<div className="my-franchise-img">
														<img className="img-fluid" src={myfrnachise}></img>
													</div>
												</div>

												<div className="col-md-3">
													<div className="my-franchise-second-col">
														<h5>ID</h5>
														<h5>Franchise Name</h5>
														<h5>Overview</h5>
													</div>
												</div>

												<div className="col-md-6">
													<div className="my-franchise-third-col">
														<p>123xxx</p>
														<p>Sample@email.com</p>
														<p>
															uspendisse mollis ante imperdiet, dictum sapien
															eu, suscipit turpis. Curabitur enimpurus,
															dignissim non tempor quis, tempus id urna. Quisque
															egestas quam nulla,quisrutrum metus lacinia non.
															Fusce eu cursus ante.{" "}
														</p>
													</div>
												</div>

												<div className="col-md-12">
													<div className="two-btns">
														<Link to="/">view Details</Link>
														<Link to="/">Edit Details</Link>
													</div>
												</div>
											</div>
										</div>

										<div className="Account-edit">
											<div className="row">
												<div className="col-md-6">
													<div class="form-group">
														<label for="exampleInputEmail1">
															Email address
														</label>
														<input
															type="email"
															class="form-control"
															id="exampleInputEmail1"
															aria-describedby="emailHelp"
															placeholder="Enter email"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="tab-pane fade"
										id="pills-contact"
										role="tabpanel"
										aria-labelledby="pills-contact-tab"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Profileaccount;
