import {
  ADD_TO_CART,
  CART_CLEAR,
  DELETE_CART_ITEM,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  TOP_PRODUCTS,
} from "../Types";

const initialState = {
  cartData: [],
};
const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        cartData: [...state.cartData, action.payload],
      };
    }
    case CART_CLEAR:
      return {
        ...state.cartData,
        cartData: [],
      };
    case DELETE_CART_ITEM: {
      let deleted_index = state.cartData.findIndex(
        (cartData) => cartData?.productitem?.id === action.payload
      );
      state.cartData.splice(deleted_index, 1);
      return {
        ...state,
      };
    }



    default:
      return state;
  }
};
export default CartReducer;
