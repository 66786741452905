import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import ff1 from "../../Assets/img/ff1.png";
import ff2 from "../../Assets/img/ff2.png";
import ff3 from "../../Assets/img/ff3.png";
import ff4 from "../../Assets/img/ff4.png";
import feature1 from "../../Assets/img/feature1.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import { Link, useLocation } from "react-router-dom";
import Bucket from "../../Components/Bucket";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart } from "../../redux/actions/CartActions";
import FranchiseCard from "../../Components/Card/FranchiseCard";
import { toast } from "react-toastify";
import { GetAvailableFranchise } from "../../network/Network";
import { AllAvailableFranchise } from "../../redux/actions/AuthActions";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { notFound } from "../../constant";
import ReactPaginate from "react-paginate";

const Available = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [bucket, setBucket] = useState(false);
	const AvaialableFranchiseRedux = useSelector(
		(state) => state.AuthReducer.availableFranchise,
	);
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [viewLoader, setViewLoader] = useState(false);

	let paramData = location?.state?.data;
	const [selectedMinMax, setSelectedMinMax] = useState(paramData?.minMax);
	const [selectedCategory, setSelectedCategory] = useState(paramData?.category);
	const [selectedState, setSelectedState] = useState(paramData?.state);

	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();

	const [selectedFranchise, setSelectedFranchise] = useState(null);

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	const AddToCartHandler = (e, item) => {
		let checkItemAlreadyExist = CartDataRedux.filter(
			(val) => val?.id === item?.id,
		);
		if (checkItemAlreadyExist?.length > 0) {
			alert("Item Already Exist in Cart!!!");
		} else {
			let data = {
				id: item?.id,
				price: item?.price,
				productitem: item,
			};
			dispatch(AddToCart(data));
			toast.success("Product Successfully Add ");
		}
		// reset the background color of the previously selected button
		if (selectedFranchise && document.getElementById(selectedFranchise)) {
			document.getElementById(selectedFranchise).style.background = "#d4d5d5";
		}

		// set background color to #000
		e.target.style.background = "#03a84e";

		// set the id of the newly selected button
		setSelectedFranchise(e.target.id);
	};

	// Available Franchise
	useEffect(() => {
		setViewLoader(true);
		let data = {
			featured: " ",
			category_id: selectedCategory ? selectedCategory : " ",
			state_id: selectedState ? selectedState : " ",
			investment_level: selectedMinMax ? selectedMinMax : " ",
			fast_growing: paramData ? 0 : 1,
		};
		GetAvailableFranchise(data, currentPage)
			.then((res) => {
				dispatch(AllAvailableFranchise(res?.data?.response?.data?.data));
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setViewLoader(false);
			})
			.catch((err) => {
				setViewLoader(false);
			});
	}, [currentPage]);

	return (
		<>
			<Header />

			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Fastest Growing Franchise</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<section className="blue-sec">
				<h4>Add Franchises To Basket</h4>
				<h4>For Free Information</h4>
			</section>

			{CartDataRedux?.length > 0 ? <Bucket /> : null}

			{/* Featured section  */}
			<section className="featured-sec">
				<div className="container">
					<div className="featured-sec-head text-left">
						<p>Search Franchises</p>
						<h2>Franchises Available</h2>
					</div>
					<div className="row">
						{viewLoader ? (
							<>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{AvaialableFranchiseRedux?.length > 0 ? (
									<>
										{AvaialableFranchiseRedux?.map((item, index) => {
											return (
												<FranchiseCard
													item={item}
													index={index}
													setBucket={setBucket}
													bucket={bucket}
													AddToCartHandler={AddToCartHandler}
												/>
											);
										})}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="img-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
					<div className="pagination-container mt-5">
						<ReactPaginate
							previousLabel="Previous"
							nextLabel="Next"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
			</section>
			{/* End of Featured section  */}

			<Footer />
		</>
	);
};

export default Available;
