import React, { useState } from "react";
import _ from "lodash";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/Signupbroker.css";
import Path1 from "../../Assets/img/Path1.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	AllCategoriesData,
	AllStates,
	signUp,
	WebSiteSettings,
} from "../../redux/actions/AuthActions";
import StripeForm from "../../Components/Payment/StripeForm";
import { useEffect } from "react";
import {
	GetAllAvailableStates,
	GetCategoriesApi,
	GetSiteSettingsApi,
} from "../../network/Network";
import { BsFillImageFill } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import Bucket from "../../Components/Bucket";
import MyPaymentForm from "../../Components/Payment/SquarePayment";

const Signupfranchisor = () => {
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const CategoriesReduxData = useSelector(
		(state) => state.AuthReducer.categories,
	);
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [infoState, setInfoState] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [franchiseName, setFranchiseName] = useState("");
	const [franchiseCandidate, setFranchiseCandidate] = useState("");
	const [description, setDescription] = useState("");
	// const [industryCategory, setIndustryCategory] = useState("");
	const [industryCategories, setIndustryCategories] = useState([]);

	const [minCashReq, setMinCashReq] = useState("");
	const [showMinCashReq, setShowMinCashReq] = useState("");
	const [netWorthReq, setNetWorthReq] = useState("");
	const [uploadImages, setUploadImages] = useState([]);
	const [uploadVideo, setUploadVideo] = useState([]);
	const [noOfLeads, setNoOfLeads] = useState(20);
	const [unitPrice, setUnitPrice] = useState();
	const [totalAmount, setTotalAmount] = useState();
	const [cardToken, setCardToken] = useState("");
	const [minInvestment, setMinInvestment] = useState("");
	const [maxInvestment, setMaxInvestment] = useState("");

	// const [financialAssistanceAvailable, setFinancialAssistanceAvailable] =
	//   useState("");
	const [passiveOwnershipAllowed, setPassiveOwnershipAllowed] = useState([]);
	const [btnLoading, setBtnLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [multiImageFiles, setMultiImageFiles] = useState([]);
	const [multiVideosFiles, setMultiVideosFiles] = useState([]);
	const [getAllStates, setGetAllStates] = useState([]);
	const [getBackupStates, setGetBackupStates] = useState([]);
	const [selectedAvailableStates, setSelectedAvailableStates] = useState([]);
	const [getCheckAllStates, setCheckAllStates] = useState(false);
	const [selectedNotAvailableStates, setSelectedNotAvailableStates] = useState(
		[],
	);
	const [logo, setLogo] = useState("");
	const [logoFileupload, setLogoFileupload] = useState("");

	const [coverFileupload, setCoverFileupload] = useState("");
	const [coverImage, SetCoverImage] = useState("");

	const [yearFound, setYearFound] = useState("");
	const [headquarters, setHeadquarters] = useState("");
	const [franchisingSince, setFranchisingSince] = useState("");
	const [total_units, setTotal_units] = useState("");

	const [royaltyfee, setRoyaltyfee] = useState("");
	const [advertisingFee, setAdvertisingFee] = useState("");
	const [franchiseFee, setFranchiseFee] = useState("");

	const [youtubeUrl, setYoutubeUrl] = useState([]);

	const [inputFields, setInputFields] = useState(1);

	const handleImageUpload = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadImages((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderPhotos = (source) => {
		return source.map((photo) => {
			return (
				<li>
					{" "}
					<img
						src={photo}
						alt=""
						style={{ width: "100px", height: "100px", objectFit: "cover" }}
					/>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiImg(e, photo)}
					/>
				</li>
			);
		});
	};

	const VideosUploadHandler = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiVideosFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadVideo((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderVideos = (source) => {
		return source.map((video) => {
			return (
				<li>
					<video width="320" height="240" controls>
						<source src={video} type="video/mp4" />
					</video>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiVideos(e, video)}
					/>
				</li>
			);
		});
	};

	const HandleLogoUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setLogo(file);
			setLogoFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		// setUploadLoading(true);
	};

	const HandleCoverUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			SetCoverImage(file);
			setCoverFileupload(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const SelectedAvailableHandler = (e, type, value) => {
		if (value === 0 && e.target.checked) {
			const remStates = getAllStates.filter((element) => element?.id === value);
			setGetAllStates(remStates);
			setSelectedAvailableStates([e.target.value]);
		} else if (value === 0 && !e.target.checked) {
			setGetAllStates(getBackupStates);
			setSelectedAvailableStates([]);
		} else if (value !== 0 && e.target.checked) {
			setGetAllStates(getBackupStates);
			setSelectedAvailableStates([...selectedAvailableStates, e.target.value]);
		}
	};

	// Get Cate Data
	useEffect(() => {
		GetCategoriesApi()
			.then((res) => {
				// console.log(res);
				dispatch(AllCategoriesData(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	let passiveOwnershipData = [
		{
			id: 1,
			name: "Owner-Operator",
		},
		{
			id: 2,
			name: "Semi-Absentee",
		},
		{
			id: 3,
			name: "Passive",
		},
	];

	const SignUpHandler = async (e, token, buyer) => {
		e.preventDefault();
		setBtnLoading(true);
		if (!firstName) {
			toast.error("Please enter the first name");
			setBtnLoading(false);
			return;
		}
		if (!lastName) {
			toast.error("Please enter the last name");
			setBtnLoading(false);
			return;
		}
		if (!email) {
			toast.error("Please enter the email  ");
			setBtnLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setBtnLoading(false);
			return;
		}
		if (!phone) {
			toast.error("Please enter the phone ");
			setBtnLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setBtnLoading(false);
			return;
		}
		if (!password) {
			toast.error("Please enter the password  ");
			setBtnLoading(false);
			return;
		}
		if (!confirmPassword) {
			toast.error("Please enter the confirm password  ");
			setBtnLoading(false);
			return;
		}
		if (password != confirmPassword) {
			toast.error("Please enter the same password and confirm the password");
			setBtnLoading(false);
			return;
		}

		if (!franchiseName) {
			toast.error("Please enter the franchise name     ");
			setBtnLoading(false);
			return;
		}

		if (!zipCode) {
			toast.error("Please enter the zip code  ");
			setBtnLoading(false);
			return;
		}
		if (!franchiseCandidate) {
			toast.error("Please enter the ideal franchise");
			setBtnLoading(false);
			return;
		}

		if (!logo) {
			toast.error("Please Upload Logo");
			setBtnLoading(false);
			return;
		}

		if (!coverImage) {
			toast.error("Please Upload Cover Image");
			setBtnLoading(false);
			return;
		}

		if (!description) {
			toast.error("Please enter the description    ");
			setBtnLoading(false);
			return;
		}

		if (description.length < 300) {
			toast.error("The description must be greater than 300 characters");
			setBtnLoading(false);
			return;
		}

		if (industryCategories?.length < 1) {
			toast.error("Please select at least one category");
			setBtnLoading(false);
			return;
		}

		if (!showMinCashReq) {
			toast.error("Please enter the minimum cash required");
			setBtnLoading(false);
			return;
		}

		if (!minInvestment) {
			toast.error("Please enter the min investment   ");
			setBtnLoading(false);
			return;
		}

		if (!maxInvestment) {
			toast.error("Please enter the max investment   ");
			setBtnLoading(false);
			return;
		}

		if (!yearFound) {
			toast.error("Please enter the year founded");
			setBtnLoading(false);
			return;
		}

		if (!franchisingSince) {
			toast.error("Please enter the franchising since  ");
			setBtnLoading(false);
			return;
		}

		if (!total_units) {
			toast.error("Please enter the total units ");
			setBtnLoading(false);
			return;
		}

		if (!headquarters) {
			toast.error("Please enter the headquarters location");
			setBtnLoading(false);
			return;
		}

		if (!royaltyfee) {
			toast.error("Please enter the royalty fee     ");
			setBtnLoading(false);
			return;
		}
		if (!advertisingFee) {
			toast.error("Please enter the advertising Fee     ");
			setBtnLoading(false);
			return;
		}

		if (!franchiseFee) {
			toast.error("Please enter the franchise Fee    ");
			setBtnLoading(false);
			return;
		}

		if (!netWorthReq) {
			toast.error("Please enter the net worth required   ");
			setBtnLoading(false);
			return;
		}

		if (passiveOwnershipAllowed?.length < 1) {
			toast.error("Please select at least one Ownership Types Allowed");
			setBtnLoading(false);
			return;
		}

		if (selectedAvailableStates?.length < 1) {
			toast.error("Please select at least one Available in these states ");
			setBtnLoading(false);
			return;
		} else {
			var data = new FormData();
			data.append("user_type", "franchisor");
			data.append("first_name", firstName);
			data.append("last_name", lastName);
			data.append("phone", phone);
			data.append("email", email);
			data.append("password", password);
			data.append("confirm_password", confirmPassword);
			data.append("name", franchiseName);
			data.append("zip", zipCode);
			data.append("ideal_franchise_candidate", franchiseCandidate);
			data.append("logo", logo);
			data.append("coverImage", coverImage);
			data.append("description", description);
			industryCategories?.map((categoryId) => {
				data.append("category_ids[]", categoryId);
			});
			data.append("min_cash_required", `$${showMinCashReq}`);
			data.append("min_investment", `$${minInvestment}`);
			data.append("max_investment", `$${maxInvestment}`);
			data.append("year_founded", yearFound);
			data.append("franchising_since", franchisingSince);
			data.append("total_units", total_units);
			data.append("headquarters", headquarters);
			data.append("royalty_fee", royaltyfee);
			data.append("advertising_fee", advertisingFee);
			data.append("franchise_fee", `$${franchiseFee}`);
			data.append("net_worth_required", `$${netWorthReq}`);
			passiveOwnershipAllowed?.map((passive) => {
				data.append("passive_ownership_allowed[]", passive);
			});
			selectedAvailableStates?.map((state) => {
				data.append("states[]", state);
			});
			youtubeUrl?.map((url) => {
				data.append("youtube_urls[]", url);
			});
			uploadImages.map((singlePic) => {
				data.append("images[]", singlePic);
			});
			uploadVideo.map((singleVideo) => {
				data.append("videos[]", singleVideo);
			});
			data.append("min_cash_required_display", 1);
			data.append("net_worth_required_display", 1);
			data.append("max_investment_display", 1);
			data.append("min_investment_display", 1);
			data.append("financial_assistance_available_display", 1);
			data.append("passive_ownership_allowed_display", 1);
			data.append("franchise_fee_display", 1);
			data.append("advertising_fee_display", 1);
			data.append("royalty_fee_display", 1);
			await dispatch(signUp(data, Navigate, setBtnLoading));
		}
	};

	const PassiveOwnershipHandler = (e) => {
		e.preventDefault();
		if (e.target.checked === true) {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal, e.target.value];
			});
		} else {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal.filter((val) => val !== e.target.value)];
			});
		}
	};

	useEffect(() => {
		GetAllAvailableStates()
			.then((res) => {
				// console.log("avb-states", res);
				setGetAllStates(res?.data?.response?.data);
				setGetBackupStates(res?.data?.response?.data);
				dispatch(AllStates(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	const RemoveMultiImg = (e, item) => {
		e.preventDefault();
		setMultiImageFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	const RemoveMultiVideos = (e, item) => {
		e.preventDefault();
		// console.log(item);
		setMultiVideosFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	// Get Pages Data
	useEffect(() => {
		GetSiteSettingsApi()
			.then((res) => {
				// console.log(res);
				dispatch(WebSiteSettings(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	// maaz precentage check

	const setMinCashRequired = (e) => {
		// setMinCashReq(e.target.value);
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setShowMinCashReq(showVal);
			} else {
				setShowMinCashReq("");
			}
		} catch (err) {
			setShowMinCashReq("");
		}
		// let value_comma1 = parseInt(e.target.value).toLocaleString("en");
		// let checkVal = minCashReq.split(",").join("");
		// // .split(',').join('')
		// // let value_comma = parseInt(e.target.value)
		// setShowMinCashReq(value_comma1);
		// // setMinCashReq(value_comma.toLocaleString("en"));
		// console.log(showMinCashReq, '888888888888888888888888888888888888888');
	};

	const setMinInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMinInvestment(showVal);
			} else {
				setMinInvestment("");
			}
		} catch (err) {
			setMinInvestment("");
		}
	};

	const setMaxInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMaxInvestment(showVal);
			} else {
				setMaxInvestment("");
			}
		} catch (err) {
			setMaxInvestment("");
		}
	};

	const changeFranchiseFees = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setFranchiseFee(showVal);
			} else {
				setFranchiseFee("");
			}
		} catch (err) {
			setFranchiseFee("");
		}
	};

	const changeNetWorth = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setNetWorthReq(showVal);
			} else {
				setNetWorthReq("");
			}
		} catch (err) {
			setNetWorthReq("");
		}
	};

	const handleTagClick = (event, value) => {
		event.preventDefault();
		if (industryCategories.includes(value)) {
			setIndustryCategories(
				industryCategories.filter((category) => category !== value),
			);
		} else {
			setIndustryCategories([...industryCategories, value]);
		}
	};

	const addInputField = (e) => {
		e.preventDefault();
		setInputFields(inputFields + 1);
	};

	const removeInputField = (e, indexToRemove, indexOfUrl) => {
		e.preventDefault();
		// console.log(indexToRemove);
		// setYoutubeUrl(youtubeUrl.filter((_, index) => index !== indexToRemove));
		// setInputFields(inputFields - 1);
		youtubeUrl.splice(indexOfUrl, 1);
		setYoutubeUrl(youtubeUrl);
		setInputFields(inputFields - 1);
	};

	return (
		<>
			<Header />
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As Franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="blue-sec">
				<h4>Add Franchises To Basket</h4>
				<h4>For Free Information</h4>
			</section>
			{CartDataRedux?.length > 0 ? <Bucket /> : null}

			{/* main form start */}
			<section className="signupform">
				<div className="container">
					<div className="all-form-wrapper">
						<div className="form-wrapper">
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-wrapper">
										<h4>Personal Information</h4>
										{/* <p>uspendisse mollis ante imperdiet, dictum sapien eu,</p> */}
									</div>
								</div>
							</div>
							<form>
								<div className="row">
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												className="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="First Name"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
											/>
										</div>
										<div class="form-group">
											<input
												type="email"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Email Address"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>

										<div class="form-group">
											<input
												type="Password"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Last Name"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
											/>
										</div>
										<div class="form-group">
											<input
												type="number"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Phone Number"
												value={phone}
												onChange={(e) => setPhone(e.target.value)}
											/>
										</div>

										<div class="form-group">
											<input
												type="Password"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Confirm Password"
												value={confirmPassword}
												onChange={(e) => setConfirmPassword(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-wrapper">
											<h4>Information on Franchise You’d Like to Advertise</h4>
											{/* <p>uspendisse mollis ante imperdiet, dictum sapien eu,</p> */}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Franchise Name"
												value={franchiseName}
												onChange={(e) => setFranchiseName(e.target.value)}
											/>
										</div>

										{/* 
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchise Headquarters State"
                        value={infoState}
                        onChange={(e) => setInfoState(e.target.value)}
                      />
                    </div> */}
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Headquarters Zip code"
												value={zipCode}
												onChange={(e) => setZipCode(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-lg-12">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Ideal Franchisee (one sentence max)"
												value={franchiseCandidate}
												onChange={(e) => setFranchiseCandidate(e.target.value)}
											/>
										</div>
									</div>
									{/* <div className="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Ideal Franchisee (one sentence max)"
                        value={franchiseCandidate}
                        onChange={(e) => setFranchiseCandidate(e.target.value)}
                      />
                    </div>

                  </div> */}
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>
												Upload Brand Logo ( ideal pixel size is 250 x 150 )
											</h5>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 mb-2 text-center">
										<div class="input-group">
											<label className="multi-image-upload">
												<img className="img-fluid" src={Path1}></img>
												<span className="addfile">Add file</span>
												<span className="ordrop"> or drop files here</span>
											</label>
											<input
												type="file"
												id="fileUpload"
												accept="image/*"
												onChange={HandleLogoUpload}
												multiple={false}
												className="form-control file-opc"
											/>
											<div className="mt-5">
												{logo ? (
													<img
														src={logoFileupload}
														className="image-fluid image-width"
														style={{
															width: "100px",
															height: "100px",
															objectFit: "cover",
														}}
														alt=""
													/>
												) : null}
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>
												Upload Cover Photo ( ideal pixel size is 820 x 312 )
											</h5>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 mb-2 text-center">
										<div class="input-group">
											<label className="multi-image-upload">
												<img className="img-fluid" src={Path1}></img>
												<span className="addfile">Add file</span>
												<span className="ordrop"> or drop files here</span>
											</label>
											<input
												type="file"
												id="fileUpload"
												accept="image/*"
												onChange={HandleCoverUpload}
												multiple={false}
												className="form-control file-opc"
											/>
											<div className="mt-5">
												{coverImage ? (
													<img
														src={coverFileupload}
														className="image-fluid image-width"
														style={{
															width: "100px",
															height: "100px",
															objectFit: "cover",
														}}
														alt=""
													/>
												) : null}
											</div>
										</div>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-lg-12">
										<div class="texterea">
											<textarea
												type="texterea"
												class="form-control"
												aria-describedby="emailHelp"
												placeholder="Input text overview/description of your franchise (will appear on the Website), We suggest ~2-4 paragraphs."
												value={description}
												onChange={(e) => setDescription(e.target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div class="form-group industry-category3">
											<h3>
												Select All Industries/Categories that are relevant to
												your franchise:
											</h3>
											<div>
												{CategoriesReduxData?.slice(1)?.map((item, index) => {
													const selected = industryCategories.includes(
														item?.id,
													);
													return (
														<button
															key={index}
															onClick={(event) =>
																handleTagClick(event, item?.id)
															}
															style={{
																backgroundColor: selected ? "#0077c2" : "",
																color: selected ? "#fff" : "",
																padding: "5px 10px",
																borderRadius: "10px",
																margin: "5px",
																cursor: "pointer",
																border: "none",
															}}
														>
															{item?.name}
														</button>
													);
												})}
											</div>
										</div>
									</div>
									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"$"}</span>
										{/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control field-pd-left"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Minimum Cash Required"
                        value={minCashReq}
                        onChange={(e) => setMinCashReq(e.target.value)}
                      />
                    </div> */}

										<div class="form-group">
											<input
												type="text"
												class="form-control field-pd-left"
												name="minCash"
												// id="exampleInputEmail1"
												// aria-describedby="emailHelp"
												placeholder="Minimum Cash Required"
												value={showMinCashReq}
												// onChange={}
												onChange={setMinCashRequired}
											/>
										</div>
									</div>
									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"$"}</span>
										{/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control field-pd-left"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Min Investment"
                        value={minInvestment}
                        onChange={(e) => setMinInvestment(e.target.value)}
                      />
                    </div> */}
										<div class="form-group">
											<input
												type="text"
												class="form-control field-pd-left"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Min Investment"
												value={minInvestment}
												onChange={setMinInvstRequired}
											/>
										</div>
									</div>
									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"$"}</span>
										{/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control field-pd-left"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Max Investment"
                        value={maxInvestment}
                        onChange={(e) => setMaxInvestment(e.target.value)}
                      />
                    </div> */}
										<div class="form-group">
											<input
												type="text"
												class="form-control field-pd-left"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Max Investment"
												value={maxInvestment}
												onChange={setMaxInvstRequired}
											/>
										</div>
										{/* <div class="form-group">
                    <select
                           className="form-control"
                           id="exampleInputEmail1"
                        onChange={(e) => {
                          setPassiveOwnershipAllowed(e.target.value);
                        }}
                      >
                        <option selected="">Ownership Types Allowed</option>
                        <option value={1}>Yes </option>
                        <option value={0}>No </option>
                      </select>
                      
                    </div> */}
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="number"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Year Founded"
												value={yearFound}
												maxlength="4"
												onChange={(e) => setYearFound(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="number"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Franchising Since"
												value={franchisingSince}
												maxlength="4"
												onChange={(e) => setFranchisingSince(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="number"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Total Units"
												value={total_units}
												onChange={(e) => setTotal_units(e.target.value)}
											/>
										</div>
									</div>
									<div className="col-lg-6">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Headquarters Location"
												value={headquarters}
												onChange={(e) => setHeadquarters(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"%"}</span>
										<div className="form-group">
											<input
												type="text"
												class="form-control pl-percetage"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Royalty Fee"
												value={royaltyfee}
												onChange={(e) => setRoyaltyfee(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"%"}</span>
										<div className="form-group">
											<input
												type="text"
												class="form-control pl-percetage"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Advertising Fee"
												value={advertisingFee}
												onChange={(e) => setAdvertisingFee(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"$"}</span>
										{/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control field-pd-left"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Franchise Fee"
                        value={franchiseFee}
                        onChange={(e) => setFranchiseFee(e.target.value)}
                      />
                    </div> */}
										<div className="form-group">
											<input
												type="text"
												class="form-control field-pd-left"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Franchise Fee"
												value={franchiseFee}
												onChange={changeFranchiseFees}
											/>
										</div>
									</div>

									<div className="col-lg-6 pos-rel">
										<span className="doollar_Sign_adjust2">{"$"}</span>
										{/* <div class="form-group">
                      <input
                        type="text"
                        class="form-control field-pd-left"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Net Worth Required"
                        value={netWorthReq}
                        onChange={(e) => setNetWorthReq(e.target.value)}
                      />
                    </div> */}
										<div class="form-group">
											<input
												type="text"
												class="form-control field-pd-left"
												id="exampleInputEmail1"
												aria-describedby="emailHelp"
												placeholder="Net Worth Required"
												value={netWorthReq}
												onChange={changeNetWorth}
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="heading-content">
											<h5>Ownership Types Allowed</h5>
										</div>
									</div>
									{passiveOwnershipData?.map((item, index) => {
										return (
											<div className="col-md-4" key={index}>
												<div>
													<input
														type="checkbox"
														id={item?.id}
														value={item?.name}
														onChange={(e) => PassiveOwnershipHandler(e)}
													/>

													<label className="ml-2">{item?.name}</label>
												</div>
											</div>
										);
									})}
								</div>

								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>Available in these states</h5>
										</div>
									</div>
								</div>
								<div className="row">
									{getAllStates
										.filter((val) => {
											if (
												selectedNotAvailableStates.find(
													(value) => value == val?.id,
												)
											) {
											} else {
												return val;
											}
										})
										?.map((item, index) => {
											return (
												<div className="col-md-4" key={index}>
													<div
														className={`${
															selectedNotAvailableStates.includes(item?.id) ==
															true
																? "state-hide"
																: "prefer-method"
														}`}
													>
														<div>
															<input
																type="checkbox"
																id={item?.id}
																value={item?.id}
																onChange={(e) =>
																	SelectedAvailableHandler(e, "yes", item?.id)
																}
															/>
															<label className="ml-2">{item?.name}</label>
														</div>
													</div>
												</div>
											);
										})}
								</div>

								{/* <div className="row">
                  {getCheckAllStates ? (
                    <div>
                      <input
                        type="checkbox"
                        id={0}
                        value={0}
                        onChange={(e) => SelectedAvailableHandler(e, "yes", 0)}
                      />
                      <label className="ml-2">{"All States"}</label>
                    </div>
                  ) : (
                    getAllStates
                      .filter((val) => {
                        if (
                          selectedNotAvailableStates.find(
                            (value) => value == val?.id
                          )
                        ) {
                          console.log(
                            "Not found in Available",
                            selectedNotAvailableStates.find(
                              (value) => value == val?.id
                            )
                          );
                        } else {
                          return val;
                        }
                      })
                      ?.map((item, index) => {
                        return (
                          <>
                            <div className="col-md-4" key={index}>
                              <div
                                className={`${
                                  selectedNotAvailableStates.includes(
                                    item?.id
                                  ) == true
                                    ? "state-hide"
                                    : "prefer-method"
                                }`}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    id={item?.id}
                                    value={item?.id}
                                    onChange={(e) =>
                                      SelectedAvailableHandler(
                                        e,
                                        "yes",
                                        item?.id
                                      )
                                    }
                                  />
                                  <label className="ml-2">{item?.name}</label>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                  )}
                </div> */}
								{/* <div className="row">
                  <div className="col-lg-12">
                    <div className="heading-content">
                      <h5>Not avaialable in these states</h5>
                    </div>
                  </div>
                </div> */}
								{/* <div className="row">
                  {getAllStates
                    .filter((val) => {
                      if (
                        !selectedAvailableStates.find(
                          (value) => value == val?.id
                        )
                      ) {
                        return val;
                      }
                    })
                    ?.map((item, index) => {
                      return (
                        <>
                          <div className="col-md-4" key={index}>
                            <div className="prefer-method">
                              <div>
                                <input
                                  type="checkbox"
                                  id={`${item?.name}${item?.id}`}
                                  value={item?.id}
                                  onChange={(e) =>
                                    SelectedAvailableHandler(e, "No")
                                  }
                                />
                                <label className="ml-2">{item?.name}</label>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div> */}
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>YouTube Video</h5>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-12">
										<div class="form-group">
											{/* {Array.from({ length: inputFields }, (_, index) => (
                        <input
                          key={index}
                          type="url"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Youtube Video Url"
                          value={youtubeUrl}
                          onChange={(e) => setYoutubeUrl(e.target.value)}
                        />
                      ))} */}
											{Array.from({ length: inputFields }, (_, index) => (
												<div className="dynamicFieldsWraper">
													<input
														style={{ margin: "0px 10px 0px 0px" }}
														key={index}
														type="url"
														class="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Youtube Video Url"
														value={youtubeUrl[index] || ""}
														onChange={(e) => {
															const newUrls = [...youtubeUrl];
															newUrls[index] = e.target.value;
															setYoutubeUrl(newUrls);
														}}
													/>

													<button
														className="removeFieldbtn"
														onClick={(e) => {
															const urlIndex = youtubeUrl[index];
															removeInputField(e, urlIndex, index);
														}}
													>
														Remove
													</button>
												</div>
											))}
										</div>
										<button
											className="addFieldbtn"
											onClick={(e) => addInputField(e)}
										>
											Add Url
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>Upload Videos</h5>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 mb-2 text-center">
										<div class="input-group">
											<label className="multi-image-upload">
												<img className="img-fluid" src={Path1}></img>
												<span className="addfile">Add file</span>
												<span className="ordrop"> or drop files here</span>
											</label>
											<input
												type="file"
												accept="video/*"
												onChange={VideosUploadHandler}
												multiple={true}
												className="form-control file-opc"
												id="fileUpload"
												// hidden
											/>
											<ul className="multi-imglist">
												{renderVideos(multiVideosFiles)}
											</ul>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-content">
											<h5>Upload Images</h5>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 mb-2 text-center">
										<div class="input-group">
											<label className="multi-image-upload">
												<img className="img-fluid" src={Path1}></img>
												<span className="addfile">Add file</span>
												<span className="ordrop"> or drop files here</span>
											</label>
											<input
												type="file"
												accept="image/*"
												onChange={handleImageUpload}
												multiple={true}
												className="form-control file-opc"
												id="fileUpload"
											/>

											<ul id="fileList" className="multi-imglist">
												{renderPhotos(multiImageFiles)}
											</ul>
										</div>
									</div>
								</div>

								{/* <div className="row">
                  <div className="col-lg-12">
                    <div className="heading-content">
                      <h5>Purchase Leads</h5>
                    </div>
                  </div>
                </div> */}
								{/* <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label>No of Leads</label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="No Of Leads"
                        value={noOfLeads}
                        onChange={(e) => setNoOfLeads(e.target.value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div class="form-group">
                      <label>Unit Price</label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Unit Price"
                        value={parseInt(SiteSettingsRedux?.lead_price)}
                        onChange={(e) => setUnitPrice(e.target.value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div class="form-group">
                      <label>Total Amount</label>
                      <input
                        type="number"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Total Amount"
                        value={
                          noOfLeads * parseInt(SiteSettingsRedux?.lead_price)
                        }
                        onChange={(e) => setTotalAmount(e.target.value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div> */}
								{/* <div className="row">
                  <div className="col-lg-12">
                    <div className="heading-content">
                      <h5>Payment</h5>
                    </div>
                  </div>
                </div> */}
								{/* <div className="row">
                  <div className="col-lg-12">
                    <MyPaymentForm
                      cardTokenizeResponseReceived={SignUpHandler}
                      loading={btnLoading}
                    />
                  </div>
                </div> */}
								<div className="row">
									<div className="col-md-4 mt-5 mx-auto text-center">
										<div className="submit-dv">
											<button
												onClick={(e) => SignUpHandler(e)}
												disabled={btnLoading}
											>
												{btnLoading ? "Loading..." : "Submit"}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Signupfranchisor;
