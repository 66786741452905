import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const MatchingQuiz1 = (props) => {
	const { quizData, SelectHandler, selectedData, viewLoader } = props;

	const navigate = useNavigate();
	return (
		<>
			<section className="quiz">
				<div className="container">
					<div className="quiz_box">
						<div className="heading">
							<h5>
								Complete our short quiz to receive your free, customized
								franchise match.
							</h5>
							<h2>Connect with franchises</h2>
						</div>
						{/* <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "6%" }}
                aria-valuenow="6"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              <div className="progress_count">
                <h5>6% Complete</h5>
              </div>
            </div> */}
						<div className="quiz_ques">
							<h2>
								{/* What level of involvement would you like to have in your
                business? */}
								{quizData[0] ? quizData[0]?.question : null}
							</h2>
						</div>

						{viewLoader ? (
							<>
								<div className="row">
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
								</div>
							</>
						) : (
							<div className="answer">
								{quizData[0] ? (
									<>
										{quizData[0]?.options?.map((item, index) => {
											return (
												<>
													{/* <button
                          className={`${
                            selectedData?.includes(item?.option) == true
                              ? "franchise-btn franchise-btn-select"
                              : "franchise-btn"
                          }`}
                          // className="franchise-btn"
                          key={index}
                          onClick={(e) => SelectHandler(e, item)}
                        >
                          {item?.option}
                        </button> */}
													<button
														id={`button${index}`}
														className={`${
															selectedData.some(
																(selectedItem) =>
																	selectedItem.answer === item.id,
															)
																? "franchise-btn franchise-btn-select"
																: "franchise-btn"
														}`}
														onClick={(e) => SelectHandler(e, item)}
													>
														{item?.option}
													</button>
													<br />
												</>
											);
										})}

										{/* {quizData[0]?.options?.filter((val) => {
                      if (
                        selectedData?.find(
                          (value) => value?.answer == val?.id
                        )
                      ) {
                        console.log(
                          "match",
                          selectedData?.find(
                            (value) => value?.answer == val?.id
                          )
                        );
                      } else {
                        return val;
                      }
                    })
                    ?.map((item,index)=>{
                      return(
                        <>
                              <button 
                                className={`${
                                  selectedData?.includes(item?.option) ==
                                  true
                                    ? "franchise-btn-select"
                                    : "franchise-btn"
                                }`}
                                key={index}
                                onClick={(e)=>SelectHandler(e,item)}
                             >
                                 {item?.option}
                               </button>
                               <br />
                             </>
                      )
                    })} */}
									</>
								) : null}

								{/* <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">Absentee</button>
              <br />
              <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">
                Semi-absentee (work ~5-10 hours a week on the business)
              </button>
              <br />
              <button onClick={()=> navigate('/quiz-two')} className="franchise-btn">
                Actively manage day-to-day
              </button> */}
							</div>
						)}
					</div>
				</div>
			</section>
			{/* Quiz Box */}

			{/* <Footer /> */}
		</>
	);
};

export default MatchingQuiz1;

// {quizData[0]?.options?.map((item, index) => {
//   return (
//     <>
//       <button className="franchise-btn" key={index}
//         onClick={(e)=>SelectHandler(e,item)}
//       >
//         {item?.option}
//       </button>
//       <br />
//     </>
//   );
// })}
