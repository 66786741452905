import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Bucket from "../../Components/Bucket";

const Investment = () => {
  const navigate = useNavigate()
  const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const InvestmentLevelRedux = useSelector(
    (state) => state.AuthReducer.investmentLevels
  );

  const AllStatesDataRedux = useSelector(
    (state) => state.AuthReducer.allStates
  );

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedMinMax, setSelectedMinMax] = useState("");

  const FindHandler = (e) => {
    e.preventDefault()
    let data = {
      category: selectedCategory,
      state: selectedState,
      minMax: selectedMinMax,
    }
    navigate(`/fastest-growing`, { state: { data: data } })
  }
  return (
    <>
      <Header />

      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                <Link to="/" >Home</Link> <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span>Investment Level</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <section className="blue-sec">
        <h4>Add Franchises To Basket</h4>
        <h4>For Free Information</h4>
      </section>

      {CartDataRedux?.length > 0 ? <Bucket /> : null}

      {/* By state section  */}
      <section className="by-state-sec">
        <div className="container">
          <div className="by-state-head">
            <p>Investment Levels</p>
            <h2>Minimum Cash Required</h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {InvestmentLevelRedux?.map((item, index) => {
                  return (
                    <div className="col-md-4 col-6">
                      <div className="state-ul">
                        <ul>
                          <li key={index}>
                            <Link to={`/investment-filter/${item?.id}`}>
                               {item?.name ? item?.name : item?.convert_price}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-4">
              <div className="all-things-newd">
                <div className="row">
                  <div className="col-md-12">
                    <div className="find-frnchise">
                      <h3>Search Franchises</h3>
                      {/* <p>uspendisse mollis ante imperdiet, dictum</p> */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                        }}
                      >
                        <option value={CategoriesReduxData ? CategoriesReduxData[0]?.id : ""}>{CategoriesReduxData ? CategoriesReduxData[0]?.name : "By Category" }</option>
                        {CategoriesReduxData?.map((item, index) => {
                          return (
                          <>
                            {
                              item?.name == "All Categories" ? (null) : (
                                <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                              )
                            }
                          </>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          setSelectedState(e.target.value);
                        }}
                      >
                       <option value={AllStatesDataRedux ? AllStatesDataRedux[0]?.id : ""}>{AllStatesDataRedux ? AllStatesDataRedux[0]?.name : "By State"}</option>
                        {AllStatesDataRedux?.map((item, index) => {
                          return (
                           <>
                            {
                              item?.name == "All States" ? (null) : (
                                <option value={item?.id} key={index}>
                                {item?.name}
                              </option>
                              )
                            }
                           </>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          setSelectedMinMax(e.target.value);
                        }}
                      >
                          <option value={InvestmentLevelRedux ? InvestmentLevelRedux[0]?.id : ""}>{InvestmentLevelRedux ? InvestmentLevelRedux[0]?.name : "By Investment Level"}</option>
                        {InvestmentLevelRedux?.map((item, index) => {
                          return (
                           <>
                            {
                              item?.name == "All Investments" ? (null) : (
                                <option value={item?.id} key={index}>
                                {item?.convert_price}
                              </option>
                              )
                            }
                           </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="allthings-table">
                <button onClick={(e) => FindHandler(e)}>Find</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of By state section  */}

      <Footer />
    </>
  );
};

export default Investment;
