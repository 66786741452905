import React, { useEffect } from "react";
import loginimg from "../../Assets/img/loginimg.png";
import loginback from "../../Assets/img/loginback.png";
import "../../Assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { login, WebSiteSettings } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { GetSiteSettingsApi } from "../../network/Network";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);

	const handleRememeberMe = (event) => {
		setChecked(!checked);
	};

	const SignInHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!email || !password) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		} else {
			localStorage.setItem("rememberMe", checked);
			localStorage.setItem("email", checked ? email : "");
			localStorage.setItem("password", checked ? password : "");

			let data = {
				email: email,
				password: password,
			};
			setTimeout(async () => {
				setLoading(false);
				let x = await dispatch(login(data));
				if (x) {
					window.location.href = "/profile-account";
					// Navigate("/profile");
				}
			}, 600);
		}
	};

	useEffect(() => {
		const rememberMe = localStorage.getItem("rememberMe") === "true";
		const email = rememberMe ? localStorage.getItem("email") : "";
		const password = rememberMe ? localStorage.getItem("password") : "";
		setEmail(email);
		setPassword(password);
		setChecked(rememberMe);
	}, []);

	// Get Pages Data
	useEffect(() => {
		GetSiteSettingsApi()
			.then((res) => {
				dispatch(WebSiteSettings(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	return (
		<>
			{/* Login Section  */}
			<section className="login-sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-6">
							<div className="login-text ">
								<h2>Login</h2>
								<p className="login-para-text">
									Welcome Back to <span>Franchise Velocity</span> <br></br> The
									Best place to enhance your look{" "}
								</p>

								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<input
													type="password"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Password"
													value={password}
													onChange={(e) => setPassword(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div className="login-btn">
												<button
													onClick={(e) => SignInHandler(e)}
													disabled={loading}
												>
													{loading ? "Loading.." : " Login"}
												</button>
											</div>
										</div>

										{/* <div className="col-md-12">
                      <div className="login-btn franchise-consultant">
                        <button onClick={() => navigate("/profileaccount2")}>
                          Login As Franchise Consultant
                        </button>
                      </div>
                    </div> */}

										<div className="col-md-12">
											<div className="forgot-password">
												<div class="form-check">
													<input
														type="checkbox"
														class="form-check-input"
														id="exampleCheck1"
														value={checked}
														checked={checked}
														onChange={(e) => handleRememeberMe(e)}
													/>
													<label class="form-check-label" for="exampleCheck1">
														Remember Me
													</label>
												</div>
												<div className="form-password">
													<Link to="/enter-email">Forgot Password ?</Link>
												</div>
											</div>
										</div>

										<div className="col-md-12">
											<div className="login-textes">
												<p>
													By Creating Account you are agree to our{" "}
													<Link to="/terms"> Terms & conditions</Link>
												</p>
											</div>
										</div>

										<div className="col-md-12">
											<div className="login-things">
												<h5>Does'nt have a account?</h5>
												<h5>
													Join as a{" "}
													<Link to="/signup-broker">Franchise Consultant</Link>
												</h5>
												<h5>
													Join as a{" "}
													<Link to="/signup-franchisor">Franchisor</Link>
												</h5>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<div className="col-md-6">
							<div className="login-img">
								<img className="img-fluid" src={loginback}></img>
							</div>
							<div className="login-logo">
								<Link to="/">
									{" "}
									<img className="img-fluid" src={loginimg}></img>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Login section  */}
		</>
	);
};

export default Login;
