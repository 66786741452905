import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GiCancel } from "react-icons/gi";
import { Link } from "react-router-dom";

import TermsConditions from "../TermsConditions/TermsConditions";

const TermsModal = (props) => {
  const {
    setIsOpenTermsModal,
    isOpenTermsModal,
    SubmitConsultation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    stateUser,
    setStateUser,
    zip,
    setZip,
    phone,
    setPhone,
    cashAvailable,
    setCashAvailable,
    loading,
    setLoading,
    checkState,
    setCheckState,
  } = props;
  return (
    <>
      <Modal
        isOpen={isOpenTermsModal}
        toggle={() => {
          setIsOpenTermsModal(false);
        }}
        className="consultation-modal orderView-Modal"
      >
        <GiCancel
          className="modal-close"
          onClick={() => setIsOpenTermsModal(false)}
        />
        <TermsConditions />
      </Modal>
    </>
  );
};

export default TermsModal;
