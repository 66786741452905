import React, { useState } from "react";
import Header from "./Header";
import lets from "../Assets/img/lets.png";
import { toast } from "react-toastify";
import { ContactUsApi } from "../network/Network";

const Contactus = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [methodContact, setMethodContact] = useState("");
	const [loading, setLoading] = useState(false);

	const ContactUsHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!name || !phone || !email) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		// if (message.length < 20) {
		//   toast.error("The message must be at least 20 characters");
		//   setLoading(false);
		//   return;
		// }
		if (!methodContact) {
			toast.error("Please Check Method Of Contact");
			setLoading(false);
			return;
		}
		let data = {
			name: name,
			phone: phone,
			email: email,
			method_of_contact: methodContact,
			message: message,
		};
		ContactUsApi(data)
			.then((res) => {
				toast.success(res?.data?.message);
				// setMyAllLeads(res?.data?.response?.data);
				setLoading(false);
				setName("");
				setPhone("");
				setEmail("");
				setMessage("");
				setMethodContact("");
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			{/* Contact-section  */}
			<section className="contact-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="contact-img">
								<img className="img-fluid" src={lets}></img>
							</div>
						</div>

						<div className="col-md-6">
							<div className="head-sub-head">
								<h5>Contact Us</h5>
								<h3>Let’s Explore Your Options Together!</h3>
								<p>
									Reach out to us with any initial questions or concerns you may
									have about franchising, our process, etc. and one of our
									certified franchise consultants will get back to you shortly!
								</p>
							</div>
							<div className="all-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Your Name"
													value={name}
													onChange={(e) => setName(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Phone"
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">Preferred Method Of Contact</h5>
											<div className="prefer-method only-for-flex">
												<div className="form-group custom_check for-margin-no">
													<input
														type="radio"
														id="html"
														name="fav_language"
														value="Phone"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="html">Phone</label>
													<br />
												</div>
												<div className="form-group custom_check for-margin-no">
													<input
														type="radio"
														id="css"
														name="fav_language"
														value="Email"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="css">Email</label>
													<br />
												</div>
												<div className="form-group custom_check for-margin-no">
													 {" "}
													<input
														type="radio"
														id="javascript"
														name="fav_language"
														value="No Preference"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="javascript"> No Preference</label>
												</div>
												   
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<textarea
													class="form-control"
													id="exampleFormControlTextarea1"
													rows="3"
													placeholder="Your Message Here"
													value={message}
													onChange={(e) => setMessage(e.target.value)}
												></textarea>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<div className="form-btn">
													<button
														onClick={(e) => ContactUsHandler(e)}
														disabled={loading}
													>
														{loading ? "Loading.." : "Submit"}
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Contact-section  */}
		</>
	);
};

export default Contactus;
