import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { GetAllFranchisesApi } from "../../network/Network";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { notFound } from "../../constant";

const ManageProfile = () => {
	const [view, setView] = useState(false);
	const Token = useSelector((state) => state.AuthReducer.token);
	const UserData = useSelector((state) => state.AuthReducer.users);
	const [myAllFranchises, setMyAllFranchises] = useState([]);
	const [spinLoad, setSpinLoad] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	useEffect(() => {
		setSpinLoad(true);
		GetAllFranchisesApi(Token, currentPage)
			.then((res) => {
				setMyAllFranchises(res?.data?.response?.data?.data);
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				// dispatch(AllStates(res?.data?.response?.data))
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, [currentPage]);

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>
										Join As{" "}
										{UserData?.user_type == "advertisor"
											? "Advertisor"
											: "Franchisor"}
									</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}
			<section className="dashboard-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							<div className="all-tab-things">
								<div class="tab-content" id="pills-tabContent">
									<div
										class="tab-pane fade show active"
										id="pills-home"
										role="tabpanel"
										aria-labelledby="pills-home-tab"
									>
										<div className="my-franchise-head">
											<h3>My Franchise</h3>
										</div>
										{spinLoad ? (
											<>
												<div className="col-md-12">
													<Skeleton count={5} />
												</div>
												<div className="col-md-12">
													<Skeleton count={5} />
												</div>
											</>
										) : (
											<>
												{myAllFranchises?.length > 0 ? (
													<>
														{myAllFranchises?.map((item, index) => {
															return (
																<div className="my-franchise" key={index}>
																	<div className="row">
																		<div className="col-md-3">
																			<div className="my-franchise-img">
																				<img
																					className="img-fluid"
																					src={item?.logo_path}
																				></img>
																			</div>
																		</div>

																		<div className="col-md-3">
																			<div className="my-franchise-second-col">
																				<h5>ID</h5>
																				<h5>Franchise Name</h5>
																				<h5>Overview</h5>
																			</div>
																		</div>

																		<div className="col-md-6">
																			<div className="my-franchise-third-col">
																				<p>{item?.id}</p>
																				<p>{item?.name}</p>
																				<p>{item?.description}</p>
																			</div>
																		</div>

																		<div className="col-md-12">
																			<div className="two-btns">
																				{/* <button onClick={() => setView(true)}>
                                      view Details
                                    </button> */}
																				<Link
																					to={`/franchise-details/${item?.id}`}
																				>
																					View Details
																				</Link>
																				<Link
																					to={`/edit-franchise/${item?.id}`}
																				>
																					Edit Details
																				</Link>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})}
													</>
												) : (
													<>
														<div className="notFound-container">
															<img
																src={notFound}
																alt="not found"
																className="img-fluid"
															/>
															<p className="mt-4">No Record Found</p>
														</div>
													</>
												)}
											</>
										)}
									</div>
								</div>
							</div>

							{/* End of All franchsie information  */}
							<div></div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default ManageProfile;
