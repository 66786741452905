import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	GetProfile,
	PurcahseLeadsApi,
	GetAllCards,
} from "../../network/Network";
import Sidebar from "./Sidebar";

const Purchase = () => {
	const Token = useSelector((state) => state.AuthReducer.token);
	const UserData = useSelector((state) => state.AuthReducer.users);
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const [view, setView] = useState(false);
	const [userLeads, setUserLeads] = useState();
	const [userCards, setUserCards] = useState(false);

	const [noOfLeads, setNoOfLeads] = useState(20);
	const [unitPrice, setUnitPrice] = useState();
	const [totalPayment, setTotalPayment] = useState();
	const [loading, setLoading] = useState(false);

	const navigateToCardRoute = useNavigate();

	useEffect(() => {
		GetProfile(Token)
			.then((res) => {
				// console.log("user leads", res);
				setUserLeads(res?.data?.response?.data?.lead_count);
			})
			.catch((err) => {});
	}, []);

	// useEffect(() => {
	//   GetAllCards(Token)
	//     .then((res) => {
	//       // console.log("All Cards", res?.data?.response?.data);
	//       setUserCards(res?.data?.response?.data);
	//     })
	//     .catch((err) => {
	//
	//     });
	// }, []);

	useEffect(() => {
		GetAllCards(Token)
			.then((res) => {
				const data = res?.data?.response?.data;
				// const allHaveId = data.every((obj) => obj.hasOwnProperty("id"));
				const check = data.length > 0;

				if (check) {
					setUserCards(true);
				} else {
					setUserCards(false);
				}
			})
			.catch((err) => {});
	}, []);

	const RedirecToCard = (e) => {
		e.preventDefault();
		// alert("Please first add the card then comeback");
		navigateToCardRoute("/card-info");
	};

	const PurchaseLeadsHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (noOfLeads < 20) {
			toast.error("Leads Quantity must be greater than 20");
			setLoading(false);
			return;
		}
		let data = {
			leads: noOfLeads,
			total_amount: 30 * noOfLeads,
			// token: ,
		};
		PurcahseLeadsApi(data, Token)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				setView(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};
	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>
										Join As{" "}
										{UserData?.user_type == "broker"
											? "Franchisor Consultant"
											: "Franchisor"}
									</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Purchase section  */}
			<section className="purchase-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<Sidebar />
						</div>

						<div className="col-md-9">
							{view ? null : (
								<div className="all-purchase-things">
									<div className="account-head">
										<h3>Number Of Remaining Leads</h3>
									</div>

									<div className="purchase-input">
										<input
											type="text"
											// placeholder="20"
											value={userLeads}
											disabled={true}
										></input>
									</div>

									<div className="purchase-btn">
										<button onClick={() => setView(true)}>
											Purchase More Leads
										</button>
									</div>
								</div>
							)}
							{/* New row  */}
							{view ? (
								<div className="all-purhcase-forms-for-hide">
									<div className="account-head">
										<h3>Purchase Leads</h3>
									</div>
									<div className="row purchase-leads">
										<div className="col-md-12">
											<div className="purchase-leads-forms">
												<forms>
													<div className="row form-row">
														<div className="col-md-6">
															<div class="form-group">
																<label>No of Leads</label>
																<input
																	type="number"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="No Of Leads"
																	value={noOfLeads}
																	onChange={(e) => setNoOfLeads(e.target.value)}
																/>
															</div>
														</div>

														<div className="col-md-3">
															<div class="form-group">
																<label>Unit Price</label>
																<input
																	type="text"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="Unit Price"
																	value={parseInt(
																		SiteSettingsRedux?.lead_price,
																	)}
																	onChange={(e) => setUnitPrice(e.target.value)}
																	disabled={true}
																/>
															</div>
														</div>

														<div className="col-md-3">
															<div class="form-group">
																<label>Total Amount</label>
																<input
																	type="text"
																	class="form-control"
																	id="exampleInputEmail1"
																	aria-describedby="emailHelp"
																	placeholder="Total Payment"
																	value={
																		noOfLeads *
																		parseInt(SiteSettingsRedux?.lead_price)
																	}
																	onChange={(e) =>
																		setTotalPayment(e.target.value)
																	}
																	disabled={true}
																/>
															</div>
														</div>
													</div>
												</forms>
											</div>
										</div>
									</div>

									<div className="col-md-12">
										<div className="submit-btn-purhcase">
											{userCards ? (
												<button
													onClick={(e) => PurchaseLeadsHandler(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Submit"}
												</button>
											) : (
												<button disabled={true}>{"Submit"}</button>
											)}
											{!userCards ? (
												<a onClick={(e) => RedirecToCard(e)}>
													<h3
														style={{
															color: "red",
															fontSize: "16px",
															cursor: "pointer",
															margin: "8px",
														}}
													>
														Click Here To Add Payment Method
													</h3>
												</a>
											) : null}
										</div>
									</div>
								</div>
							) : null}
							{/* <End of New row  */}
						</div>
					</div>
				</div>
			</section>

			{/* End of Purchase section  */}

			<Footer />
		</>
	);
};

export default Purchase;
