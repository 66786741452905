import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/newsletter.css";
import Bucket from "../../Components/Bucket";
import { useState } from "react";
import { toast } from "react-toastify";
import { PostNewsLetter } from "../../network/Network";
import { Link } from "react-router-dom";

const Newssignup = () => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [allErrors, setAllErrors] = useState({
		email: "",
	});

	const emailSubscriberHandler = (e) => {
		setEmail(e.target.value);
		setAllErrors({ email: "" });
	};

	const SubmitNewsLetter = (e) => {
		setLoading(true);
		e.preventDefault();
		if (!email) {
			setAllErrors({
				email: "Please Enter Email Address",
			});
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			setAllErrors({
				email: "Invalid Email",
			});
			setLoading(false);
			return;
		}
		let data = {
			email: email,
		};
		PostNewsLetter(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				setEmail("");
				setAllErrors({ email: "" });
			})
			.catch((err) => {
				if (err?.response?.data?.errors?.email) {
					toast.error(err?.response?.data?.errors?.email[0]);
					setLoading(false);
					setAllErrors({ email: "" });
					return;
				}
				setLoading(false);
			});
	};

	// const errorHandler = (fieldName) => {
	// 	return <p style={{ color: "red" }}>{!fieldName ? "sdsd" : null}</p>;
	// };

	console.log(allErrors, "error[email]");

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Newsletter Sign Up</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<Bucket />

			{/* Newsletter section  */}

			<section className="news-letter-sec">
				<div className="container">
					<div className="news-letter-head">
						<h3>Newsletter Signup</h3>
						<p>Stay up to date on the latest trends in franchising</p>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="news-letter-form">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Enter email"
													value={email}
													onChange={(e) => emailSubscriberHandler(e)}
												/>
												<p style={{ color: "red" }}>{allErrors?.email}</p>
											</div>
										</div>

										<div className="col-md-12">
											<div className="form-news-letter">
												<button
													onClick={(e) => SubmitNewsLetter(e)}
													disabled={loading}
												>
													{loading ? "Loading..." : "Submit"}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Newsletter section  */}
			<Footer />
		</>
	);
};

export default Newssignup;
