import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Bucket from "../../Components/Bucket";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <Header />
      {/* Main banner section  */}
      <section className="banner inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Inner-banner-text banner-text">
                <h5>
                  <Link to="/">Home</Link>{" "}
                  <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
                  <span> Thank You</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of banner section  */}

      <Bucket />
      {/* ThankYou section  */}
      <section className="contact-sec conatct-sec-for-padding">
        <div className="container">
          <div className="contact-head">
            <h5>
              Thank You! One of our franchise consultants is reviewing your
              answers and will send your custom recommendation to your email
              within 1 business day.
            </h5>
            <div className="thankyou-div">
              <Link to="/">
                <button>Return to Home Page</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* End of ThankYou section  */}

      <Footer />
    </>
  );
};

export default ThankYou;
