import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "../Pages/About/Aboutus";
import Available from "../Pages/Cashprogram/Available";
import Cash from "../Pages/Cashprogram/Cash";
import Cashprogram from "../Pages/Cashprogram/Cashprogram";
import Investment from "../Pages/Cashprogram/Investment";
import Contact from "../Pages/Contact/Contact";
import Financing from "../Pages/Financingoptions/Financing";
import Franchise101 from "../Pages/franchise101/Franchise101";
import Franchisede from "../Pages/Franchisedetail/Franchisedet";
import FreeConsultation from "../Pages/freeconsultation/FreeConsultation";
import Home from "../Pages/Home/Home";
import Newssignup from "../Pages/Newsletter/Newssignup";
import Request from "../Pages/Requestinfo/Request";
import Privacy from "../Pages/termscondition/Privacy";
import Termscondition from "../Pages/termscondition/Termscondition";
import Signupfranchisor from "../Pages/Signup/Signupfranchisor";
import Signupbroker from "../Pages/Signup/Signupbroker";
import Enteremail from "../Pages/Enteremail/Enteremail";
import Newpassword from "../Pages/Enteremail/Newpassword";
import MatchingQuiz1 from "../Pages/FranchiseQuiz/MatchingQuiz1";
import MatchingQuiz2 from "../Pages/FranchiseQuiz/MatchingQuiz2";
import MatchingQuiz3 from "../Pages/FranchiseQuiz/MatchingQuiz3";
import MatchingQuiz4 from "../Pages/FranchiseQuiz/MatchingQuiz4";
import MatchingQuiz5 from "../Pages/FranchiseQuiz/MatchingQuiz5";
import MatchingQuiz6 from "../Pages/FranchiseQuiz/MatchingQuiz6";
import MatchingQuiz7 from "../Pages/FranchiseQuiz/MatchingQuiz7";
import MatchingQuiz8 from "../Pages/FranchiseQuiz/MatchingQuiz8";
import MatchingQuiz9 from "../Pages/FranchiseQuiz/MatchingQuiz9";
import MatchingQuiz10 from "../Pages/FranchiseQuiz/MatchingQuiz10";
import MatchingQuiz11 from "../Pages/FranchiseQuiz/MatchingQuiz11";
import MatchingQuiz12 from "../Pages/FranchiseQuiz/MatchingQuiz12";
import Login from "../Pages/Login/Login";
import Freeprocess from "../Pages/Freeprocess/Freeprocess";
import Category from "../Pages/Cashprogram/Category";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Profileaccount from "../Pages/Profilepages/Profileaccount";
import ManageProfile from "../Pages/Profilepages/ManageProfile";
import { Card } from "bootstrap-4-react/lib/components";
import Cardinfo from "../Pages/Profilepages/Card-info";
import Leads from "../Pages/Profilepages/Leads";
import Purchase from "../Pages/Profilepages/Purchase";
import Editprofile from "../Pages/Profilepages/Editprofile";
import Editprofile2 from "../Pages/Profilepages2/Editprofile2";
import Editdetail2 from "../Pages/Profilepages2/Editdetail2";
import Manageprofile2 from "../Pages/Profilepages2/Manageprofile2";
import Franch from "../Pages/Profilepages2/Franch";
import Addfranchise from "../Pages/Profilepages2/Addfranchise";
import CategoryAvailaible from "../Pages/Cashprogram/CategoryAvailaible";
import ProtectedRoutes from "./ProtectedRoutes";
import LeadDetails from "../Pages/Profilepages/LeadDetails";
import QuizMain from "../Pages/FranchiseQuiz/QuizMain";
// import QuizTest from "../Pages/FranchiseQuiz/QuizTest";
import NotFound from "../Components/NotFound/NotFound";
import DynamicQuiz from "../Pages/FranchiseQuiz/DynamicQuiz";
import ConfidentialQuestionnaire from "../Pages/ConfidentialPages/ConfidentialQuestionnaire";
import Disclosure from "../Pages/ConfidentialPages/Disclosure";
import ThankYou from "../Pages/ThankYou/ThankYou";




const Publicroutes = () => {
	
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="signup-broker" element={<Signupbroker />} />
					<Route path="signup-franchisor" element={<Signupfranchisor />} />
					<Route path="enter-email" element={<Enteremail />} />
					<Route path="reset-password" element={<Newpassword />} />

					<Route path="contact-us" element={<Contact />} />
					<Route path="category" element={<Category />} />
					<Route path="states" element={<Cash />} />
					<Route path="invesment" element={<Investment />} />
					<Route path="request" element={<Request />} />
					<Route path="about" element={<About />} />
					<Route path="fastest-growing" element={<Available />} />
					<Route path="free-consultation" element={<FreeConsultation />} />
					<Route path="free-process" element={<Freeprocess />} />
					<Route path="financing" element={<Financing />} />
					<Route path="news-letter" element={<Newssignup />} />
					<Route path="terms" element={<Termscondition />} />
					<Route path="privacy" element={<Privacy />} />
					<Route path="cash-program" element={<Cashprogram />} />
					<Route path="franchise/:ID" element={<Franchisede />} />
					<Route path="category-filter/:ID" element={<CategoryAvailaible />} />
					<Route path="state-filter/:ID" element={<CategoryAvailaible />} />
					<Route path="investment-filter/:ID" element={<CategoryAvailaible />} />
					<Route path="franchise101" element={<Franchise101 />} />

					<Route path="confidential-questionnaire" element={<ConfidentialQuestionnaire />} />
					<Route path="disclosure-form" element={<Disclosure />} />

					<Route path="/*" element={<NotFound />} />
				

					<Route path="login" element={<Login />} />
					<Route path="/" element={<ProtectedRoutes />}>
						<Route path="profile-account" element={<Profileaccount />} />
						<Route path="edit-profile" element={<Editprofile />} />
						<Route path="add-franchise" element={<Addfranchise />} />
						<Route path="franchise-details/:ID" element={<Manageprofile2 />} />
						<Route path="edit-franchise/:ID" element={<Editdetail2 />} />
						<Route path="leads" element={<Leads />} />
						<Route path="lead-details/:ID" element={<LeadDetails />} />
						<Route path="purchase-leads" element={<Purchase />} />
						<Route path="franch" element={<Franch />} />
						<Route path="manage-franchise" element={<ManageProfile />} />
						<Route path="card-info" element={<Cardinfo />} />
					</Route>

					
			
					<Route path="/quiz" element={<DynamicQuiz />} />
					<Route path="/thankyou" element={<ThankYou/>} />

					{/* <Route path="/test-route" element={<Manageprofile2/>} /> */}


					{/* <Route path="/quiz-one" element={<MatchingQuiz1 />} />
					<Route path="/quiz-two" element={<MatchingQuiz2 />} />
					<Route path="/quiz-three" element={<MatchingQuiz3 />} />
					<Route path="/quiz-four" element={<MatchingQuiz4 />} />
					<Route path="/quiz-five" element={<MatchingQuiz5 />} />
					<Route path="/quiz-six" element={<MatchingQuiz6 />} />
					<Route path="/quiz-seven" element={<MatchingQuiz7 />} />
					<Route path="/quiz-eight" element={<MatchingQuiz8 />} />
					<Route path="/quiz-nine" element={<MatchingQuiz9 />} />
					<Route path="/quiz-ten" element={<MatchingQuiz10 />} />
					<Route path="/quiz-eleven" element={<MatchingQuiz11 />} />
					<Route path="/quiz-twlve" element={<MatchingQuiz12 />} /> */}

					<Route path="/editprofile2" element={<Editprofile2 />} />
					{/* <Route path="/leads" element={<Leads />} /> */}
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default Publicroutes;
