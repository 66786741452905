import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GiCancel } from "react-icons/gi";
import { Link } from "react-router-dom";

import TermsModal from "./TermsModal";

function ConsultationModal(props) {
  const [isOpenTermsModal, setIsOpenTermsModal] = useState(false);

  const {
    heading,
    content,
    setIsOpenConsultationModal,
    isOpenConsultationModal,
    SubmitConsultation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    stateUser,
    setStateUser,
    zip,
    setZip,
    phone,
    setPhone,
    cashAvailable,
    setCashAvailable,
    loading,
    setLoading,
    checkState,
    setCheckState,
  } = props;
  return (
    <>
      <Modal
        isOpen={isOpenConsultationModal}
        toggle={() => {
          setIsOpenConsultationModal(false);
        }}
        className="consultation-modal orderView-Modal"
      >
        <GiCancel
          className="modal-close"
          onClick={() => setIsOpenConsultationModal(false)}
        />

        <div className="inner-content">
          <div className="free-form">
            <div className="free-form-head">
              <h3>{heading ?? heading}</h3>
              <p>{content ?? content}</p>
            </div>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="pnumber"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="State"
                      value={stateUser}
                      onChange={(e) => setStateUser(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="inputzip"
                      placeholder="Zip Code"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="inputzip"
                      placeholder="Available Cash To Invest"
                      value={cashAvailable}
                      onChange={(e) => setCashAvailable(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group custom_check">
                    <input
                      type="checkbox"
                      id="checkonly"
                      value={checkState}
                      onChange={(e) => setCheckState(e.target.value)}
                    />
                    <label for="checkonly">
                      {/* <Link to="/terms">Agree to Terms & Conditions</Link>{" "} */}
                      Agree to{" "}
                      <span onClick={() => setIsOpenTermsModal(true)}>
                        Terms & Conditions
                      </span>
                    </label>
                  </div>
                </div>

                <div className="col-md-12">
                  <div class="form-group">
                    <div className="free-consultation-btn">
                      <button
                        onClick={(e) => SubmitConsultation(e)}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <TermsModal
        setIsOpenTermsModal={setIsOpenTermsModal}
        isOpenTermsModal={isOpenTermsModal}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export default ConsultationModal;
