import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import email2 from "../../Assets/img/email.png";
import call from "../../Assets/img/call.png";
import Bucket from "../../Components/Bucket";
import { Link } from "react-router-dom";
import { ContactUsApi } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Contact = () => {
	const SiteData = useSelector((state) => state.AuthReducer.siteSettings);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [methodContact, setMethodContact] = useState("");
	const [loading, setLoading] = useState(false);

	const ContactUsHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!name || !phone || !email || !message) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}
		if (message.length < 20) {
			toast.error("The message must be at least 20 characters");
			setLoading(false);
			return;
		}
		if (!methodContact) {
			toast.error("Please Check Method Of Contact");
			setLoading(false);
			return;
		}
		let data = {
			name: name,
			phone: phone,
			email: email,
			method_of_contact: methodContact,
			message: message,
		};
		ContactUsApi(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				setName("");
				setPhone("");
				setEmail("");
				setMethodContact("");
				setMessage("");
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span> Contact Us</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<Bucket />
			{/* contact section  */}
			<section className="contact-sec conatct-sec-for-padding">
				<div className="container">
					<div className="contact-head">
						<h3>Please contact us if you have any comments,</h3>
						<h3> questions, or partnership opportunities</h3>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="all-form all-forms">
								<form>
									<div className="row">
										<div className="col-md-12">
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Your Name"
													value={name}
													onChange={(e) => setName(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Your Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<input
													type="number"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Your Phone"
													value={phone}
													onChange={(e) => setPhone(e.target.value)}
												/>
											</div>
										</div>

										<div className="col-md-12">
											<h5 className="preferred">Preferred Method Of Contact</h5>
											<div className="prefer-method only-for-flex">
												<div className="form-group custom_check for-margin-no">
													<input
														type="radio"
														id="html"
														name="fav_language"
														value="Phone"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="html">Phone</label>
													<br />
												</div>
												<div className="form-group custom_check for-margin-no">
													<input
														type="radio"
														id="css"
														name="fav_language"
														value="Email"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="css">Email</label>
													<br />
												</div>
												<div className="form-group custom_check for-margin-no">
													 {" "}
													<input
														type="radio"
														id="javascript"
														name="fav_language"
														value="No Preference"
														onChange={(e) => setMethodContact(e.target.value)}
													/>
													  <label for="javascript"> No Preference</label>
												</div>
												   
											</div>
										</div>

										<div className="col-md-12">
											<div class="form-group">
												<textarea
													class="form-control"
													id="exampleFormControlTextarea1"
													rows="3"
													placeholder="Write Message"
													value={message}
													onChange={(e) => setMessage(e.target.value)}
												></textarea>
											</div>
										</div>

										<div className="col-md-6"></div>

										<div className="col-md-6">
											<div className="submit-dv">
												<button
													onClick={(e) => ContactUsHandler(e)}
													disabled={loading}
												>
													{loading ? "Loading.." : "Submit"}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of contact section  */}

			<section className="add-fax-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>

						<div className="col-lg-4 col-md-6">
							<div className="pic-and-text-finance-text">
								<img className="img-fluid" src={email2} />
								<div className="finance-text">
									<h5>Email Address</h5>
									<p>{SiteData?.contact_email}</p>
									{/* <p>www.finder.com</p> */}
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-6">
							<div className="pic-and-text-finance-text">
								<img className="img-fluid" src={call} />
								<div className="finance-text">
									<h5>Phone</h5>
									<p>Local : {SiteData?.contact_phone}</p>
									{/* <p>Mobile : 1-997-66-Finder</p> */}
								</div>
							</div>
						</div>

						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default Contact;
