// Define api end points here

export const REGISTER = "/register";
export const LOGIN = "/login";
export const FORGOT = "/forgot";
export const RESET_PASSWORD = "/reset-password";
export const STATES = "/states";
export const CATEGORIES = "/categories";

export const AVAILABLE_FRANCHISES = "/available-franchises";
export const SITE_SETTINGS = "/site-settings"
export const PROFILE = "/profile"
export const CHANGE_PASSWORD = "/change-password";

export const FRANCHISE = "/franchises";
export const CREATE = "/create";
export const DETAIL = "/detail";
export const STATUS = "/status";

export const LEADS = "/leads";
export const PURCHASE = "/purchase";
export const CONTACTUS = "/contact-us";
export const INVESTMENT_LEVELS = "/investment-levels";
export const CARDS = "/cards";
export const PAGES = "/pages";
export const LEAD = "/lead";
export const ONE_O_ONE = "/franchising-101";

export const CONSULTATION = "/consultation";
export const FORM = "/form";
export const DELETE = "/delete";

export const MAKE = "/make";
export const DEFAULT = "/default";
export const OUR_PROCESS = "/our-processes";

export const FRANCHISE_DETAILS = "/franchise-detail";
export const NEWSLETTER = "/newsletter";

export const UPDATE = "/update";
export const FRANCHISES = "/franchises";
export const DELETE_IMAGE = "/delete-image";
export const DELETE_VIDEO = "/delete-video";

export const PAGE = "?page";
export const FAQS = "/faqs";
export const QUESTIONS = "/questions";
export const SUBMIT_QUIZ_SECTION = "/submit-quiz-section";

export const AUTH = "/auth";
export const STORE = "/store";






