import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/css/quiz.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MatchingQuiz1 from "./MatchingQuiz1";
import { GetQuiz, PostQuiz } from "../../network/Network";
import { useEffect } from "react";
import { useState } from "react";
import QuizForm from "../../Components/Quiz/QuizForm";
import { toast } from "react-toastify";

const DynamicQuiz = () => {
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());
	const [page, setPage] = useState(1);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [zip, setZip] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectOption, setSelectOption] = useState();
	const [selectedData, setSelectedData] = useState([]);
	const [quizData, setQuizData] = useState([]);
	const [viewLoader, setViewLoader] = useState(false);
	const [steps, setSteps] = useState([]);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [optionSelected, setOptionSelected] = useState(false);

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		setOptionSelected(false);
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setPage(page + 1);
		setSkipped(newSkipped);

		// check if an option has been selected
		// const hasOptionSelected = selectedData.length > 0;
		// setOptionSelected(hasOptionSelected);
		// setOptionSelected(false);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		if (page > 0) {
			setPage(page - 1);
		}
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
		setPage(1);
	};

	const StepOneContinue = (e) => {
		e.preventDefault();
		setPage(page + 1);
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const StepTwoContinue = (e) => {
		e.preventDefault();
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	useEffect(() => {
		setViewLoader(true);
		GetQuiz(page)
			.then((res) => {
				setQuizData(res?.data?.response?.data?.data);
				const numPages = res?.data?.response?.data?.last_page || 1;
				const newSteps = Array.from(
					{ length: numPages },
					(_, i) => `${i + 1}`, //steps
				);
				setSteps(newSteps);
				setViewLoader(false);
			})
			.catch((err) => {
				setViewLoader(false);
			});
	}, [page]);

	// const SelectHandler = (e, item) => {
	//   e.preventDefault();
	//   let array = {
	//     question: item?.question_id,
	//     answer: item?.id,
	//     option: item?.option,
	//   };
	//   console.log(array);

	//   setSelectedData([...selectedData, array]);
	//   console.log("all data", [...selectedData, array]);

	//   // set background color to #000
	//   e.target.style.background = "#000";
	// };

	const SelectHandler = (e, item) => {
		e.preventDefault();

		// check if the item is already selected
		// const isItemSelected = selectedData.some(
		//   (selectedItem) => selectedItem.answer === item.id
		// );

		// if (!isItemSelected) {
		//   const array = {
		//     question: item.question_id,
		//     answer: item.id,
		//     option: item.option,
		//   };

		//   setSelectedData([...selectedData, array]);

		//   // reset the background color of the previously selected button
		//   if (selectedAnswer && document.getElementById(selectedAnswer)) {
		//     document.getElementById(selectedAnswer).style.background = "#249fdb";
		//   }

		//   // set background color to #000
		//   e.target.style.background = "#000";

		//   // set the id of the newly selected button
		//   setSelectedAnswer(e.target.id);
		//   setOptionSelected(true);
		// }

		const array = {
			question: item.question_id,
			answer: item.id,
			option: item.option,
		};

		setSelectedData([...selectedData, array]);

		// reset the background color of the previously selected button
		if (selectedAnswer && document.getElementById(selectedAnswer)) {
			document.getElementById(selectedAnswer).style.background = "#249fdb";
		}

		// set background color to #000
		e.target.style.background = "#000";

		// set the id of the newly selected button
		setSelectedAnswer(e.target.id);
		setOptionSelected(true);
	};

	const QuizSubmitHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		if (!firstName || !lastName || !email || !phone || !zip) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}
		var data = new FormData();
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("phone", phone);
		data.append("email", email);
		data.append("zip", zip);
		selectedData?.map((item, index) => {
			data.append(`question[${index}]`, item?.question);
			data.append(`option[${index}]`, item?.option);
		});
		PostQuiz(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setPhone("");
				setZip("");
				navigate("/thankyou");
			})
			.catch((err) => {
				if (
					err?.response?.data?.errors?.email[0] ==
					"The email has already been taken."
				) {
					toast.error(err?.response?.data?.errors?.email[0]);
				}

				setLoading(false);
			});
	};
	const getMatchingQuizComponent = (step) => {
		switch (step) {
			case 0:
				return (
					<MatchingQuiz1
						quizData={quizData}
						selectedData={selectedData}
						SelectHandler={SelectHandler}
						viewLoader={viewLoader}
					/>
				);
			case steps.length:
				return (
					<QuizForm
						firstName={firstName}
						setFirstName={setFirstName}
						lastName={lastName}
						setLastName={setLastName}
						email={email}
						setEmail={setEmail}
						phone={phone}
						setPhone={setPhone}
						zip={zip}
						setZip={setZip}
						QuizSubmitHandler={QuizSubmitHandler}
						loading={loading}
					/>
				);
			default:
				return (
					<MatchingQuiz1
						quizData={quizData}
						selectedData={selectedData}
						SelectHandler={SelectHandler}
						viewLoader={viewLoader}
					/>
				);
		}
	};

	return (
		<>
			<Header />
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>
									<span> Franchise Matching Quiz</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Quiz Box */}
			<section className="quiz">
				<div className="container">
					<div className="quiz_box">
						<Box sx={{ width: "100%", bgcolor: "background.paper" }}>
							<Stepper activeStep={activeStep}>
								{steps.map((label, index) => {
									const stepProps = {};
									const labelProps = {};
									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>{label}</StepLabel>
										</Step>
									);
								})}
							</Stepper>
							<Box sx={{ p: 3 }}>
								<Typography>{getMatchingQuizComponent(activeStep)}</Typography>
								<div>
									{activeStep === steps.length ? (
										<div>
											<Button variant="contained" onClick={handleReset}>
												Reset
											</Button>
										</div>
									) : (
										<div>
											<div>
												{/* <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button> */}
												{/* Update next button with disabled property */}
												{activeStep !== steps.length - 1 ? (
													<Button
														variant="contained"
														onClick={handleNext}
														disabled={!optionSelected}
													>
														Next
													</Button>
												) : (
													<Button
														variant="contained"
														onClick={handleNext}
														disabled={!optionSelected}
													>
														Finish
													</Button>
												)}
											</div>
										</div>
									)}
								</div>
							</Box>
						</Box>
					</div>
				</div>
			</section>

			{/* Quiz Box */}

			<Footer />
		</>
	);
};
export default DynamicQuiz;
