import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import ff1 from "../../Assets/img/ff1.png";
import ff2 from "../../Assets/img/ff2.png";
import ff3 from "../../Assets/img/ff3.png";
import ff4 from "../../Assets/img/ff4.png";
import feature1 from "../../Assets/img/feature1.png";
import feature2 from "../../Assets/img/feature2.png";
import feature3 from "../../Assets/img/feature3.png";
import feature4 from "../../Assets/img/feature4.png";
import { Link, useLocation } from "react-router-dom";
import Bucket from "../../Components/Bucket";
import { GetAvailableFranchise } from "../../network/Network";
import { useEffect } from "react";
import { notFound } from "../../constant";
import Skeleton from "react-loading-skeleton";
import FranchiseCard from "../../Components/Card/FranchiseCard";
import { toast } from "react-toastify";
import { AddToCart } from "../../redux/actions/CartActions";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

const CategoryAvailaible = () => {
	const dispatch = useDispatch();

	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	const AllStatesDataRedux = useSelector(
		(state) => state.AuthReducer.allStates,
	);

	let currentUrl = window.location.href.split("/");
	const [selectedMinMax, setSelectedMinMax] = useState();
	const [selectedCategory, setSelectedCategory] = useState();
	const [selectedState, setSelectedState] = useState();
	const [viewLoader, setViewLoader] = useState(false);
	const [bucket, setBucket] = useState(false);
	const [allFranchise, setAllFranchise] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState();

	const handlePageClick = async (data) => {
		setCurrentPage(data?.selected + 1);
	};

	const AddToCartHandler = (item) => {
		let checkItemAlreadyExist = CartDataRedux.filter(
			(val) => val?.id === item?.id,
		);
		if (checkItemAlreadyExist?.length > 0) {
			alert("Item Already Exist in Cart!!!");
		} else {
			let data = {
				id: item?.id,
				price: item?.price,
				productitem: item,
			};
			dispatch(AddToCart(data));
			toast.success("Product Successfully Add ");
		}
	};

	useEffect(() => {
		setViewLoader(true);
		let finalData;
		if (currentUrl[3] == "category-filter") {
			let data = {
				featured: " ",
				category_id: selectedCategory ? selectedCategory : currentUrl[4],
				state_id: selectedState,
				investment_level: selectedMinMax,
			};
			finalData = data;
		}
		if (currentUrl[3] == "state-filter") {
			let data = {
				featured: " ",
				category_id: selectedCategory,
				state_id: selectedState
					? selectedState
					: currentUrl[4] == 0
					? " "
					: currentUrl[4],
				investment_level: selectedMinMax,
			};
			finalData = data;
		}
		if (currentUrl[3] == "investment-filter") {
			let data = {
				featured: " ",
				category_id: selectedCategory,
				state_id: selectedState,
				investment_level: selectedMinMax ? selectedMinMax : currentUrl[4],
			};
			finalData = data;
		}
		GetAvailableFranchise(finalData, currentPage)
			.then((res) => {
				setAllFranchise(res?.data?.response?.data?.data);
				// dispatch(AllAvailableFranchise(res?.data?.response?.data));
				const total = res?.data?.response?.data?.total;
				const limit = res?.data?.response?.data?.per_page;
				setPageCount(Math.ceil(total / limit));
				setViewLoader(false);
			})
			.catch((err) => {
				setViewLoader(false);
			});
	}, [currentPage]);

	return (
		<>
			<Header />

			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home <i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Cash Back Program</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			<section className="blue-sec">
				<h4>Add Franchises To Basket</h4>
				<h4>For Free Information</h4>
			</section>

			{bucket ? <Bucket /> : null}

			{/* Featured section  */}
			<section className="featured-sec">
				<div className="container">
					<div className="featured-sec-head text-left">
						<p>Franchises Available</p>
						<h2>
							{currentUrl[3] == "category-filter"
								? "By Category"
								: currentUrl[3] == "state-filter"
								? "By State"
								: currentUrl[3] == "investment-filter"
								? "By Investment"
								: null}
						</h2>
					</div>
					<div className="row">
						{viewLoader ? (
							<>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
								<div className="col-md-4">
									<Skeleton count={5} />
								</div>
							</>
						) : (
							<>
								{allFranchise?.length > 0 ? (
									<>
										{allFranchise?.map((item, index) => {
											return (
												<FranchiseCard
													item={item}
													index={index}
													setBucket={setBucket}
													bucket={bucket}
													AddToCartHandler={AddToCartHandler}
												/>
											);
										})}
									</>
								) : (
									<div className="notFound-container">
										<img
											src={notFound}
											alt="not found"
											className="img-fluid"
										/>
										<p className="mt-4">No Record Found</p>
									</div>
								)}
							</>
						)}
					</div>
					<div className="pagination-container mt-5">
						<ReactPaginate
							previousLabel="<<"
							nextLabel=">>"
							breakLabel={"..."}
							pageCount={pageCount}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={handlePageClick}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
						/>
					</div>
				</div>
			</section>
			{/* End of Featured section  */}

			<Footer />
		</>
	);
};

export default CategoryAvailaible;
