import {
  REGISTER,
  LOGIN,
  FORGOT,
  STATES,
  AVAILABLE_FRANCHISES,
  SITE_SETTINGS,
  PROFILE,
  CHANGE_PASSWORD,
  FRANCHISE,
  CREATE,
  DETAIL,STATUS,
  LEADS,
  PURCHASE,
  INVESTMENT_LEVELS,
  CARDS,PAGES,LEAD,
  CONSULTATION,FORM,
  DELETE,
  MAKE, DEFAULT,
  OUR_PROCESS,
  FRANCHISE_DETAILS,
  NEWSLETTER,
  RESET_PASSWORD,
  UPDATE, FRANCHISES,
  DELETE_IMAGE,DELETE_VIDEO,
  PAGE,FAQS,
  QUESTIONS,
  SUBMIT_QUIZ_SECTION,
  // End Franchise s
    AUTH,
    CATEGORIES,
    CONTACTUS,
    ONE_O_ONE,
    // ---
 
  


    // FROGOT_PASSWORD,
  } from "../network/Endpoint";
  
  import {
    doGet,
    doPost,
    doPatch,
    doDelete,
    doPostProfilePictureUpload,
  } from "./Config";

  export const postSignUp = (data) => {
    return doPostProfilePictureUpload(`${REGISTER}`, data);
  };

  export const postSignIn = (data) => {
    return doPost(`${LOGIN}`, { ...data });
  };

  export const forgotPasswordApi = (data) => {
    return doPost(`${FORGOT}`, data);
  };

  

  export const GetAllAvailableStates = () => {
    return doGet(`${STATES}`);
  };

  export const GetCategoriesApi = () => {
    return doGet(`${CATEGORIES}`);
  };
  

  export const GetAvailableFranchise = (data,currentPage) => {
    return doGet(`${AVAILABLE_FRANCHISES}${"?featured"}=${data?.featured ? data?.featured : " "}${"&category_id"}=${data?.category_id ? data?.category_id : " "}${"&state_id"}=${data?.state_id ? data?.state_id : " "}${"&investment_level"}=${data?.investment_level ? data?.investment_level : " "}${"&fast_growing"}=${data?.fast_growing ? data?.fast_growing : " "}${"&page"}=${currentPage}`);
  };

  export const GetSiteSettingsApi = (data) => {
    return doGet(`${SITE_SETTINGS}`);
  };

  export const UpdateProfile = (data,token) => {
    return doPost(`${PROFILE}`, data,token);
  };
  
  export const PostChangePassword = (data, token) => {
    return doPost(`${CHANGE_PASSWORD}`, data, token);
  };

  export const AddNewFranchise = (data, token) => {
    return doPost(`${FRANCHISE}${CREATE}`, data, token);
  };


  export const GetAllFranchisesApi = ( token,currentPage) => {
    return doGet(`${FRANCHISE}${"?page"}=${currentPage}`,  token);
  };
  
  export const GetFranchiseDetailsApi = (id, token) => {
    return doGet(`${FRANCHISE}${"/"}${id}${DETAIL}`,  token);
  };

  export const GetHomeFranchiseDetailsApi = (id) => {
    return doGet(`${FRANCHISE_DETAILS}${"/"}${id}`,  );
  };



  export const UpdateFranchiseStatus = (data, token) => {
    return doPost(`${FRANCHISE}${STATUS}`, data, token);
  };

  export const GetMyLeads = ( token,currentPage) => {
    return doGet(`${LEADS}${PAGE}=${currentPage}`, token);
  };
  export const GetMyLeadDetails = ( id, token) => {
    return doGet(`${LEADS}${"/"}${id}`, token);
  };

  export const PurcahseLeadsApi = ( data,token) => {
    return doPost(`${LEADS}${PURCHASE}`,data, token);
  };
  
  export const ContactUsApi = ( data) => {
    return doPost(`${CONTACTUS}`, data);
  };
    
  export const GetAllInvestmentLevels = (id, token) => {
    return doGet(`${INVESTMENT_LEVELS}`,  token);
  };

  export const GetAllCards = ( token) => {
    return doGet(`${CARDS}`,  token);
  };

  export const GetProfile = ( token) => {
    return doGet(`${PROFILE}`,  token);
  };
  
  export const PagesApi = (slug) => {
    return doGet(`${PAGES}${slug}`);
  };

  export const UserLeadCreate = ( data) => {
    return doPost(`${LEAD}${CREATE}`,data);
  };

  export const ConsultationForm = ( data) => {
    return doPost(`${CONSULTATION}${FORM}`,data);
  };
  
  export const PostSaveCard = (data,token) => {
    return doPost(`${CARDS}${CREATE}`, data, token);
  };


  export const DeletePaymentMethodsApi = (data, token) => {
    return doPost(`${CARDS}${DELETE}`, data, token);
  };

  export const PostPaymentDefaultCard = (data, token) => {
    return doPost(`${CARDS}${MAKE}${DEFAULT}`, data, token);
  };

  export const GetOurProcess = () => {
    return doGet(`${OUR_PROCESS}`, );
  };

  
  export const PostNewsLetter = (data) => {
    return doPost(`${NEWSLETTER}`, data);
  };
  

  export const ResetPasswordApi = (data) => {
    return doPost(`${RESET_PASSWORD}`, data);
  };

  export const UpdateFranchise = (data, token) => {
    return doPost(`${FRANCHISES}${UPDATE}`, data, token);
  };

  export const DeleteFranchiseImage = (data, token) => {
    return doPost(`${FRANCHISES}${DELETE_IMAGE}`, data, token);
  };

  export const DeleteFranchiseVideo = (data, token) => {
    return doPost(`${FRANCHISES}${DELETE_VIDEO}`, data, token);
  };

  export const GetFaqs = () => {
    return doGet(`${FAQS}`, );
  };
  
  export const GetQuiz = (page) => {
    return doGet(`${QUESTIONS}${"?page"}=${page}`, );
  };

  export const PostQuiz = (data) => {
    return doPost(`${SUBMIT_QUIZ_SECTION}`, data,);
  };

  export const getDocuments = () => {
    return doGet(`${"/documents"}`, );
  };

  export const franchise101ApiCall=()=>{
    return doGet (`${PAGES}${ONE_O_ONE}`)
  }
  
  export const forgotPasswordOtp = (data) => {
    return doPost(`${AUTH}${RESET_PASSWORD}`, data);
  };

  export const PostQuestionnaire = (data) => {
    return doPost(`${"/storeQuestionnaire"}`, data);
  };

  
  // End Franchise 
  



  // export const UpdateProfile = (data, token) => {
  //   return doPostProfilePictureUpload(
  //     `${CUSTOMER}${UPDATE_PROFILE}`,
  //     data,
  //     token
  //   );
  // };



  // export const AddWishList = (data, token) => {
  //   return doPost(`${CUSTOMER}${WISHLIST}${ADD}`, data, token);
  // };




  // ------ end

  