import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { PagesApi } from "../../network/Network";

const Privacy = () => {
	const [pageData, setPageData] = useState();
	const [spinLoad, setSpinLoad] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/privacy-policy";
		PagesApi(slug)
			.then((res) => {
				setPageData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);
	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i class="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Privacy Policy</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Terms and conditions secton  */}
			<section className="terms-sec">
				<div className="container">
					{spinLoad ? (
						<>
							<div className="col-md-12">
								<Skeleton count={5} />
							</div>
							<div className="col-md-12">
								<Skeleton count={5} />
							</div>
						</>
					) : (
						<div className="about-head">
							<h5>Franchise Velocity</h5>
							<h2>{pageData?.page_title}</h2>

							<p dangerouslySetInnerHTML={{ __html: pageData?.content }} />
						</div>
					)}
				</div>
			</section>

			{/* End of Terms condition section  */}
			<Footer />
		</>
	);
};

export default Privacy;
