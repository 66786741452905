import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <section>
        <div className="container">
          <div className="page-notFound">
            <h2>404</h2>
            <p>Page Not Found</p>
            <Link to="/">GO back to home page</Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
