import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import StripeForm from "../Payment/StripeForm";
import { GiCancel } from 'react-icons/gi';
import MyPaymentForm from "../Payment/SquarePayment";

function PaymentModal(props) {
  const { setIsOpenPaymentModal, isOpenPaymentModal,
    PaymentHandler,
    setCardToken,
    cardToken,btnLoading,
  } = props;
  const Token = useSelector((state) => state.AuthReducer.token);

  return (
    <>
      <Modal
        isOpen={isOpenPaymentModal}
        toggle={() => {
            setIsOpenPaymentModal(false);
        }}
        className="payment-modal orderView-Modal"
      >
        <GiCancel 
          className="modal-close"
          onClick={() => setIsOpenPaymentModal(false)}
        />
        <div className="inner-content">
          <h2 style={{ textAlign: "center" }}>Add Payment Card</h2>
          <div className="view_about_query_modal">
            <div className="mt-5">
              {/* <StripeForm
                PaymentHandler={PaymentHandler}
                setCardToken={setCardToken}
                cardToken={cardToken}
                btnLoading={btnLoading}
              /> */}
               <MyPaymentForm
                  cardTokenizeResponseReceived={PaymentHandler}
                />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PaymentModal;
