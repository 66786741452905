import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/css/finance.css";
import { Link, useNavigate } from "react-router-dom";
import address from "../../Assets/img/address.png";
import fax from "../../Assets/img/fax.png";
import emailImg from "../../Assets/img/email.png";
import call from "../../Assets/img/call.png";
import { useEffect } from "react";
import { useState } from "react";
import { ConsultationForm, PagesApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import ConsultationModal from "../../Components/Modal/ConsultationModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Financing = () => {
	const navigate = useNavigate();
	const handleGo = () => {
		navigate("/terms");
	};
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const [pageData, setPageData] = useState();
	const [loading, setLoading] = useState(false);
	const [spinLoad, setSpinLoad] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [stateUser, setStateUser] = useState("");
	const [zip, setZip] = useState("");
	const [phone, setPhone] = useState("");
	const [cashAvailable, setCashAvailable] = useState("");

	const [isOpenConsultationModal, setIsOpenConsultationModal] = useState(false);
	const [consultData, sConsultData] = useState([]);
	const [checkState, setCheckState] = useState(false);

	useEffect(() => {
		setSpinLoad(true);
		let slug = "/financing-your-business";
		PagesApi(slug)
			.then((res) => {
				setPageData(res?.data?.response?.data);
				setSpinLoad(false);
			})
			.catch((err) => {
				setSpinLoad(false);
			});
	}, []);

	const SubmitConsultation = (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!firstName ||
			!lastName ||
			!email ||
			!stateUser ||
			!zip ||
			!phone ||
			!cashAvailable
		) {
			toast.error("Please Enter All Fields");
			setLoading(false);
			return;
		}
		if (
			!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				email,
			)
		) {
			toast.error("Invalid Email");
			setLoading(false);
			return;
		}
		if (phone?.length < 10) {
			toast.error("The phone no must be at least 10 characters");
			setLoading(false);
			return;
		}

		var data = new FormData();
		data.append("type", "consulations");
		data.append("first_name", firstName);
		data.append("last_name", lastName);
		data.append("email", email);
		data.append("state", stateUser);
		data.append("zip", zip);
		data.append("phone", phone);
		data.append("cash_available_to_invest", cashAvailable);
		ConsultationForm(data)
			.then((res) => {
				toast.success(res?.data?.message);
				setIsOpenConsultationModal(false);
				setLoading(false);
				setFirstName("");
				setLastName("");
				setEmail("");
				setStateUser("");
				setZip("");
				setPhone("");
				setCashAvailable("");
				setCheckState(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Header />
			{/* Main banner section  */}
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									<Link to="/">Home</Link>{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Financing Options</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of banner section  */}

			{/* Financing section  */}
			<section className="financing-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{spinLoad ? (
								<>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
									<div className="col-md-12">
										<Skeleton count={5} />
									</div>
								</>
							) : (
								<>
									<div className="finance-buisness">
										<h5>Financing Options</h5>
										<h2>{pageData?.page_title}</h2>
										<p
											dangerouslySetInnerHTML={{ __html: pageData?.content }}
										/>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* End of Financing section  */}

			{/* Obligation section  */}
			<section className="obligation-sec">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="obligation-sec">
								<h2>
									Sign up today for a no-obligations discussion to review your
									financing options
								</h2>

								<div className="free-consultation Sign-up-btn">
									<button
										type="button"
										class="btn btn-primary"
										onClick={() => setIsOpenConsultationModal(true)}
										// data-toggle="modal"
										// data-target=".bd-example-modal-lg"
									>
										Free Consultation
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Obligation section  */}

			{/* Address and fax section  */}

			{/* <section className="add-fax-sec fax-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={address} />
                <div className="finance-text">
                  <h5>Address</h5>
                  <p>{SiteSettingsRedux?.address}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={fax} />
                <div className="finance-text">
                  <h5>Fax</h5>
                  <p>Office : 1437-636-7400</p>
                  <p>Home : 1437-636-7400</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={emailImg} />
                <div className="finance-text">
                  <h5>Email Address</h5>
                  <p>{SiteSettingsRedux?.contact_email}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="pic-and-text-finance-text">
                <img className="img-fluid" src={call} />
                <div className="finance-text">
                  <h5>Phone</h5>
                  <p>{SiteSettingsRedux?.contact_phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

			{/* End of Address and fax section  */}

			<Footer />
			<ConsultationModal
				heading="Free Consultation"
				content="Sign up below"
				setIsOpenConsultationModal={setIsOpenConsultationModal}
				isOpenConsultationModal={isOpenConsultationModal}
				SubmitConsultation={SubmitConsultation}
				firstName={firstName}
				setFirstName={setFirstName}
				lastName={lastName}
				setLastName={setLastName}
				email={email}
				setEmail={setEmail}
				stateUser={stateUser}
				setStateUser={setStateUser}
				zip={zip}
				setZip={setZip}
				phone={phone}
				setPhone={setPhone}
				cashAvailable={cashAvailable}
				setCashAvailable={setCashAvailable}
				loading={loading}
				setLoading={setLoading}
			/>
		</>
	);
};

export default Financing;
