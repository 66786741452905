import React, { useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Path1 from "../../Assets/img/Path1.png";
import {
	AddNewFranchise,
	GetAllAvailableStates,
	GetSiteSettingsApi,
} from "../../network/Network";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import StripeForm from "../../Components/Payment/StripeForm";
import { useNavigate } from "react-router-dom";
import { BsFillImageFill } from "react-icons/bs";
import { AllStates, WebSiteSettings } from "../../redux/actions/AuthActions";
import { RiCloseCircleLine } from "react-icons/ri";
import Bucket from "../../Components/Bucket";
import MyPaymentForm from "../../Components/Payment/SquarePayment";

const Addfranchise = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const CategoriesReduxData = useSelector(
		(state) => state.AuthReducer.categories,
	);
	const SiteSettingsRedux = useSelector(
		(state) => state.AuthReducer.siteSettings,
	);
	const CartDataRedux = useSelector((state) => state.CartReducer.cartData);
	// const getAllStates = useSelector((state) => state.AuthReducer.allStates);
	const Token = useSelector((state) => state.AuthReducer.token);
	const [loading, setLoading] = useState(false);

	const [franchiseName, setFranchiseName] = useState("");
	const [franchiseCandidate, setFranchiseCandidate] = useState("");
	const [logo, setLogo] = useState("");
	const [logoFileupload, setLogoFileupload] = useState("");
	const [description, setDescription] = useState("");
	// const [industryCategory, setIndustryCategory] = useState("");
	const [industryCategories, setIndustryCategories] = useState([]);
	const [minCashReq, setMinCashReq] = useState("");
	const [netWorthReq, setNetWorthReq] = useState("");
	const [minInvestment, setMinInvestment] = useState("");
	const [maxInvestment, setMaxInvestment] = useState("");
	const [financialAssistanceAvailable, setFinancialAssistanceAvailable] =
		useState("");
	const [passiveOwnershipAllowed, setPassiveOwnershipAllowed] = useState([]);
	const [selectedAvailableStates, setSelectedAvailableStates] = useState([]);
	const [selectedNotAvailableStates, setSelectedNotAvailableStates] = useState(
		[],
	);
	const [noOfLeads, setNoOfLeads] = useState(20);
	const [unitPrice, setUnitPrice] = useState();
	const [totalAmount, setTotalAmount] = useState();

	const [coverFileupload, setCoverFileupload] = useState("");
	const [coverImage, SetCoverImage] = useState("");

	const [multiImageFiles, setMultiImageFiles] = useState([]);
	const [multiVideosFiles, setMultiVideosFiles] = useState([]);

	const [uploadImages, setUploadImages] = useState([]);
	const [uploadVideo, setUploadVideo] = useState([]);
	const [cardToken, setCardToken] = useState("");

	const [royaltyfee, setRoyaltyfee] = useState("");
	const [advertisingFee, setAdvertisingFee] = useState("");
	const [franchiseFee, setFranchiseFee] = useState("");

	const [youtubeUrl, setYoutubeUrl] = useState([]);

	const [inputFields, setInputFields] = useState(1);

	const [getAllStates, setGetAllStates] = useState([]);
	const [getBackupStates, setGetBackupStates] = useState([]);

	// const [yearFound, setYearFound] = useState("");
	// const [headquarters, setHeadquarters] = useState("");
	// const [franchisingSince, setFranchisingSince] = useState("");
	// const [total_units, setTotal_units] = useState("");

	const HandleLogoUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			setLogo(file);
			setLogoFileupload(reader.result);
		};
		reader.readAsDataURL(file);
		// setUploadLoading(true);
	};

	const HandleCoverUpload = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];
		reader.onloadend = () => {
			SetCoverImage(file);
			setCoverFileupload(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleImageUpload = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiImageFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadImages((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderPhotos = (source) => {
		return source.map((photo) => {
			return (
				<li>
					{" "}
					<img
						src={photo}
						alt=""
						style={{ width: "100px", height: "100px", objectFit: "cover" }}
					/>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiImg(e, photo)}
					/>
				</li>
			);
		});
	};

	const VideosUploadHandler = (e) => {
		e.preventDefault();
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file),
			);
			setMultiVideosFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => URL.revokeObjectURL(file), // avoid memory leak
			);
			Array.from(e.target.files).map((file) => {
				setUploadVideo((preVal) => {
					return [...preVal, file];
				});
			});
		}
	};

	const renderVideos = (source) => {
		return source.map((video) => {
			return (
				<li>
					<video width="320" height="240" controls>
						<source src={video} type="video/mp4" />
					</video>
					<RiCloseCircleLine
						style={{ position: "absolute" }}
						onClick={(e) => RemoveMultiVideos(e, video)}
					/>
				</li>
			);
		});
	};

	const SelectedAvailableHandler = (e, type, value) => {
		if (value === 0 && e.target.checked) {
			const remStates = getAllStates.filter((element) => element?.id === value);

			setGetAllStates(remStates);
			setSelectedAvailableStates([e.target.value]);
		} else if (value === 0 && !e.target.checked) {
			setGetAllStates(getBackupStates);
			setSelectedAvailableStates([]);
		} else if (value !== 0 && e.target.checked) {
			setGetAllStates(getBackupStates);
			setSelectedAvailableStates([...selectedAvailableStates, e.target.value]);
		}

		// if (type == "yes") {
		//   if (e.target.checked === true) {
		//     setSelectedAvailableStates((preVal) => {
		//       return [...preVal, parseInt(e.target.value)];
		//     });
		//   } else {
		//     setSelectedAvailableStates((preVal) => {
		//       return [...preVal.filter((val) => val !== parseInt(e.target.value))];
		//     });
		//   }
		// } else {
		//   if (e.target.checked === true) {
		//     setSelectedNotAvailableStates((preVal) => {
		//       return [...preVal, parseInt(e.target.value)];
		//     });
		//   } else {
		//     setSelectedNotAvailableStates((preVal) => {
		//       return [...preVal.filter((val) => val !== parseInt(e.target.value))];
		//     });
		//   }
		// }
	};

	// const SelectedAvailableHandler = (e, type) => {
	// 	if (type == "yes") {
	// 		if (e.target.checked === true) {
	// 			setSelectedAvailableStates((preVal) => {
	// 				return [...preVal, parseInt(e.target.value)];
	// 			});
	// 		} else {
	// 			setSelectedAvailableStates((preVal) => {
	// 				return [...preVal.filter((val) => val !== parseInt(e.target.value))];
	// 			});
	// 		}
	// 	} else {
	// 		if (e.target.checked === true) {
	// 			setSelectedNotAvailableStates((preVal) => {
	// 				return [...preVal, parseInt(e.target.value)];
	// 			});
	// 		} else {
	// 			setSelectedNotAvailableStates((preVal) => {
	// 				return [...preVal.filter((val) => val !== parseInt(e.target.value))];
	// 			});
	// 		}
	// 	}
	// };

	let passiveOwnershipData = [
		{
			id: 1,
			name: "Owner-Operator",
		},
		{
			id: 2,
			name: "Semi-Absentee",
		},
		{
			id: 3,
			name: "Passive",
		},
	];

	const AddFranchise = (e, token) => {
		e.preventDefault();
		setLoading(true);
		if (!token?.token) {
			if (
				!franchiseName ||
				!franchiseCandidate ||
				!selectedAvailableStates ||
				!financialAssistanceAvailable ||
				!passiveOwnershipAllowed ||
				!maxInvestment ||
				!minInvestment ||
				!description ||
				!netWorthReq ||
				!minCashReq ||
				!industryCategories ||
				!royaltyfee ||
				!advertisingFee ||
				!franchiseFee
			) {
				setLoading(false);
				toast.error("Please Enter All Field");
				return;
			}
			if (!logo) {
				toast.error("Please Upload Logo");
				setLoading(false);
				return;
			}
			if (description.length < 300) {
				toast.error("The description must be greater than 300 characters");
				setLoading(false);
				return;
			}

			if (!/^(ftp|http|https):\/\/[^ "]+$/.test(youtubeUrl)) {
				toast.error("Invalid Url");
				setLoading(false);
				return;
			}
			if (!coverImage) {
				toast.error("Please Upload Cover Image");
				setLoading(false);
				return;
			}
			let data = new FormData();
			// data.append("category_ids[]", industryCategories);
			industryCategories?.map((categoryId) => {
				data.append("category_ids[]", categoryId);
			});
			// industryCategories?.map((category) => {
			//   data.append("category_ids[]", category);
			// });
			// data.append("category_id", industryCategories);
			data.append("name", franchiseName);
			data.append("ideal_franchise_candidate", franchiseCandidate);
			data.append("logo", logo);
			data.append("coverImage", coverImage);
			data.append("description", description);
			// data.append("min_cash_required", minCashReq);
			// data.append("year_founded", yearFound);
			// data.append("franchising_since", franchisingSince);
			// data.append("headquarters", headquarters);
			// data.append("total_units", total_units);
			// data.append("min_investment", minInvestment);
			// data.append("max_investment", maxInvestment);
			data.append("min_cash_required", `$${minCashReq}`);
			data.append("net_worth_required", `$${netWorthReq}`);
			data.append("min_investment", `$${minInvestment}`);
			data.append("max_investment", `$${maxInvestment}`);
			passiveOwnershipAllowed?.map((passive) => {
				data.append("passive_ownership_allowed[]", passive);
			});
			data.append(
				"financial_assistance_available",
				financialAssistanceAvailable,
			);
			// selectedAvailableStates?.map((state) => {
			// 	data.append("states[]", state);
			// });

			selectedAvailableStates?.map((state) => {
				data.append("states[]", state);
			});
			// selectedNotAvailableStates?.map((notState) => {
			//   data.append("not_available_states[]", notState);
			// });
			uploadImages.map((singlepic) => {
				data.append("images[]", singlepic);
			});
			uploadVideo.map((singleVideo) => {
				data.append("videos[]", singleVideo);
			});

			// data.append("card_nonce", token?.token);
			// data.append("stripe_token", cardToken);
			// data.append("leads", noOfLeads);
			// data.append("unit_price", parseInt(SiteSettingsRedux?.lead_price));
			// data.append(
			//   "total_amount",
			//   noOfLeads * parseInt(SiteSettingsRedux?.lead_price)
			// );
			// data.append("total_amount", totalAmount);
			data.append("royalty_fee", royaltyfee);
			data.append("advertising_fee", advertisingFee);
			data.append("franchise_fee", `$${franchiseFee}`);

			data.append("financial_assistance_available_display", 1);
			// data.append("financial_assistance_available_label", );
			data.append("min_cash_required_display", 1);
			// data.append("min_cash_required_label", );
			data.append("net_worth_required_display", 1);
			// data.append("net_worth_required_label", );
			data.append("max_investment_display", 1);
			// data.append("max_investment_label", );
			data.append("min_investment_display", 1);
			// data.append("min_investment_label", );
			data.append("passive_ownership_allowed_display", 1);
			// data.append("passive_ownership_allowed_label", );
			data.append("franchise_fee_display", 1);
			// data.append("franchise_fee_label", );
			data.append("advertising_fee_display", 1);
			// data.append("advertising_fee_label", );
			data.append("royalty_fee_display", 1);
			// data.append("royalty_fee_label", );

			// data.append("youtube_url", youtubeUrl);
			youtubeUrl?.map((url) => {
				data.append("youtube_urls[]", url);
			});

			AddNewFranchise(data, Token)
				.then((res) => {
					toast.success(res?.data?.message);
					navigate("/franch");
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			toast.error("something went wrong");
		}
	};

	const PassiveOwnershipHandler = (e) => {
		e.preventDefault();
		if (e.target.checked === true) {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal, e.target.value];
			});
		} else {
			setPassiveOwnershipAllowed((preVal) => {
				return [...preVal.filter((val) => val !== e.target.value)];
			});
		}
	};

	// useEffect(() => {
	// 	GetAllAvailableStates()
	// 		.then((res) => {
	// 			dispatch(AllStates(res?.data?.response?.data));
	// 		})
	// 		.catch((err) => {});
	// }, []);

	useEffect(() => {
		GetAllAvailableStates()
			.then((res) => {
				setGetAllStates(res?.data?.response?.data);
				setGetBackupStates(res?.data?.response?.data);
				dispatch(AllStates(res?.data?.response?.data));
			})
			.catch((err) => {});
	}, []);

	const RemoveMultiImg = (e, item) => {
		e.preventDefault();
		setMultiImageFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	const RemoveMultiVideos = (e, item) => {
		e.preventDefault();
		setMultiVideosFiles((preVal) => {
			return [...preVal.filter((val) => val !== item)];
		});
	};

	// maaz precentage check

	const setMinCashRequired = (e) => {
		// setMinCashReq(e.target.value);
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMinCashReq(showVal);
			} else {
				setMinCashReq("");
			}
		} catch (err) {
			setMinCashReq("");
		}
		// let value_comma1 = parseInt(e.target.value).toLocaleString("en");
		// let checkVal = minCashReq.split(",").join("");
		// // .split(',').join('')
		// // let value_comma = parseInt(e.target.value)
		// setShowMinCashReq(value_comma1);
		// // setMinCashReq(value_comma.toLocaleString("en"));
		// console.log(showMinCashReq, '888888888888888888888888888888888888888');
	};

	const setMinInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMinInvestment(showVal);
			} else {
				setMinInvestment("");
			}
		} catch (err) {
			setMinInvestment("");
		}
	};

	const setMaxInvstRequired = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setMaxInvestment(showVal);
			} else {
				setMaxInvestment("");
			}
		} catch (err) {
			setMaxInvestment("");
		}
	};

	const changeFranchiseFees = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setFranchiseFee(showVal);
			} else {
				setFranchiseFee("");
			}
		} catch (err) {
			setFranchiseFee("");
		}
	};

	const changeNetWorth = (e) => {
		try {
			let checkVal = e.target.value.split(",").join("");
			let showVal = checkVal ? parseInt(checkVal)?.toLocaleString("en") : "";
			if (showVal) {
				setNetWorthReq(showVal);
			} else {
				setNetWorthReq("");
			}
		} catch (err) {
			setNetWorthReq("");
		}
	};

	const handleTagClick = (event, value) => {
		event.preventDefault();
		if (industryCategories.includes(value)) {
			setIndustryCategories(
				industryCategories.filter((category) => category !== value),
			);
		} else {
			setIndustryCategories([...industryCategories, value]);
		}
	};

	const addInputField = (e) => {
		e.preventDefault();
		setInputFields(inputFields + 1);
	};

	// const removeInputField = (e, indexToRemove) => {
	//   e.preventDefault();
	//   // console.log(indexToRemove);
	//   setYoutubeUrl(youtubeUrl.filter((_, index) => index !== indexToRemove));
	//   setInputFields(inputFields - 1);
	// };

	const removeInputField = (e, indexToRemove, indexOfUrl) => {
		e.preventDefault();
		// console.log(indexToRemove);
		// setYoutubeUrl(youtubeUrl.filter((_, index) => index !== indexToRemove));
		// setInputFields(inputFields - 1);
		youtubeUrl.splice(indexOfUrl, 1);
		setYoutubeUrl(youtubeUrl);
		setInputFields(inputFields - 1);
	};

	return (
		<>
			<Header />
			<section className="banner inner-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="Inner-banner-text banner-text">
								<h5>
									Home{" "}
									<i className="fa fa-angle-right m-2" aria-hidden="true"></i>{" "}
									<span>Join As franchisor</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="blue-sec">
				<h4>Add Franchises To The Basket</h4>
				<h4>For Free Information</h4>
			</section>
			{CartDataRedux?.length > 0 ? <Bucket /> : null}

			{/* main form start */}
			<section className="signupform">
				<div className="container">
					<div className="form-wrapper">
						<form>
							<div className="row"></div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-wrapper">
										<h4>Franchise Information</h4>
										{/* <p>uspendisse mollis ante imperdiet, dictum sapien eu,</p> */}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div class="form-group">
										<input
											type="text"
											class="form-control"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Franchise Name"
											value={franchiseName}
											onChange={(e) => setFranchiseName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div class="form-group">
										<input
											type="text"
											class="form-control"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Ideal Franchisee (one sentence max)"
											value={franchiseCandidate}
											onChange={(e) => setFranchiseCandidate(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>Upload Logo</h5>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mb-2 text-center">
									<div class="input-group">
										<label className="multi-image-upload">
											<img className="img-fluid" src={Path1}></img>
											<span className="addfile">Add file</span>
											<span className="ordrop"> or drop files here</span>
											{/* <span className="ml-3">
                        {logo ? <BsFillImageFill /> : null}
                      </span> */}
										</label>
										<input
											type="file"
											id="fileUpload"
											accept="image/*"
											onChange={HandleLogoUpload}
											multiple={false}
											class="form-control  file-opc"
										/>
										<div className="mt-5">
											{logo ? (
												<img
													src={logoFileupload}
													className="image-fluid image-width"
													style={{
														width: "100px",
														height: "100px",
														objectFit: "cover",
													}}
													alt=""
												/>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>Upload Cover</h5>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mb-2 text-center">
									<div class="input-group">
										<label className="multi-image-upload">
											<img className="img-fluid" src={Path1}></img>
											<span className="addfile">Add file</span>
											<span className="ordrop"> or drop files here</span>
											{/* <span className="ml-3">{coverImage ? <BsFillImageFill /> : null }</span> */}
										</label>
										<input
											type="file"
											id="fileUpload"
											accept="image/*"
											onChange={HandleCoverUpload}
											multiple={false}
											class="form-control  file-opc"
										/>
										<div className="mt-5">
											{coverImage ? (
												<img
													src={coverFileupload}
													className="image-fluid image-width"
													style={{
														width: "100px",
														height: "100px",
														objectFit: "cover",
													}}
													alt=""
												/>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-lg-12">
									<div class="texterea">
										<textarea
											type="texterea"
											class="form-control"
											aria-describedby="emailHelp"
											placeholder="Input text overview/description of your franchise (will appear on the Website)"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="form-group industry-category3">
										<h3>
											Select All Industries/Categories that are relevant to your
											franchise:
										</h3>
										<div>
											{CategoriesReduxData?.slice(1)?.map((item, index) => {
												const selected = industryCategories.includes(item?.id);
												return (
													<button
														key={index}
														onClick={(event) => handleTagClick(event, item?.id)}
														style={{
															backgroundColor: selected ? "#0077c2" : "",
															color: selected ? "#fff" : "",
															padding: "5px 10px",
															borderRadius: "10px",
															margin: "5px",
															cursor: "pointer",
															border: "none",
														}}
													>
														{item?.name}
													</button>
												);
											})}
										</div>
									</div>

									{/* <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Year Founded"
                      value={yearFound}
                      onChange={(e)=>setYearFound(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Total Units"
                      value={total_units}
                      onChange={(e)=>setTotal_units(e.target.value)}
                    />
                  </div> */}
								</div>

								{/* <div class="form-group">
                    <input
                      type="Number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Franchising Since"
                      value={franchisingSince}
                      onChange={(e) => setFranchisingSince(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Headquarters"
                      value={headquarters}
                      onChange={(e) => setHeadquarters(e.target.value)}
                    />
                  </div> */}

								<div className="col-lg-6 pos-rel">
									<span className="doollar_Sign_adjust">{"$"}</span>
									<div class="form-group">
										<input
											type="text"
											class="form-control field-pd-left"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Minimum Cash Required"
											value={minCashReq}
											// maaz
											onChange={setMinCashRequired}
										/>
									</div>
									<span className="doollar_Sign_adjust_other_signup">
										{"$"}
									</span>
									<div class="form-group">
										<input
											type="text"
											class="form-control field-pd-left"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Min Investment"
											value={minInvestment}
											// maaz
											onChange={setMinInvstRequired}
										/>
									</div>
									<div className="form-group pos-rel">
										<span class="doollar_Sign_adjust_other_signup_nocol">
											%
										</span>
										<input
											type="number"
											class="form-control pl-percetage"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Royalty Fee"
											value={royaltyfee}
											onChange={(e) => {
												setRoyaltyfee(e.target.value);
											}}
										/>
									</div>
									<div className="form-group pos-rel">
										<span className="doollar_Sign_adjust_other_signup_nocol">
											{"$"}
										</span>
										<input
											type="text"
											class="form-control field-pd-left"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Franchise Fee"
											value={franchiseFee}
											onChange={changeFranchiseFees}
										/>
									</div>

									<div className="form-group industry-category3">
										<select
											// className="form-control"
											id="exampleInputEmail1"
											onChange={(e) => {
												setFinancialAssistanceAvailable(e.target.value);
											}}
										>
											<option selected="">
												Financial Assistance Available
											</option>
											<option value={1}>Yes </option>
											<option value={0}>No </option>
										</select>
									</div>
								</div>
								<div className="col-lg-6 pos-rel">
									<span className="doollar_Sign_adjust_other_signup">
										{"$"}
									</span>
									<div class="form-group">
										<input
											type="text"
											class="form-control field-pd-left"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Max Investment"
											value={maxInvestment}
											onChange={setMaxInvstRequired}
										/>
									</div>

									<div className="form-group pos-rel">
										<span class="doollar_Sign_adjust_other_signup_nocol">
											%
										</span>
										<input
											type="number"
											class="form-control pl-percetage"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Advertising Fee"
											value={advertisingFee}
											onChange={(e) => setAdvertisingFee(e.target.value)}
										/>
									</div>
									<div class="form-group pos-rel">
										<span class="doollar_Sign_adjust_other_signup_nocol">
											$
										</span>
										<input
											type="text"
											class="form-control field-pd-left"
											id="exampleInputEmail1"
											aria-describedby="emailHelp"
											placeholder="Net Worth Required"
											value={netWorthReq}
											onChange={changeNetWorth}
										/>
									</div>
								</div>

								<div className="col-md-12">
									<div className="heading-content">
										<h5>Ownership Types Allowed</h5>
									</div>
								</div>
								{passiveOwnershipData?.map((item, index) => {
									return (
										<div className="col-md-4" key={index}>
											<div>
												<input
													type="checkbox"
													id={item?.id}
													value={item?.name}
													onChange={(e) => PassiveOwnershipHandler(e)}
												/>

												<label className="ml-2">{item?.name}</label>
											</div>
										</div>
									);
								})}
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>Available in these states</h5>
									</div>
								</div>
							</div>
							<div className="row">
								{getAllStates
									.filter((val) => {
										if (
											selectedNotAvailableStates.find(
												(value) => value == val?.id,
											)
										) {
										} else {
											return val;
										}
									})
									?.map((item, index) => {
										return (
											<div className="col-md-4" key={index}>
												<div
													className={`${
														selectedNotAvailableStates.includes(item?.id) ==
														true
															? "state-hide"
															: "prefer-method"
													}`}
												>
													<div>
														<input
															type="checkbox"
															id={item?.id}
															value={item?.id}
															onChange={(e) =>
																SelectedAvailableHandler(e, "yes", item?.id)
															}
														/>
														<label className="ml-2">{item?.name}</label>
													</div>
												</div>
											</div>
										);
									})}
							</div>

							{/* <div className="row">
								{getAllStates
									?.filter((val) => {
										if (
											selectedNotAvailableStates.find(
												(value) => value == val?.id,
											)
										) {
											// console.log(
											// 	"Not found in Available",
											// 	selectedNotAvailableStates.find(
											// 		(value) => value == val?.id,
											// 	),
											// );
										} else {
											return val;
										}
									})
									?.map((item, index) => {
										return (
											<div className="col-md-4" key={index}>
												<div
													className={`${
														selectedNotAvailableStates.includes(item?.id) ==
														true
															? "state-hide"
															: "prefer-method"
													}`}
												>
													<div>
														<input
															type="checkbox"
															id={item?.id}
															value={item?.id}
															onChange={(e) =>
																SelectedAvailableHandler(e, "yes")
															}
														/>
														<label className="ml-2">{item?.name}</label>
													</div>
												</div>
											</div>
										);
									})}
							</div> */}
							{/* <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Not avaialable in these states</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                {getAllStates
                  .filter((val) => {
                    console.log(
                      "Not Available",
                      selectedAvailableStates.find((value) => value == val?.id)
                    );
                    if (
                      !selectedAvailableStates.find((value) => value == val?.id)
                    ) {
                      return val;
                    }
                  })
                  ?.map((item, index) => {
                    return (
                      <>
                        <div className="col-md-4" key={index}>
                          <div className="prefer-method">
                            <div>
                              <input
                                type="checkbox"
                                id={`${item?.name}${item?.id}`}
                                value={item?.id}
                                onChange={(e) =>
                                  SelectedAvailableHandler(e, "No")
                                }
                              />
                              <label className="ml-2">{item?.name}</label>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div> */}
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>YouTube Video</h5>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-lg-12">
									<div class="form-group">
										{/* {Array.from({ length: inputFields }, (_, index) => (
                        <input
                          key={index}
                          type="url"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Youtube Video Url"
                          value={youtubeUrl}
                          onChange={(e) => setYoutubeUrl(e.target.value)}
                        />
                      ))} */}
										{Array.from({ length: inputFields }, (_, index) => (
											<div className="dynamicFieldsWraper">
												<input
													style={{ margin: "0px 10px 0px 0px" }}
													key={index}
													type="url"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="emailHelp"
													placeholder="Youtube Video Url"
													value={youtubeUrl[index] || ""}
													onChange={(e) => {
														const newUrls = [...youtubeUrl];
														newUrls[index] = e.target.value;
														setYoutubeUrl(newUrls);
													}}
												/>

												<button
													className="removeFieldbtn"
													onClick={(e) => {
														const urlIndex = youtubeUrl[index];
														removeInputField(e, urlIndex, index);
													}}
												>
													Remove
												</button>
											</div>
										))}
									</div>
									<button
										className="addFieldbtn"
										onClick={(e) => addInputField(e)}
									>
										Add Url
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>Upload Videos</h5>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mb-2 text-center">
									<div class="input-group">
										<label className="multi-image-upload">
											<img className="img-fluid" src={Path1}></img>
											<span className="addfile">Add file</span>
											<span className="ordrop"> or drop files here</span>
										</label>
										<input
											type="file"
											accept="video/*"
											onChange={VideosUploadHandler}
											multiple={true}
											className="form-control file-opc"
											id="fileUpload"
											// hidden
										/>
										<ul className="multi-imglist">
											{renderVideos(multiVideosFiles)}
										</ul>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="heading-content">
										<h5>Upload Images</h5>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12 mb-2 text-center">
									<div class="input-group">
										<label className="multi-image-upload">
											<img className="img-fluid" src={Path1}></img>
											<span className="addfile">Add file</span>
											<span className="ordrop"> or drop files here</span>
										</label>
										<input
											type="file"
											accept="image/*"
											onChange={handleImageUpload}
											multiple={true}
											className="form-control file-opc"
											id="fileUpload"
										/>

										<ul id="fileList" className="multi-imglist">
											{renderPhotos(multiImageFiles)}
										</ul>
									</div>
								</div>
							</div>

							{/* <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Purchase Leads</h5>
                    <p>uspendisse mollis ante imperdiet,</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label>No of Leads</label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="No Of Leads"
                      value={noOfLeads}
                      onChange={(e) => setNoOfLeads(e.target.value)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <label>Unit Price</label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Unit Price"
                      value={parseInt(SiteSettingsRedux?.lead_price)}
                      onChange={(e) => setUnitPrice(e.target.value)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div class="form-group">
                    <label>Total Amount</label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Total Amount"
                      value={
                        noOfLeads * parseInt(SiteSettingsRedux?.lead_price)
                      }
                      onChange={(e) => setTotalAmount(e.target.value)}
                      disabled={true}
                    />
                  </div>
                </div>
              </div> */}
							{/* <div className="row">
                <div className="col-lg-12">
                  <div className="heading-content">
                    <h5>Payment</h5>
                  </div>
                </div>
              </div> */}
							<div className="row">
								<div className="col-lg-12"></div>
							</div>
							<div className="row">
								<div className="col-md-4 mt-5 mx-auto text-center">
									<div className="submit-dv">
										<button
											onClick={(e) => AddFranchise(e, Token)}
											disabled={loading}
										>
											{loading ? "Loading..." : "Submit"}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
			{/* main form end */}

			<Footer />
		</>
	);
};

export default Addfranchise;
