import * as React from 'react';
import { Ach, CreditCard, GiftCard, PaymentForm } from 'react-square-web-payments-sdk';

const MyPaymentForm = (props) => {
  const {cardTokenizeResponseReceived,loading} = props;
 

return(
  <PaymentForm
  applicationId="sandbox-sq0idb-e1ww-hHoy1u3sGz_3AP53g"
  cardTokenizeResponseReceived={cardTokenizeResponseReceived}
  
  // createVerificationDetails={() => ({
  //   amount: '1.00',
  //   /* collected from the buyer */
  //   billingContact: {
  //     addressLines: ['123 Main Street', 'Apartment 1'],
  //     familyName: 'Doe',
  //     givenName: 'John',
  //     countryCode: 'GB',
  //     city: 'London',
  //   },
  //   currencyCode: 'GBP',
  //   intent: 'CHARGE',
  // })}

  locationId="LQ19BFVB5Z6A5"
>
  <CreditCard 
   buttonProps={{
    isLoading: loading ,
    // onClick: onClick,
  }}
    // aria-disabled={loading}
    // render={(button) => <button disabled={loading} onClick={cardTokenizeResponseReceived}> { loading ? "Loading..." : "Submit"}</button>}
  >
    {  loading ? "Loading..." : "Submit"}
  </CreditCard>
</PaymentForm>
)
}

export default MyPaymentForm;